// Inbody
export const GET_INBODY_OVERVIEW_OPTIONS_API = '/api/inbody/overview/options/';
export const GET_EMPLOYEES_OPTIONS_API = `/api/employees/options/`;
export const GET_INBODY_API = '/api/inbody/'; // 取得量身資料清單
export const GET_INBODY_MEMBER_HEIGHT_API = '/api/inbody/member-height/'; // 取得會員身高
export const GET_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API = '/api/inbody/interview-not-measure-status/'; // 面談狀態
export const PUT_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API = GET_INBODY_INTERVIEW_NOT_MEASURE_STATUS_API;
export const POST_INBODY_INTERVIEW_MEMBERID_API = (memberID) => `/api/inbody/interview/${memberID}/`; // 建立量身面談內容
export const GET_INBODY_MEASURE_RECORDS_API = '/api/inbody/measure-records/'; // 取得完整量身資料清單
export const GET_INBODY_TRIGGER_MEASURE_API = '/api/inbody/trigger-measure/'; // 觸發量身,回傳是否可使用
export const POST_INBODY_MEASUREID_SPORT_ADVISE_SAVE_API = (measureID) => `/api/inbody/measure/${measureID}/sport-advise/save/`;
export const POST_INBODY_MEMBERID_MEASURE_TASK_XFACTOR_MEASUREID_API = (memberID, measureID) => `/api/inbody/${memberID}/measure-task-xfactor/${measureID}/`;
export const POST_INBODY_MEMBERID_MEASURE_TASK_AGREED_MEASUREID_API = (memberID, measureID) => `/api/inbody/${memberID}/measure-task-agreed/${measureID}/`;
export const PUT_INBODY_INTERVIEW_API = `/api/inbody/interview/`;
export const POST_CANCEL_MEASURE_API = `/api/inbody/cancel-measure/`;
export const DELETE_INBODY_MEASUREID_API = (measureID) => `/api/inbody/${measureID}/`;
export const POST_INBODY_IMPORT_EXCEL_API = `/api/inbody/import-inbody/`;
export const POST_INBODY_IMPORT_EXCEL_370_DM0536_API = `/api/inbody/import-inbody/370_DM-0536/`;
export const GET_INBODY_MEMBERID_MEASURE_RANGE_DATE_API = (memberID) => `/api/inbody/${memberID}/measure-range-date/`;

import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, MenuItem, Menu, ListItemText } from '@common/components/';
import { MoreVertical as MoreVerticalIcon } from '@common/SvgIcon/';
import { giftDispatchByMemberList } from '@icoach/members/staticData';

/**
 * 快速名單使用共用元件
 */
export const QuickMoreLabelBox = (props) => {
    const { rowData, dialogRef, routerPath, targetType, onKeepState } = props;
    const [anchorEl, setAnchorEl] = useState(null);
    const history = useHistory();
    const open = Boolean(anchorEl);

    const handleToggleClick = (e) => {
        setAnchorEl(e.currentTarget);
    };
    // 關閉menu錨點
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <React.Fragment>
            <IconButton onClick={handleToggleClick}>
                <MoreVerticalIcon />
            </IconButton>
            <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        onKeepState && onKeepState(rowData);
                        handleClose();
                        const path = routerPath(rowData.memberID);
                        history.push(path);
                    }}
                >
                    <ListItemText>查看</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        dialogRef.current.openContactDialog(rowData);
                        handleClose();
                    }}
                >
                    <ListItemText>新增聯繫</ListItemText>
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        dialogRef.current.openSmsDialog([{ id: rowData.id, type: rowData.type, name: rowData.nickName || rowData.name || rowData.memberName }]);
                        handleClose();
                    }}
                >
                    <ListItemText>發送簡訊</ListItemText>
                </MenuItem>
                {giftDispatchByMemberList.includes(String(targetType)) && (
                    <MenuItem
                        onClick={() => {
                            dialogRef.current.openGiftDispatchDialog({ memberIDs: [rowData.id] });
                            handleClose();
                        }}
                    >
                        <ListItemText>贈品發送</ListItemText>
                    </MenuItem>
                )}
            </Menu>
        </React.Fragment>
    );
};

export default QuickMoreLabelBox;

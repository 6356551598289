import React from 'react';
import { IconButton } from '@common/components/';
import useDocumentApi from '@apis/useDocumentApi';
import { objectToQueryString } from '@util/utils';
import { Download as DownloadIcon } from '@common/SvgIcon/';

const InBodyTranscriptDialog = (props) => {
    const { sourceData = {} } = props;
    const { getTranscriptPDFApi } = useDocumentApi();

    const handleTranscript = () => {
        const { measureID, memberID, isShowQrcode, ...other } = sourceData;
        doTranscriptApi(
            {
                memberID,
                ...other,
            },
            measureID,
            memberID,
            isShowQrcode,
        );
    };

    const doTranscriptApi = async (params, measureID, memberID, isShowQrcode) => {
        await getTranscriptPDFApi(objectToQueryString(params), measureID, memberID, isShowQrcode);
    };

    return (
        <IconButton onClick={handleTranscript}>
            <DownloadIcon />
        </IconButton>
    );
};

export default InBodyTranscriptDialog;

import React, { useImperativeHandle, useRef } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Card, SelectField, MenuItem, Typography, TextField } from '@common/components/';

const ConfigurationOthersFormContent = React.forwardRef((props, ref) => {
    const { className: classNameProps, sourceData } = props;
    const { shinKongSetting = {}, ecPaySetting = {}, smsSetting = {} } = sourceData;

    const shinKongSettingMerchantnoRef = useRef(null);

    const ecPaySettingMerchantidRef = useRef(null);
    const ecPaySettingAccountRef = useRef(null);
    const ecPaySettingPasswordRef = useRef(null);
    const ecPaySettingHashkeyRef = useRef(null);
    const ecPaySettingHashivRef = useRef(null);

    const smssettingAccountRef = useRef(null);
    const smssettingPasswordRef = useRef(null);

    // 新光
    const getShinKongSettingParams = () => {
        let params = {
            ...shinKongSetting,
            merchantNo: shinKongSettingMerchantnoRef.current.getResult(),
        };
        return params;
    };

    // 綠界
    const getEcPaySettingParams = () => {
        let params = {
            ...ecPaySetting,
            merchantID: ecPaySettingMerchantidRef.current.getResult(),
            account: ecPaySettingAccountRef.current.getResult(),
            hashKey: ecPaySettingHashkeyRef.current.getResult(),
            hashIV: ecPaySettingHashivRef.current.getResult(),
        };
        const password = ecPaySettingPasswordRef.current.getResult();
        if (password) {
            params.password = password;
        }
        return params;
    };

    // 三竹
    const getSmsSettingParams = () => {
        let params = {
            ...smsSetting,
            account: smssettingAccountRef.current.getResult(),
        };
        const password = smssettingPasswordRef.current.getResult();
        if (password) {
            params.password = password;
        }
        return params;
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                getResult: () => {
                    return {
                        shinKongSetting: getShinKongSettingParams(),
                        ecPaySetting: getEcPaySettingParams(),
                        smsSetting: getSmsSettingParams(),
                    };
                },
                isDifference: () => {},
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment>
            <Card.CardContent className={clsx(classNameProps)}>
                <Typography variant="h4" component={'h3'} className="mb-2">
                    批次扣款設定
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <SelectField label="銀行" defaultValue="1" fullWidth>
                            <MenuItem value="1">新光銀行</MenuItem>
                        </SelectField>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            ref={shinKongSettingMerchantnoRef}
                            label={'特店代號(15碼)'}
                            defaultValue={shinKongSetting.merchantNo}
                            type={'number'}
                            maxLength="15"
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Card.CardContent>
            <Card.CardContent className={clsx(classNameProps)}>
                <Typography variant="h4" component={'h3'} className="mb-2">
                    電子發票設定
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <TextField ref={ecPaySettingMerchantidRef} label={'商店代號'} defaultValue={ecPaySetting.merchantID} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField ref={ecPaySettingAccountRef} label={'帳號'} defaultValue={ecPaySetting.account} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            ref={ecPaySettingPasswordRef}
                            label={'密碼'}
                            type="password"
                            inputProps={{
                                placeholder: ecPaySetting.password,
                            }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField ref={ecPaySettingHashkeyRef} label={'HashKey'} defaultValue={ecPaySetting.hashKey} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField ref={ecPaySettingHashivRef} label={'HashIV'} defaultValue={ecPaySetting.hashIV} fullWidth />
                    </Grid>
                </Grid>
            </Card.CardContent>
            <Card.CardContent className={clsx(classNameProps)}>
                <Typography variant="h4" component={'h3'} className="mb-2">
                    簡訊發送設定
                </Typography>
                <Grid container spacing={3}>
                    <Grid item xs={4}>
                        <TextField ref={smssettingAccountRef} label={'帳號'} defaultValue={smsSetting.account} fullWidth />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            ref={smssettingPasswordRef}
                            label={'密碼'}
                            type="password"
                            inputProps={{
                                placeholder: smsSetting.password,
                            }}
                            fullWidth
                        />
                    </Grid>
                </Grid>
            </Card.CardContent>
        </React.Fragment>
    );
});

export default ConfigurationOthersFormContent;

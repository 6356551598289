import React, { useRef } from 'react';
import clsx from 'clsx';
import { Box } from '@common/components/';
import InBodyInterviewMeasureNormalBox, { inBodyInterviewNotesItems } from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import InBodyExtendDiv from '@icoach/inbody/components/InBodyExtendDiv';

const InBodyInterviewNotesTable = React.forwardRef((props, ref) => {
    const { sourceData } = props;
    const divRef = useRef(null);

    return (
        <Box className="inbody-measure">
            <Box className="container" ref={divRef}>
                {inBodyInterviewNotesItems.map(({ value, key, rowClass, styles = {}, formatCell }) => {
                    return (
                        // row
                        <Box key={value} className={clsx('row', rowClass, sourceData.length === 0 && 'full-width')} style={{ ...styles }}>
                            {/* Column 標題 */}
                            <Box className={clsx('item', 'sticky')} style={{ width: '180px' }}>
                                {value}
                            </Box>
                            {/* 全部內容 */}
                            <InBodyInterviewMeasureNormalBox
                                sourceData={sourceData}
                                itemKey={key}
                                styles={{
                                    maxWidth: 400,
                                    minWidth: 400,
                                    overflow: 'hidden',
                                    ...styles,
                                }}
                                formatCell={formatCell}
                            />
                            <InBodyExtendDiv />
                        </Box>
                    );
                })}
            </Box>
            {/* <ScrollerArrowButton arrowLeftPos={150} moveDistance={800} parentRef={divRef} /> */}
        </Box>
    );
});

export default InBodyInterviewNotesTable;

import { useEffect, useState } from 'react';
import useEmployeeApi from '@apis/useEmployeeApi';

// 取得員工資訊
const useGetEmployeeInformationApi = ({ isFetchOnMount = true, employeeID = 0 } = {}) => {
    const { getEmployeeInformationApi: doEmployeeInformationApi } = useEmployeeApi();
    const [sourceData, setSourceData] = useState([]);

    const getEmployeeInformationApi = async (employeeID) => {
        const resp = await doEmployeeInformationApi(employeeID);
        if (resp) {
            setSourceData(resp);
        }
    };

    useEffect(() => {
        if (isFetchOnMount && employeeID) getEmployeeInformationApi(employeeID);

        // eslint-disable-next-line
    }, [isFetchOnMount, employeeID]);

    return { sourceData, getEmployeeInformationApi };
};

export default useGetEmployeeInformationApi;

import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Button, Typography } from '@common/components/';
import { CyclePurchaseProvider } from '@icoach/documents/cyclePurchase/CyclePurchaseContext';
import { DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER } from '@icoach/router/routerPath';
import { initBookmark } from '@icoach/members/staticData';
import CyclePurchaseOverviewSearchBar from '@icoach/documents/cyclePurchase/CyclePurchaseOverviewSearchBar';
import CyclePurchaseOverviewTable from '@icoach/documents/cyclePurchase/CyclePurchaseOverviewTable';

const DefaultSortBy = 2

const CyclePurchaseOverviewPage = (props) => {
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [options, setOptions] = useState({});
    const [bookmark, setBookmark] = useState({
        ...initBookmark(DefaultSortBy),
    });
    const refreshRef = useRef();
    
    const childContext = {
        tableData,
        setTableData,
        options,
        setOptions,
        refresh: refreshRef,
        setRefresh: (fn) => (refreshRef.current = fn),
        bookmark: bookmark,
        setBookmarkNum: (num) => setBookmark((pre) => ({ ...pre, pageIndex: num })),
        setSortBy: (value) => setBookmark((prev) => ({ ...prev, sortBy: parseInt(value) || DefaultSortBy })),
    };

    return (
        <CyclePurchaseProvider value={childContext}>
            <Box className="container main-container-spacing cycle-purchase-page">
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        循環訂單總覽
                    </Typography>
                    <Box className={'btn-group'}>
                        <Button variant={'contained'} onClick={() => history.push(DOCUMENT_CYCLE_PURCHASE_CREATE_ROUTER)}>
                            新增循環訂購單
                        </Button>
                    </Box>
                </Box>
                <CyclePurchaseOverviewSearchBar className={'mb-3'} />
                <CyclePurchaseOverviewTable sortDefaultValue={DefaultSortBy}/>
            </Box>
        </CyclePurchaseProvider>
    );
};

export default CyclePurchaseOverviewPage;

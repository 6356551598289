import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Card } from '@common/components/';
import useShowPDF from '@util/hook/useShowPDF';
import PromptDialog from '@icoach/components/dialog/PromptDialog';
import useDocMembershipTransferInApi from '@apis/useDocMembershipTransferInApi';
import MembershipTransferInOrigingMembership from '@icoach/documents/membership/transferIn/MembershipTransferInOrigingMembership';
import MembershipTransferInModifyMembership from '@icoach/documents/membership/transferIn/MembershipTransferInModifyMembership';
import MembershipTransferInFee from '@icoach/documents/membership/transferIn/MembershipTransferInFee';
import MembershipTransferInMemberProfile from '@icoach/documents/membership/transferIn/MembershipTransferInMemberProfile';
import MembershipTransferInPaymentAuthorization from '@icoach/documents/membership/transferIn/MembershipTransferInPaymentAuthorization';
import { MembershipProvider } from '@icoach/documents/membership/create/MembershipContext';
import { Stepper } from '@icoach/documents/components/';
import { mapStepsWithErrorState } from '@icoach/documents/tools';
import useValidationErrors from '@util/hook/useValidationErrors';
import { isEmpty } from '@util/utils';

// 移入申請書填寫步驟資料來源
const tabsStepSourceData = [
    {
        id: 'program',
        label: '原會籍',
        content: <MembershipTransferInOrigingMembership />,
        keyMapping: [],
    },
    {
        id: 'reProgram',
        label: '會籍調整',
        content: <MembershipTransferInModifyMembership />,
        keyMapping: ['employeeID', 'applyDate', 'memberType', 'paymentType', 'membershipTerm', 'startDate', 'endDate', 'joinFee', 'monthlyFee', 'notes'],
    },
    {
        id: 'payment',
        label: '初次費用',
        content: <MembershipTransferInFee />,
        keyMapping: ['expectedFirstMonthFee', 'cardPaymentAmount', 'cashPaymentAmount', 'isImmediatelyIssuing', 'firstCheckContract', 'firstSign'],
    },
    {
        id: 'basic',
        label: '基本資料',
        content: <MembershipTransferInMemberProfile />,
        keyMapping: [
            'name',
            'nickName',
            'identifierID',
            'birthday',
            'guardianSign',
            'email',
            'cellPhone',
            'tel',
            'companyTel',
            'postCode',
            'address',
            'contactName',
            'contactPhone',
            'contactship',
            'memberCardNo',
        ],
    },
    {
        id: 'paymentAuthorization',
        label: '扣款授權',
        content: <MembershipTransferInPaymentAuthorization />,
        keyMapping: [
            'paymentCardSign',
            'paymentCreditCardBankName',
            'paymentCreditCardType',
            'paymentCreditCardNo',
            'paymentCardExpiryDate',
            'paymentCardName',
            'paymentCardSign',
            'paymentBankCode',
            'paymentBankName',
            'paymentBankAccount',
            'paymentBankAccountName',
            'paymentBankAccountID',
            'bankCardSignImg',
            'invoiceCarrierType',
            'invoiceIssuingType',
            'invoiceCarrierNo',
            'invoiceTitle',
            'invoiceTaxID',
            'invoiceLoveCode',
        ],
    },
];

const MembershipTransferInContent = (props) => {
    const { onReturnHistory, isReadOnly } = props;
    const [showPrompt, setShowPrompt] = useState(true);
    const [options, setOptions] = useState({});
    const [isMounted, setMounted] = useState(false);
    const { validationErrors, updateValidationErrors, clearValidationErrors } = useValidationErrors();
    const { peopleType: peopleTypeProps, referenceID: referenceIDProps, contractID: contractIDProps } = useParams();
    const showPDF = useShowPDF();
    const { getDocTransferInContractApi, postDocTransferInContractApi, postDocTemporaryTransferInContractApi } = useDocMembershipTransferInApi();
    const temporarySourceDataRef = useRef({});
    const apiSendingRef = useRef(false);
    const {
        isCompleteContract, // 是否為正式文件
    } = temporarySourceDataRef.current;
    const currentContentRef = useRef(null);

    const checkStepsForErrors = useCallback(mapStepsWithErrorState, [JSON.stringify(validationErrors)]);

    // 確認送出
    const handleSubmitClick = () => {
        if (apiSendingRef.current) return false;
        currentContentRef.current.getResult();
        apiSendingRef.current = true;

        postTransferInDataApi(temporarySourceDataRef.current);
    };

    // 暫存
    const handleTemporaryClick = () => {
        if (apiSendingRef.current) return false;
        currentContentRef.current.getResult();
        apiSendingRef.current = true;

        postTemporaryStorageApi(temporarySourceDataRef.current);
    };

    // 10: 移入會員 11: 手動移入 13: 未完成移入 14:已移入會員
    // 送出 移入申請書
    const postTransferInDataApi = async (params) => {
        const resp = await postDocTransferInContractApi(contractIDProps, { ...params });
        const { isError = false, result, completedPath } = resp || {};

        if (isError) {
            // 0002 錯誤
            updateValidationErrors(result);
        } else if (isEmpty(resp)) {
            // 失敗
            clearValidationErrors();
        } else {
            // 成功
            showPDF({
                open: true,
                pdfUrl: completedPath,
            });
            clearValidationErrors();
            setShowPrompt(false);
            window.setTimeout(() => {
                onReturnHistory && onReturnHistory('14', resp.contractID);
            }, 5);
        }

        apiSendingRef.current = false;
    };

    // 暫存資料
    const postTemporaryStorageApi = async (params) => {
        const resp = await postDocTemporaryTransferInContractApi({ ...params });
        if (resp) {
            setShowPrompt(false);
            window.setTimeout(() => {
                onReturnHistory && onReturnHistory('13', resp);
            }, 5);
        } else {
            apiSendingRef.current = false;
        }
    };

    const doInitTransferInDataApi = async () => {
        const resp = await getDocTransferInContractApi(contractIDProps, {
            type: peopleTypeProps,
            id: referenceIDProps,
        });
        if (resp) {
            const { resource, ...others } = resp;
            temporarySourceDataRef.current = { ...others };
            setOptions(resource);
            setMounted(true);
        }
    };

    useEffect(
        () => {
            doInitTransferInDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <MembershipProvider>
            {isMounted && (
                <React.Fragment>
                    <Card>
                        <Card.CardContent>
                            <Stepper
                                ref={currentContentRef}
                                stepSourceContent={checkStepsForErrors(tabsStepSourceData, validationErrors)}
                                temporarySourceDataRef={temporarySourceDataRef}
                                options={options}
                                onSubmitEvent={handleSubmitClick}
                                onTemporaryStorageEvent={handleTemporaryClick}
                                isReadOnly={isReadOnly}
                                isTemporary={!isReadOnly && !isCompleteContract}
                                isSubmit={!isReadOnly}
                                onCancelClick={() => onReturnHistory && onReturnHistory(peopleTypeProps, 0)}
                                errorModule={validationErrors}
                            />
                        </Card.CardContent>
                    </Card>
                    {!isReadOnly && <PromptDialog when={showPrompt} />}
                </React.Fragment>
            )}
        </MembershipProvider>
    );
};
export default MembershipTransferInContent;

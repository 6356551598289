import { useState } from 'react';

const useSwitch = (initialState = false) => {
    const [isOn, setIsOn] = useState(initialState);

    const handleToggle = () => setIsOn((prev) => !prev);
    const handleTurnOn = () => setIsOn(true);
    const handleTurnOff = () => setIsOn(false);

    return { isOn, handleToggle, handleTurnOn, handleTurnOff };
};

export default useSwitch;

import React from 'react';
import { isEmpty } from 'lodash';
import { Edit as EditIcon } from '@common/SvgIcon/';
import { IconButton, CheckboxField, Box } from '@common/components/';
import clsx from 'clsx';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';

const InBodyAgreedNotes = React.forwardRef((props, ref) => {
    const { sourceData = [], measureID } = props;
    const { dialogRef, peopleID, refresh } = useInBodyContext();

    const handleAgreedNotesOnClick = () => {
        dialogRef.current.openAgreedNotesDialog({ list: sourceData, measureID, memberID: peopleID }, refresh);
    };

    const isEmptyData = isEmpty(sourceData);

    return (
        <Box className={clsx('cursor-pointer', { 'text-left': !isEmptyData })} onClick={handleAgreedNotesOnClick}>
            {isEmptyData ? (
                <IconButton>
                    <EditIcon />
                </IconButton>
            ) : (
                <CheckboxField className={'w-100'} origin="start" row={false} readOnly>
                    {sourceData[0] && <CheckboxField.Checkbox label={sourceData[0].taskTitle} checked={sourceData[0].isCompleted} />}
                    {sourceData[1] && <CheckboxField.Checkbox label={sourceData[1].taskTitle} checked={sourceData[1].isCompleted} />}
                </CheckboxField>
            )}
        </Box>
    );
});

export default InBodyAgreedNotes;

import React, { useMemo } from 'react';
import { Box, Chip, Typography } from '@common/components/';
import { BusinessCardBox } from '@icoach/components/';
import { OrderInvoiceAbandonDialog, OrderAllowanceDialog } from '@icoach/accountBook/dialog';
import { formatCurrencyFn } from '@util/utils';
import useToggleDialog from '@util/hook/useToggleDialog';
import { dialogType, orderStatusColorMap } from '@icoach/accountBook/staticData';
import { BatchPaymentOrdersTable, checkRowsLabel } from '@icoach/accountBook/tables/BatchPaymentOrdersCreditCardTable';

const rowsLabel = [
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        align: 'left',
        maxWidth: 20.4,
        formatCell: (cellValue, { memberNo, memberName, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox sourceData={memberInfo} />;
        },
    },
    {
        cellKey: 'transactionStatusText',
        headerLabel: '交易狀態',
        align: 'center',
        formatCell: (cellValue, { transactionStatus = 0 }) => {
            return <Chip label={cellValue} color={orderStatusColorMap.get(transactionStatus)} />;
        },
    },
    {
        cellKey: 'transactionTypeText',
        headerLabel: '交易類型',
        align: 'center',
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
        align: 'center',
    },
    {
        cellKey: 'transactionPrice',
        headerLabel: '交易金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'bankAccount',
        headerLabel: '銀行帳號',
        maxWidth: 21,
        formatCell: (cellValue, { bankCode, bankName }) => {
            return (
                <Box className="payment-card-content">
                    {bankName && (
                        <Typography className={'sub-title font-color-3 mb-0'} variant={'body1'}>
                            {bankName}
                        </Typography>
                    )}
                    <Typography className={'title font-color-3 mb-0'} variant={'body1'}>
                        代號：{bankCode}
                    </Typography>
                    <Typography className={'title mb-0'} variant={'body1'} component={'h6'}>
                        {cellValue}
                    </Typography>
                </Box>
            );
        },
    },
    {
        cellKey: 'bankAccountName',
        headerLabel: '銀行帳戶',
        maxWidth: 21,
        formatCell: (cellValue, { bankAccountID }) => {
            return (
                <div className="payment-card-content">
                    <Typography className={'title'} variant="body2">
                        {`戶名：${cellValue}`}
                    </Typography>
                    {bankAccountID && (
                        <Typography className={'sub-title'} variant="body1">
                            {bankAccountID}
                        </Typography>
                    )}
                </div>
            );
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        maxWidth: 30,
    },
];

const BillingResultAchTable = React.forwardRef((props, ref) => {
    const { refresh, action } = props;
    const { openTarget, handleOpenDialog, handleCloseDialog, orderID } = useToggleDialog();
    const labelTitle = useMemo(() => {
        let array = [...rowsLabel];
        if (action) array = [checkRowsLabel(action), ...array];
        return array;
    }, [action]);

    return (
        <React.Fragment>
            <BatchPaymentOrdersTable {...props} ref={ref} labelTitle={labelTitle} handleOpenDialog={handleOpenDialog} />
            <OrderAllowanceDialog open={openTarget === dialogType.allowance} orderID={orderID} onClose={handleCloseDialog} refresh={refresh} />
            <OrderInvoiceAbandonDialog open={openTarget === dialogType.invoiceAbandon} orderID={orderID} onClose={handleCloseDialog} refresh={refresh} />
        </React.Fragment>
    );
});

export default BillingResultAchTable;

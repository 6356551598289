import { useState, useEffect, useLayoutEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

const useTablenizationState = (props) => {
    const { onLoadState, onReduxState, isAutoScrollTo = true } = props;
    const history = useHistory();
    const location = useLocation();
    const [remainPosition, setRemainPosition] = useState(null);
    const [remainKey, setRemainKey] = useState(null);

    // keep state
    const handleOnKeepState = (rowKey) => {
        const data = onReduxState();
        const state = {
            data: data,
            queryString: location.search,
            position: { x: window.scrollX, y: window.scrollY },
            rowKey
        };

        history.replace(location.pathname, state);
    }

    const handleOnManualScrollTo = (scrollBehavior = 'auto') => {
        if (remainPosition) {
            const { x = 0, y = 0 } = remainPosition;
            window.scrollTo({
                top: y,
                left: x,
                behavior: scrollBehavior
            });

            setRemainPosition(null);
        }
    }

    const handelCheckActiveState = (currentRowKey) => {
        return remainKey !== null && remainKey === currentRowKey;
    }

    // load state
    // 1. replace remain querystring
    // 2. replace remain data
    // 3. scroll to remain position
    useEffect(() => {
        if (typeof onLoadState === 'function' && location.state) {
            const { data, queryString, position, rowKey } = location.state;
            history.replace(location.pathname + queryString);
            onLoadState(data);

            setRemainPosition(position);
            setRemainKey(rowKey);
        }

        // eslint-disable-next-line
    }, [location.state]);

    useLayoutEffect(() => {
        if (isAutoScrollTo) {
            handleOnManualScrollTo();
        }
        // eslint-disable-next-line
    }, [location]);


    return {
        doKeepState: handleOnKeepState,
        doManualScrollTo: handleOnManualScrollTo,
        doCheckActiveState: handelCheckActiveState
    };
}

export default useTablenizationState;
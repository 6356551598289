import React, { useRef } from 'react';
import Tablenization from '@icoach/components/Tablenization';
import { BusinessCardBox, LabelCheckBox, LabelHeaderCheckBox, MemoControllerBox, MoreLabelBox, MorePageBox, useLabelCheckBox } from '@icoach/components';
import { formatCurrencyFn, objectToQueryString } from '@util/utils';
import { displayDateText } from '@util/moment';
import { StatusChipTooltip } from '@icoach/rentCabinet/CabinetOverviewTable';
import { cabinetPaymentCycleMap, cabinetStatusMap, getRentCabinetMoreActionArray, RENT_CABINET_STATUS_COLOR_MATCH } from '@icoach/rentCabinet/staticData';
import { RENT_CABINET_DOCUMENT_CREATE_URL, RENT_CABINET_DOCUMENT_ID_URL, RENT_CABINET_DOCUMENT_PREVIEW_URL } from '@icoach/router/MembersRouter';
import { useRentCabinetContext } from '@icoach/rentCabinet/components/RentCabinetContext';
import { Box, IconButton, Stack, Tooltip } from '@common/components/';
import { ListInfo } from '@icoach/accountBook/components';
import { Link } from 'react-router-dom';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { isEmpty } from 'lodash';
import { PaperPlane as PaperPlaneIcon } from '@common/SvgIcon/';

const RentCabinetMoreLabel = ({ rowsData, handleOpenDialog, dialogRef, refresh, handleSendSMSClick, ...props }) => {
    const { cabinetRentalID, memberID, nickName } = rowsData;

    const moreLabelMap = {
        view: {
            text: '查看',
            to: () => RENT_CABINET_DOCUMENT_PREVIEW_URL(cabinetRentalID),
        },
        edit: {
            text: '編輯',
            to: () => RENT_CABINET_DOCUMENT_ID_URL(cabinetRentalID),
        },
        renew: {
            text: '續約',
            to: () => RENT_CABINET_DOCUMENT_CREATE_URL + objectToQueryString({ renewCabinetRentalID: cabinetRentalID }),
        },
        quit: {
            text: '退租申請',
            onClickEvent: () => dialogRef.current && dialogRef.current.openQuitRentCabinetDialog({ cabinetRentalID }, refresh),
        },
        deleteQuit: {
            text: '刪除退租申請',
            onClickEvent: () => dialogRef.current && dialogRef.current.openDeleteQuitRentCabinetDialog({ cabinetRentalID }, refresh),
        },
        sms: {
            text: '發送簡訊',
            onClickEvent: () => dialogRef.current && dialogRef.current.openSendSmsDialog({ id: memberID, type: 2, name: nickName }, refresh),
        },
    };

    return <MoreLabelBox {...props} id={rowsData.cabinetID} moreLabelMap={moreLabelMap} textAlign={'center'} />;
};

const rowsLabel = [
    {
        isIcon: true,
        maxWidth: 7,
        width: 7,
        formatCell: (value, rowData, { checkBoxsRef, onCheckBoxChange, getCheckedItems, sequence }) => {
            let isChecked = false;
            const key = `${value}_${sequence}`;
            if ((getCheckedItems() || []).includes(key)) isChecked = true;
            return (
                <LabelCheckBox
                    ref={(el) => (checkBoxsRef.current[key] = el)}
                    onChange={onCheckBoxChange}
                    idKey={key}
                    defalutCehecked={isChecked}
                    sourceData={rowData}
                    disabled={String(rowData.redeemStatus) === '1'}
                />
            );
        },
    },
    {
        cellKey: 'memberID',
        headerLabel: '承租會員',
        maxWidth: 20.4,
        formatCell: (_, rowData) => {
            const { cabinetRentalID } = rowData;
            return (
                <Link className={'unstyled'} to={RENT_CABINET_DOCUMENT_PREVIEW_URL(cabinetRentalID)}>
                    <BusinessCardBox sourceData={rowData} />
                </Link>
            );
        },
    },
    {
        cellKey: 'cabinetName',
        headerLabel: '置物櫃名稱',
        align: 'center',
    },
    {
        cellKey: 'firstMonthFee',
        headerLabel: '租金',
        align: 'center',
        formatCell: (cellValue, { paymentCycle, monthlyFee }) => {
            let feeText = formatCurrencyFn(cellValue, { symbol: '$' });
            if (String(paymentCycle) !== cabinetPaymentCycleMap.payOff) {
                feeText = formatCurrencyFn(monthlyFee, { symbol: '$' });
                feeText += '/月';
            }
            return feeText;
        },
    },
    {
        cellKey: 'cabinetRentalStatusText',
        headerLabel: '狀態',
        align: 'center',
        formatCell: (cellValue, { cabinetRentalStatus, rentalMemo }) => {
            return (
                <StatusChipTooltip
                    statusText={cellValue}
                    statusMemo={rentalMemo}
                    color={RENT_CABINET_STATUS_COLOR_MATCH.get(cabinetRentalStatus)}
                    isShowTip={cabinetRentalStatus === cabinetStatusMap.pause}
                />
            );
        },
    },
    {
        cellKey: 'paymentCycleText',
        headerLabel: '繳費週期',
        align: 'center',
    },
    {
        cellKey: 'startDate',
        headerLabel: '租借期間',
        formatCell: (startDate, { endDate }) => {
            return displayDateText(startDate, endDate);
        },
    },
    {
        cellKey: 'keyStatusText',
        headerLabel: '鑰匙',
        align: 'center',
    },
    {
        cellKey: 'rentalMemo',
        headerLabel: '備註',
        formatCell: (cellValue) => {
            return <MemoControllerBox data={cellValue} />;
        },
    },
    {
        cellKey: 'more',
        headerLabel: '',
        align: 'right',
        isIcon: true,
        formatCell: (cellValue, rowsData, other) => {
            return <RentCabinetMoreLabel moreActionArray={getRentCabinetMoreActionArray(rowsData)} rowsData={rowsData} {...other} />;
        },
    },
];

const RentCabinetDocumentTable = () => {
    const { tableData, setBookmarkNum, refresh, dialogRef, reSetCheckedItemsRef } = useRentCabinetContext();
    const { list = [], totalCount, totalPage, pageIndex, pageSize } = tableData;
    const { checkBoxsRef, headerCheckBoxRef, onHeaderChange, onCellChange, getCheckedItems, getCheckedSourceData, doSaveCheckedItem, doClearCheckedItems } =
        useLabelCheckBox();
    const labelTitle = useRef(rowsLabel);
    const scrollerArrowRef = useRef(null);
    const refreshFn = () => {
        doClearCheckedItems();
        if (typeof refresh.current === 'function') refresh.current();
    };

    reSetCheckedItemsRef(doClearCheckedItems);

    const handlePageChange = (e, page) => {
        doSaveCheckedItem();
        setBookmarkNum(page);
    };

    const handleSendSMSClick = () => {
        let params = getCheckedSourceData();
        if (params.length === 0) return;
        params = params.map((target) => ({ id: target.memberID, type: 2, name: target.nickName || target.name }));
        dialogRef.current.openSendSmsDialog(params, refreshFn);
    };

    return (
        <React.Fragment>
            <Stack className={'mb-1'} alignItems={'center'} justifyContent={'space-between'}>
                {!isEmpty(list) && (
                    <Stack className="all-checkbox-header pb-1">
                        <LabelHeaderCheckBox ref={headerCheckBoxRef} onChange={onHeaderChange} />
                        <Stack>
                            <Tooltip title="群發送簡訊">
                                <IconButton onClick={handleSendSMSClick}>
                                    <PaperPlaneIcon />
                                </IconButton>
                            </Tooltip>
                        </Stack>
                    </Stack>
                )}
                <ListInfo
                    className={'ml-auto'}
                    pageIndex={pageIndex}
                    padeSize={pageSize}
                    totalCount={totalCount}
                    isCurrentPageFirstIndex
                    isCurrentPageLastIndex
                />
            </Stack>
            <Box className={'scroll-arrow-container'}>
                <Tablenization
                    cardProps={{ className: 'theme-gray overview' }}
                    count={totalPage}
                    page={pageIndex}
                    onChange={handlePageChange}
                    tableContainerRef={scrollerArrowRef}
                    Pagination={MorePageBox}
                    isPagination
                >
                    <Tablenization.TableRowHeader headerRow={labelTitle.current} />
                    <Tablenization.TableRowBody
                        headerRow={labelTitle.current}
                        rowsData={list}
                        dialogRef={dialogRef}
                        refresh={refreshFn}
                        checkBoxsRef={checkBoxsRef}
                        onCheckBoxChange={onCellChange}
                        getCheckedItems={getCheckedItems}
                    />
                </Tablenization>
                <ScrollerArrowButton parentRef={scrollerArrowRef} />
            </Box>
        </React.Fragment>
    );
};

export default RentCabinetDocumentTable;

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import { Button, Card, Form, MenuItem, SelectField } from '@common/components/';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { CheckOutContent, CreateOrderInfoSection } from '../AccountBookCreateOrderPage';
import { refIsRequiredError, isEmpty } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import useOrderCheckoutApi from '@apis/useOrderCheckoutApi';
import { receiverTypeMap } from '../staticData';
import { FieldByMember } from '../components/SetTargetSearchBar';
import useValidationErrors from '@util/hook/useValidationErrors';

const MemberInfoBox = (props) => {
    const { className, memberID, resource = {} } = props;
    const { receiverOptions } = resource;
    return (
        <Card className={clsx('search-bar', className)}>
            <Card.CardContent className="px-4 pt-2 pb-3">
                <Grid spacing={3} container>
                    <Grid xs={3} item>
                        <SelectField label={'對象'} defaultValue={receiverTypeMap.member} fullWidth disabled>
                            {Array.isArray(receiverOptions) &&
                                receiverOptions
                                    .filter((item) => item || !item.disabled)
                                    .map((item) => (
                                        <MenuItem key={item.value} value={item.value}>
                                            {item.text}
                                        </MenuItem>
                                    ))}
                        </SelectField>
                    </Grid>
                    <FieldByMember defaultValue={memberID} readOnly />
                </Grid>
            </Card.CardContent>
        </Card>
    );
};
const CreateOrderDialog = (props) => {
    const { className, open, memberID, onClose, refresh } = props;
    const { getOrderCheckoutOptionsApi, getOrderCheckoutApi, postOrderCheckoutByPeopleApi } = useOrderCheckoutApi();
    const setMessageDialog = useMessageDialog();
    const [resource, setResource] = useState({});
    const { validationErrors, updateValidationErrors, clearValidationErrors } = useValidationErrors();
    const orderInfoRef = useRef();
    const searchBarRef = useRef();
    const checkoutRef = useRef();
    const receiverType = receiverTypeMap.member;
    const getReceiver = () => receiverType;

    const doOptionsApi = async () => {
        let resp = await getOrderCheckoutOptionsApi();
        if (resp) {
            setResource(resp);
        }
    };

    const doConfirmMemberOrderApi = async (memberID) => {
        let res = await getOrderCheckoutApi(memberID);

        if (res) {
            if (checkoutRef.current && checkoutRef.current.setCreditOrder) {
                checkoutRef.current.setCreditOrder(res);
            }
            if (!isEmpty(res.orders)) {
                const handleClickConfirm = (checkoutRef) => {
                    if (checkoutRef.current && checkoutRef.current.setShowCreditOrders) checkoutRef.current.setShowCreditOrders(true);
                };
                setMessageDialog({
                    open: true,
                    title: '欠費提醒',
                    msg: '查詢到會員尚有未結清的訂單，請問是否於本次消費一起結帳？',
                    onConfirm: () => handleClickConfirm(checkoutRef),
                });
            }
        }
    };
    const doOrderCheckoutApi = async (params) => {
        let resp = await postOrderCheckoutByPeopleApi(params);
        const { isError = false, result } = resp || {};

        if (isError) {
            // 0002 錯誤
            updateValidationErrors(result);
        } else if (isEmpty(resp)) {
            // 失敗
            clearValidationErrors();
        } else {
            clearValidationErrors();
            refresh && refresh();
            onClose();
        }
    };
    const getParams = () => {
        let result = {
            receiverType,
            receiverID: memberID,
        };
        if (orderInfoRef.current && orderInfoRef.current.getResult) {
            result = Object.assign(result, orderInfoRef.current.getResult());
        }
        if (checkoutRef.current && checkoutRef.current.getResult) {
            result = Object.assign(result, checkoutRef.current.getResult());
        }
        return result;
    };
    const checkError = () => {
        return refIsRequiredError(orderInfoRef, searchBarRef, checkoutRef);
    };

    const handleSubmit = () => {
        let params = getParams();
        let isError = checkError();
        if (!isError) {
            doOrderCheckoutApi(params);
        }
    };

    useEffect(() => {
        if (open) {
            doOptionsApi();
            if (memberID) doConfirmMemberOrderApi(memberID);
        }
        // eslint-disable-next-line
    }, [open]);

    if (!open && isEmpty(resource)) return null;

    return (
        <Dialog
            className={clsx('simple-content', 'dialog', className)}
            PaperProps={{ className: 'wx-90rem', component: Form }}
            onSubmit={handleSubmit}
            open={open}
            fullWidth
        >
            <GradientColorHeader onClose={onClose}>會員購物</GradientColorHeader>
            <DialogContent className={'p-4 bg-light'}>
                <CreateOrderInfoSection className={'mb-2'} ref={orderInfoRef} resource={resource} />
                <MemberInfoBox className={'mb-2'} memberID={memberID} sourceData={resource} />
                <CheckOutContent ref={checkoutRef} resource={resource} getReceiver={getReceiver} errorModule={validationErrors} isShowCash isShoCredit />
            </DialogContent>
            <DialogActions className={'bg-light'}>
                <Button type={'submit'} variant="contained">
                    確認送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateOrderDialog;

import React, { useState, useRef, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import ProcessCircleBox from '@icoach/inbody/components/ProcessCircleBox';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import InBodyInterviewCancelMeasureDialog from '@icoach/inbody/components/InBodyInterviewCancelMeasureDialog';
import { Button, SelectField, MenuItem, TextField, RadioField, Grid } from '@common/components/';
import useEmployeeApi from '@apis/useEmployeeApi';
import useInBodyApi from '@apis/useInBodyApi';
import { refIsRequiredError } from '@util/utils';
import useToggleDialog from '@util/hook/useToggleDialog';

const dialogTypeMap = {
    cancelMeasure: 'cancelMeasure',
    addMeasure: 'addMeasure',
};

const InBodyVisitorsDialog = React.forwardRef((props, ref) => {
    // const { employeesOptions } = options;
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const { openTarget, handleOpenDialog, handleCloseDialog } = useToggleDialog();
    const [openProcessCircle, setOpenProcessCircle] = useState(false);
    const [employeesOptions, setEmployeesOptions] = useState([]);
    const employeesRef = useRef(null);
    const heightRef = useRef(null);
    const ageRef = useRef(null);
    const genderRef = useRef(null);

    const { postTriggerMeasureApi } = useInBodyApi();

    const getAllParams = (emp, heig, age) => {
        return {
            age: age,
            employeeID: emp,
            height: heig,
            referenceID: 0,
            referenceType: 3,
            gender: genderRef.current.getResult().find((target) => target.checked)?.value ?? 1,
        };
    };

    const handleStartInBodyMeasure = () => {
        if (!refIsRequiredError(employeesRef, heightRef)) {
            const employeeID = employeesRef.current.getResult();
            const height = heightRef.current.getResult();
            const age = ageRef.current.getResult();
            const params = getAllParams(employeeID, height, age);
            doPrepareInbodyMeasureApi(params);
        }
    };

    const handleVisitorsMeasure = () => {
        handleOpenDialog(dialogTypeMap.addMeasure);
    };

    const doCloseDialog = () => {
        setOpenProcessCircle(null);
        handleCloseDialog();
    };

    const doPrepareInbodyMeasureApi = async (params) => {
        const resp = await postTriggerMeasureApi(params);
        if (resp.isError) {
            if (resp.statusCode === 'ME003') {
                handleOpenDialog(dialogTypeMap.cancelMeasure);
            }
        } else {
            setOpenProcessCircle(true);
        }
    };

    const doInitOptions = async () => {
        const resp = await getEmployeesOptionsApi();
        if (resp) {
            setEmployeesOptions(resp);
        }
    };

    useEffect(
        () => {
            doInitOptions();
            return () => doCloseDialog();
        },
        // eslint-disable-next-line
        [],
    );

    if (employeesOptions.length === 0) return null;

    return (
        <React.Fragment>
            <Button onClick={handleVisitorsMeasure} variant="outlined" color="secondary">
                訪客量身
            </Button>
            <Dialog open={openTarget === dialogTypeMap.addMeasure} PaperProps={{ className: 'wx-40rem' }} fullWidth>
                <GradientColorHeader onClose={doCloseDialog}>新增訪客量身</GradientColorHeader>
                {!openProcessCircle ? (
                    <React.Fragment>
                        <DialogContent>
                            <div className="pt-3 px-3">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <SelectField direction={'row'} label="擔當" displayEmpty required ref={employeesRef} fullWidth>
                                            <MenuItem disabled value="">
                                                <em>請選擇</em>
                                            </MenuItem>
                                            {employeesOptions.map(({ value, text, disabled }) => (
                                                <MenuItem key={value} value={value} disabled={disabled}>
                                                    {text}
                                                </MenuItem>
                                            ))}
                                        </SelectField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField direction={'row'} ref={ageRef} label="年齡" type={'number'} defaultValue={18} fullWidth required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField direction={'row'} ref={heightRef} label="身高" type={'number'} defaultValue={160} fullWidth required />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <RadioField ref={genderRef} defaultValue={1} label="性別" labelDirection="row" required row>
                                            <RadioField.Radio value={1} label={'女'} />
                                            <RadioField.Radio value={2} label={'男'} />
                                        </RadioField>
                                    </Grid>
                                </Grid>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleStartInBodyMeasure}>開始量身</Button>
                        </DialogActions>
                    </React.Fragment>
                ) : (
                    <DialogContent>
                        <ProcessCircleBox className="pt-3 px-3" onClose={doCloseDialog} />
                    </DialogContent>
                )}
            </Dialog>
            <InBodyInterviewCancelMeasureDialog open={openTarget === dialogTypeMap.cancelMeasure} onClose={handleCloseDialog} refresh={handleVisitorsMeasure} />
        </React.Fragment>
    );
});

export default InBodyVisitorsDialog;

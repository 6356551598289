import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import { Grid } from '@mui/material';
import { PrivateInformationBox, SignBox } from '@icoach/components';
import { RadioField, TextField, Box, CheckboxField, Stack, Button, Typography } from '@common/components/';
import useDocumentApi from '@apis/useDocumentApi';
import { getErrorModuleStatus, refIsRequiredError } from '@util/utils';
import { getRadioValue } from '@icoach/documents/tools';

const CREDIT_CARD_TYPE_OPTIONS = [
    {
        text: 'VISA',
        value: 1,
    },
    {
        text: 'MasterCard',
        value: 2,
    },
    {
        text: 'JCB',
        value: 3,
    },
];

const CreditCard = React.forwardRef((props, ref) => {
    const { getDocumentCardAuthPDFDownloadApi } = useDocumentApi();
    const {
        className,
        defaultValue = {},
        errorModule = {},
        cardRelationProps,
        ownerProps = {},
        isPersonalSignatureRequired = false, // 顯示簽名欄位，本人是否需要簽名
        isDisplaySignature = false, // 是否顯示簽名欄位
        isShowCardRelation = false,
        isShowCreditCardBankName = false,
        isShowCreditCardPDF = false,
        required: requiredProps = false,
        readOnly: readOnlyProp = false,
    } = props;

    const initCreditCardData = {
        creditCardNo: '',
        cardExpiryDate: '',
        cardName: '',
        creditCardBankName: '',
        creditCardType: 1,
        cardRelation: '',
    };

    const [isOwner, setIsOwner] = useState(false);
    const [creditCardInfo, setCreditCardInfo] = useState({
        creditCardNo: '',
        cardExpiryDate: '',
        cardRelation: '',
    });
    const creditCardNoRef = useRef();
    const cardExpiryDateRef = useRef();
    const cardNameRef = useRef();
    const creditCardBankNameRef = useRef();
    const creditCardTypeRef = useRef();
    const cardRelationRef = useRef();
    const signImgRef = useRef(null);

    const isClearedRef = useRef(false);

    const showSign = isDisplaySignature ? (creditCardInfo.cardRelation === '本人' ? isPersonalSignatureRequired : true) : false;

    const handleIsOwnerChange = (e, value) => {
        ownerProps && ownerProps.onChange && ownerProps.onChange(e, value);
        setIsOwner(value);
    };

    const handleCreditCardNoChange = (e) => {
        const obj = {
            creditCardNo: e.target.value,
        };

        if (!isClearedRef.current) {
            obj.cardExpiryDate = '';
        }

        isClearedRef.current = true;

        setCreditCardInfo((prev) => ({
            ...prev,
            ...obj,
        }));
    };

    const handleCardExpiryDateChange = (e, ...rest) => {
        const obj = {
            cardExpiryDate: e.target.value,
        };

        if (!isClearedRef.current) {
            obj.creditCardNo = '';
        }

        isClearedRef.current = true;

        setCreditCardInfo((prev) => ({
            ...prev,
            ...obj,
        }));
    };

    const handleCardRelationChange = (e) => {
        setCreditCardInfo((prev) => {
            return {
                ...prev,
                cardRelation: e.target.value,
            };
        });
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                let result = {
                    ...initCreditCardData,
                };
                if (creditCardNoRef.current && creditCardNoRef.current.getResult) {
                    result.creditCardNo = creditCardNoRef.current.getResult();
                }
                if (cardExpiryDateRef.current && cardExpiryDateRef.current.getResult) {
                    result.cardExpiryDate = cardExpiryDateRef.current.getResult();
                }
                if (cardNameRef.current && cardNameRef.current.getResult) {
                    result.cardName = cardNameRef.current.getResult();
                }
                if (creditCardBankNameRef.current && creditCardBankNameRef.current.getResult) {
                    result.creditCardBankName = creditCardBankNameRef.current.getResult();
                }
                if (creditCardTypeRef.current && creditCardTypeRef.current.getResult) {
                    result.creditCardType = getRadioValue(creditCardTypeRef.current.getResult()) || 0;
                }
                if (isShowCardRelation) {
                    let cardRelation = '本人';
                    if (cardRelationRef.current && cardRelationRef.current.getResult) cardRelation = cardRelationRef.current.getResult();
                    result.cardRelation = cardRelation;
                }
                if (signImgRef.current && signImgRef.current.getResult) result.signImg = signImgRef.current.getResult();
                return result;
            },
            isError: () => {
                return refIsRequiredError(
                    creditCardNoRef,
                    cardExpiryDateRef,
                    cardNameRef,
                    creditCardBankNameRef,
                    creditCardTypeRef,
                    cardRelationRef,
                    signImgRef,
                );
            },
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (!isEmpty(defaultValue)) {
            const { cardRelation, creditCardNo, cardExpiryDate, creditCardType } = defaultValue;

            setCreditCardInfo({
                creditCardNo,
                cardExpiryDate,
                creditCardType,
                cardRelation,
            });

            if (cardRelation === '本人') {
                setIsOwner(true);
            }
        }
    }, [isDisplaySignature, isPersonalSignatureRequired, defaultValue]);

    useEffect(
        () => {
            if (isOwner && creditCardInfo.cardRelation !== '本人') {
                setCreditCardInfo((prev) => {
                    return {
                        ...prev,
                        cardRelation: '本人',
                    };
                });
            }
        },
        // eslint-disable-next-line
        [isOwner, creditCardInfo.cardRelation],
    );

    return (
        <Box className={clsx('credit-card', className)}>
            <Grid container spacing={2}>
                <Grid xs={4} item>
                    <PrivateInformationBox
                        label={'信用卡卡號'}
                        ref={creditCardNoRef}
                        value={creditCardInfo?.creditCardNo}
                        {...getErrorModuleStatus(errorModule, 'creditCardNo')}
                        readOnly={readOnlyProp}
                        required={readOnlyProp ? !readOnlyProp : requiredProps}
                        maskType={'CCN'}
                        as={TextField}
                        onChange={handleCreditCardNoChange}
                        fullWidth
                    />
                </Grid>
                <Grid xs={4} item>
                    <TextField
                        {...getErrorModuleStatus(errorModule, 'cardExpiryDate')}
                        label={'到期日 月/年份'}
                        ref={cardExpiryDateRef}
                        value={creditCardInfo?.cardExpiryDate}
                        maskType={'CVV2'}
                        readOnly={readOnlyProp}
                        required={readOnlyProp ? !readOnlyProp : requiredProps}
                        onChange={handleCardExpiryDateChange}
                        fullWidth
                    />
                </Grid>
                <Grid xs={4} item>
                    {isShowCreditCardBankName && (
                        <TextField
                            label={'發卡機構'}
                            key={defaultValue?.creditCardBankName}
                            ref={creditCardBankNameRef}
                            defaultValue={defaultValue?.creditCardBankName}
                            {...getErrorModuleStatus(errorModule, 'creditCardBankName')}
                            readOnly={readOnlyProp}
                            fullWidth
                        />
                    )}
                </Grid>
                <Grid xs={4} item>
                    <TextField
                        label={'持卡人姓名'}
                        key={defaultValue?.cardName}
                        ref={cardNameRef}
                        defaultValue={defaultValue?.cardName}
                        {...getErrorModuleStatus(errorModule, 'cardName')}
                        readOnly={readOnlyProp}
                        fullWidth
                    />
                </Grid>
                <Grid xs={8} item>
                    <RadioField
                        key={defaultValue?.creditCardType}
                        label={'卡別'}
                        ref={creditCardTypeRef}
                        defaultValue={defaultValue?.creditCardType}
                        {...getErrorModuleStatus(errorModule, 'creditCardType')}
                        readOnly={readOnlyProp}
                        fullWidth
                        row
                    >
                        {Array.isArray(CREDIT_CARD_TYPE_OPTIONS) &&
                            CREDIT_CARD_TYPE_OPTIONS.map(({ text, value }) => <RadioField.Radio key={text} value={value} label={text} />)}
                    </RadioField>
                </Grid>
                {isShowCardRelation && (
                    <React.Fragment>
                        <Grid xs={12} item>
                            <Stack justifyContent={'start'} alignItems={'start'} flexWrap={'wrap'}>
                                <CheckboxField key={isOwner} defaultValue={[isOwner]} {...ownerProps} onChange={handleIsOwnerChange} required row>
                                    <CheckboxField.Checkbox value={true} label={'卡片是否為本人持有'} readOnly={readOnlyProp} />
                                </CheckboxField>
                                {!isOwner && (
                                    <TextField
                                        label={'卡片關係為'}
                                        ref={cardRelationRef}
                                        value={creditCardInfo?.cardRelation || ''}
                                        {...getErrorModuleStatus(errorModule, 'cardRelation')}
                                        readOnly={readOnlyProp}
                                        required={requiredProps}
                                        direction={'row'}
                                        onChange={handleCardRelationChange}
                                        {...cardRelationProps}
                                    />
                                )}
                            </Stack>
                        </Grid>
                    </React.Fragment>
                )}
                {showSign && (
                    <Grid xs={6} item>
                        <SignBox
                            title={'持卡人簽名'}
                            ref={signImgRef}
                            defaultValue={defaultValue?.signImg}
                            {...getErrorModuleStatus(errorModule, 'signImg')}
                            readOnly={readOnlyProp}
                        />
                        <Typography variant={'body2'} className={'font-color-3'}>
                            (須與信用卡背面簽名一致)
                        </Typography>
                    </Grid>
                )}
                {!readOnlyProp && isShowCreditCardPDF && (
                    <Grid xs={6} item>
                        <Stack className={'h-100'} alignItems={'end'}>
                            <Button
                                variant={'contained'}
                                onClick={() => {
                                    getDocumentCardAuthPDFDownloadApi();
                                }}
                            >
                                信用卡授權書
                            </Button>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Box>
    );
});

export default CreditCard;

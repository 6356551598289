import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import useSwitch from '@util/hook/useSwitch';
import { v4 as uuid } from 'uuid';
import { isEmpty, refIsRequiredError } from '@util/utils';
import { FormControlLabel, MenuItem, SelectField, Stack, Switch } from '@common/components/';
import useEmployeeApi from '@apis/useEmployeeApi';

const FieldByEmployee = React.forwardRef(({ referenceID, isLock = false }, ref) => {
    const { isOn, handleToggle } = useSwitch();
    const employeeOption = useFetchEmployeeOptions(isOn);
    const [key, setKey] = useState(uuid());
    const referenceIDRef = useRef();

    useEffect(() => {
        if (referenceID || !isEmpty(employeeOption)) {
            setKey(uuid());
        }
    }, [referenceID, employeeOption]);

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                return {
                    referenceID: referenceIDRef.current.getResult(),
                };
            },
            isError: () => refIsRequiredError(referenceIDRef),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Stack className={'field-employee'} spacing={1}>
            <SelectField key={key} ref={referenceIDRef} label={'教練'} defaultValue={referenceID || ''} disabled={isLock} fullWidth required>
                {Array.isArray(employeeOption) &&
                    employeeOption.map((item) => (
                        <MenuItem key={item.employeeID} value={item.employeeID}>
                            {item.nickName}
                        </MenuItem>
                    ))}
            </SelectField>
            <FormControlLabel
                key={isOn.toString()}
                className={'mt-4'}
                control={<Switch defaultChecked={isOn} onChange={handleToggle} />}
                prefixLabel={'包含無效教練'}
            />
        </Stack>
    );
});

const useFetchEmployeeOptions = (isAllEmployees = false) => {
    const { getEmployeesListStatusOptionsApi } = useEmployeeApi();
    const [option, setOption] = useState();

    const doEmployeesListStatusOptionsApi = async (employeeStatus) => {
        const resp = await getEmployeesListStatusOptionsApi({ employeeStatus });
        if (resp) {
            setOption(resp);
        }
    };

    useEffect(
        () => {
            const employeeEmploymentStatus = isAllEmployees ? 99 : 1;
            doEmployeesListStatusOptionsApi(employeeEmploymentStatus);
        },
        // eslint-disable-next-line
        [isAllEmployees],
    );

    return option;
};

export default FieldByEmployee;

import React from 'react';
import { Grid } from '@mui/material';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox } from '@icoach/components/';
import { Check as CheckIcon, Close as CloseIcon } from '@common/SvgIcon/';
import { MenuItem, SelectField, Stack, TextComponent } from '@common/components/';
import { parseDate } from '@util/moment';
import { refIsRequiredError, findIndexFn } from '@util/utils';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

export const FilterNewMemberSearch = React.forwardRef((props, ref) => {
    const { resource = {}, paramsData } = props;
    const { peopleSourceTypeOption: sourceTypeOption = [] } = resource;
    const sourceTypeRef = React.useRef(null);

    React.useImperativeHandle(ref, () => {
        return {
            isError: () => refIsRequiredError(sourceTypeRef),
            getResult: () => {
                let result = {};
                if (sourceTypeRef.current && sourceTypeRef.current.getResult) {
                    result = Object.assign(result, {
                        sourceType: sourceTypeRef.current.getResult(),
                    });
                }
                return result;
            },
        };
    });

    const sourceType = findIndexFn(sourceTypeOption, 'value', paramsData.sourceType) !== -1 ? paramsData.sourceType : '999';

    return (
        <React.Fragment>
            <Grid item xs={2}>
                <SelectField label={'來源'} defaultValue={sourceType} ref={sourceTypeRef} fullWidth>
                    {Array.isArray(sourceTypeOption) &&
                        sourceTypeOption
                            .filter((item) => item || !item.disabled)
                            .map((item) => (
                                <MenuItem key={item.value} value={item.value}>
                                    {item.text}
                                </MenuItem>
                            ))}
                </SelectField>
            </Grid>
        </React.Fragment>
    );
});

//********************** 新會員名單 **********************//
export const FilterNewMemberLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'specialCondition',
        headerLabel: '特殊狀況',
        width: 18,
        formatCell: (cellValue, { memberID }) => {
            return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
        },
    },
    {
        cellKey: 'contractSendDate',
        headerLabel: '入會申請送出日',
        width: 16,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'firstWorkoutAppointmentDateTime',
        headerLabel: 'FWO預約日',
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'secondWorkoutAppointmentDateTime',
        headerLabel: '2WO預約日',
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'thirdWorkoutAppointmentDateTime',
        headerLabel: '3WO預約日',
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'sourceType',
        headerLabel: '來源',
        align: 'center',
        formatCell: (value) => <TextComponent breakWord={' '} text={value} /> 
    },
    {
        cellKey: 'firstWorkoutDateTime',
        headerLabel: '第1次WO日期&時間',
        width: 19,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
    },
    {
        cellKey: 'secondWorkoutDateTime',
        headerLabel: '第2次WO日期&時間',
        width: 19,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
    },
    {
        cellKey: 'thirdWorkoutDateTime',
        headerLabel: '第3次WO日期&時間',
        width: 19,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
    },
    {
        cellKey: 'fourthWorkoutDateTime',
        headerLabel: '第4次WO日期&時間',
        width: 19,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
    },
    {
        cellKey: 'fifthWorkoutDateTime',
        headerLabel: '第5次WO日期&時間',
        width: 19,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayFullBySlash),
    },
    {
        cellKey: 'quarterlyWorkoutFrequency',
        headerLabel: '前12週WO頻率',
        width: 22,
        align: 'center',
    },
    {
        cellKey: 'lastThreeMonthsMeasureRecord',
        headerLabel: '最近3個月是否量身',
        width: 22,
        align: 'center',
        formatCell: (value) => {
            const mAry = Array.isArray(value) ? value : [];
            return (
                <Stack justifyContent="center">
                    {mAry.map((checked, index) => (checked ? <CheckIcon key={index} color="success" /> : <CloseIcon key={index} color="error" />))}
                </Stack>
            );
        },
    },
    {
        cellKey: 'proteinStatus',
        headerLabel: '乳清訂單狀態',
        align: 'center',
    },
    {
        cellKey: 'probioticsStatus',
        headerLabel: '益生箘訂單狀態',
        width: 16,
        align: 'center',
    },
    {
        cellKey: 'calciumStatus',
        headerLabel: '鈣訂單狀態',
        width: 16,
        align: 'center',
    },
    {
        cellKey: 'day30GiftText',
        headerLabel: '領取新手30',
        align: 'center',
        formatCell: (value) => (value ? <CheckIcon color="success" /> : ''),
    },
    {
        cellKey: 'day90GiftText',
        headerLabel: '領取新手90',
        align: 'center',
        formatCell: (value) => (value ? <CheckIcon color="success" /> : ''),
    },
    { ...contactSheet },
    { ...moreSheet },
];

import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import useShowPDF from '@util/hook/useShowPDF';
import { isEmpty } from '@util/utils';

const GET_DOC_MEMBERSHIP_TRANSFER_OVER_OPTIONS_API = `/api/doc/membership/transfer-over/options/`;
const GET_DOC_MEMBERSHIP_TRANSFER_OVER_HISTORY_API = `/api/doc/membership/transfer-over/history/`;
const GET_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API = (contractID) => `/api/doc/membership/transfer-over/${contractID}/`;
const POST_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API = GET_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API;
const DELETE_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API = GET_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API;
const GET_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API = `/api/doc/membership/transfer-over/temporary/`;
const POST_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API = GET_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API;
const DELETE_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API = GET_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API;

const useDocMembershipTransferOverApi = () => {
    const Ajax = useAjax();
    const showPDF = useShowPDF();
    const { enqueueSnackbar } = useSnackbar();

    // search 預設option
    const getInitTransferOverOptionApi = useCallback(
        async () => {
            const respOptions = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OVER_OPTIONS_API);
            if (!respOptions) {
                enqueueSnackbar('預載資料有誤，請重新整理', { variant: 'error' });
            }
            return respOptions;
        },
        // eslint-disable-next-line
        [],
    );

    // 會籍轉讓歷史紀錄
    const postTransferOverContractHistoryApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OVER_HISTORY_API, params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 讀取會籍轉讓申請書
    const getTransferOverContractApi = useCallback(
        async (contractID, params) => {
            const resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API(contractID), params);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
                return null;
            } else if (resp.isError) {
                return null;
            } else {
                return resp;
            }
        },
        // eslint-disable-next-line
        [],
    );

    // 新增會籍轉讓申請書
    const postTransferOverContractApi = useCallback(
        async (contractID, params) => {
            const resp = await Ajax.post(POST_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API(contractID), params);
            if (!isEmpty(resp) && resp.isError && typeof resp.result === 'string') return null;
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 刪除會籍轉讓申請書
    const deleteTransferOverContractApi = useCallback(
        async (contractID) => {
            const resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_TRANSFER_OVER_CONTRACTID_API(contractID));
            if (resp) {
                enqueueSnackbar('刪除成功', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 確認是否有會籍轉讓暫存資料
    const getDocTemporaryTransferOverContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.get(GET_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API, params);
            if (resp !== 0 && !resp) {
                enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 暫存會籍轉讓暫存資料
    const postDocTemporaryTransferOverContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.post(POST_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API, params);
            if (resp) {
                enqueueSnackbar('暫存轉讓申請書成功', { variant: 'success' });
                return resp;
            } else {
                enqueueSnackbar('新增暫存失敗，請重新操作', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 刪除暫存會籍轉讓暫存資料
    const deleteDocTemporaryTransferOverContractApi = useCallback(
        async (params) => {
            let resp = await Ajax.delete(DELETE_DOC_MEMBERSHIP_TRANSFER_OVER_TEMPORARY_API, params);
            if (!resp) {
                enqueueSnackbar('刪除失敗，請重新操作', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 轉讓申請書PDF
    const downloadTransferOverPDFApi = useCallback(
        async (params) => {
            const { contractUrl } = params;
            showPDF({
                pdfUrl: contractUrl,
                open: true,
            });
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getInitTransferOverOptionApi,
        postTransferOverContractHistoryApi,
        getTransferOverContractApi,
        postTransferOverContractApi,
        deleteTransferOverContractApi,
        getDocTemporaryTransferOverContractApi,
        postDocTemporaryTransferOverContractApi,
        deleteDocTemporaryTransferOverContractApi,
        downloadTransferOverPDFApi,
    };
};

export default useDocMembershipTransferOverApi;

import React, { useEffect, useRef, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getVersionData } from '@redux/version/selector';
import clsx from 'clsx';
import { useSnackbar } from 'notistack';
import { Button, Stack } from '@common/components/';
import useShowPDF from '@util/hook/useShowPDF';
import { parseDate } from '@util/moment';
import { objectToQueryString } from '@util/utils';
import useDocumentApi from '@apis/useDocumentApi';
import useInBodyApi from '@apis/useInBodyApi';
import useMeasureLeaderboard from '@apis/useMeasureLeaderboard';
import useSignalr from '../../../../signalr/useSignalr';

const ProcessCircleBox = (props) => {
    const {
        className,
        refresh,
        onClose: onCloseProps,
        onOpenImpedanceDialog: onOpenImpedanceDialogProps,
        peopleID: peopleIDProps = 0,
        referenceType: referenceTypeProps,
    } = props;
    const { postCancelMeasureApi } = useInBodyApi();
    const { getTranscriptPDFApi } = useDocumentApi();
    const { getCheckHasTranscriptApi } = useMeasureLeaderboard();
    const [path, setPath] = useState(null);
    const { enqueueSnackbar } = useSnackbar();
    const showPDF = useShowPDF();
    const measureDateRef = useRef(null);
    const barRef = useRef(null);
    const contRef = useRef(null);
    const signalr = useSignalr();
    const reduxVersion = useSelector(getVersionData);

    const handleProcess = useCallback(
        (text) => {
            const barDom = barRef.current;
            const contDom = contRef.current;
            let processNum = parseInt(text, 10);
            const r = barDom.getAttribute('r');
            const c = Math.PI * (r * 2);
            if (processNum < 0) processNum = 0;
            if (processNum > 100) processNum = 100;
            if (processNum === 100) {
                enqueueSnackbar('量身完畢，即將開啟套表畫面', { variant: 'success' });
            }
            const pct = ((100 - processNum) / 100) * c;
            barDom.style['stroke-dashoffset'] = pct;
            contDom.setAttribute('data-pct', processNum);
            // eslint-disable-next-line
        },
        // eslint-disable-next-line
        []
    );

    const handleTransript = (text) => {
        const date = parseDate(text, 'YYYY-MM-DD');
        if (peopleIDProps && date) {
            measureDateRef.current = date;
        }
    };

    const handleCloseWindowOnClick = () => {
        onCloseProps();
    };

    const showTranscript = useCallback(
        async () => {
            if (['3', '4', '5'].includes(String(referenceTypeProps))) return; // 顧客 教練 不會有綜合評估表和成績單
            const { env } = reduxVersion;
            // 0913 特殊判斷 綜合評估比還沒上線 只在UAT DEV測試
            if (env.toUpperCase() === 'PRODUCTION') {
                const resp = await getCheckHasTranscriptApi(peopleIDProps, { measureDate: measureDateRef.current });
                if (resp) {
                    await getTranscriptPDFApi(objectToQueryString({ memberID: peopleIDProps, measureDate: measureDateRef.current }), 0, peopleIDProps);
                }
            } else {
                await getTranscriptPDFApi(objectToQueryString({ memberID: peopleIDProps, measureDate: measureDateRef.current }), 0, peopleIDProps);
            }
        },
        // eslint-disable-next-line
        []
    );

    const handleOpenPDF = useCallback(
        (text) => {
            setPath(text);

            showPDF({
                open: true,
                pdfUrl: text,
                onClose: () => {
                    setTimeout(() => showTranscript(), 10);
                },
            });
            signalr.stop();
            refresh && refresh();
        },
        // eslint-disable-next-line
        [showTranscript]
    );

    const handleUnusualImpedance = useCallback(
        (text) => {
            onOpenImpedanceDialogProps(text);
        },
        // eslint-disable-next-line
        []
    );

    const doSignalrConnect = async () => {
        await signalr.start();
        await signalr.on('RECEIVE_INBODY_PROCESS', handleProcess);
        await signalr.on('RECEIVE_INBODY_UNUSUAL_IMPEDANCE', handleUnusualImpedance);
        await signalr.on('RECEIVE_INBODY_PDF_PATH', handleOpenPDF);
        await signalr.on('RECEIVE_INBODY_MEASURE_DAY', handleTransript);
    };

    const handleOpenPDFOnClick = useCallback(
        () => {
            // 不需要轉 源頭已經format
            if (path) {
                showPDF({
                    open: true,
                    pdfUrl: path,
                    onClose: () => {
                        setTimeout(() => showTranscript(), 10);
                    },
                });
            }
        },
        // eslint-disable-next-line
        [showTranscript, path]
    );

    useEffect(
        () => {
            doSignalrConnect();
            return () => {
                signalr.stop();
                postCancelMeasureApi();
            };
        },
        // eslint-disable-next-line
        []
    );

    return (
        <React.Fragment>
            <div className={clsx('process-circle', className)} key={'process-circle'}>
                <div className="cont" ref={contRef} data-pct={0}>
                    <svg className="svg" width="200" height="200" viewport="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
                        <circle r="90" cx="100" cy="100" fill="transparent" strokeDasharray="565.48" strokeDashoffset="0"></circle>
                        <circle ref={barRef} className="bar" r="90" cx="100" cy="100" fill="transparent" strokeDasharray="565.48" strokeDashoffset=""></circle>
                    </svg>
                </div>
            </div>
            {!!path ? (
                <Stack justifyContent="end" direction="row" spacing={2}>
                    <Button onClick={handleOpenPDFOnClick} variant="outlined">
                        套表
                    </Button>
                    <Button onClick={onCloseProps} variant="contained">
                        關閉
                    </Button>
                </Stack>
            ) : (
                <Stack justifyContent="center" direction="row" spacing={2}>
                    <Button variant="contained" onClick={handleCloseWindowOnClick}>
                        取消量身
                    </Button>
                </Stack>
            )}
        </React.Fragment>
    );
};

export default ProcessCircleBox;

import React, { useRef, useLayoutEffect, useImperativeHandle, useState } from 'react';
import { useParams } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import MembershipTransferInDiscountDateItem from './MembershipTransferInDiscountDateItem';
import MembershipTransferInSuspendDateItem from './MembershipTransferInSuspendDateItem';
import MembershipTransferInContactDateItem from './MembershipTransferInContactDateItem';
import { TextField, DateIntervalField, DateField, RadioField, CheckboxField, Button, Typography, Stack, AutocompleteField } from '@common/components/';
import { FieldGroup } from '@icoach/documents/components/Layout';
import { getRadioFieldResult } from '@util/utils';
import useDocMembershipTransferInApi from '@apis/useDocMembershipTransferInApi';
import { checkIsManualTransferIn } from '@icoach/documents/tools';

/**
 * peopleType === 11 意思為手動輸入 非2.0轉入2.0實用的功能
 */

// 原會籍
const MembershipTransferInOrigingMembership = React.forwardRef((props, ref) => {
    const { temporarySourceDataRef, options, isReadOnly = false } = props;
    const [refreshKey, setRefreshKey] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');
    const { postTransferInRangeApi } = useDocMembershipTransferInApi();
    const { peopleType } = useParams();
    const { membershipMemberTypeOptions, membershipTermOptions, paymentTypeOptions, giftOptions, suspendOptions, clubOptions } = options;

    const {
        originalIsNewContract,
        originalMemberType,
        originalPaymentType,
        originalMembershipTerm,
        originalContractStartDate,
        originalContractEndDate,
        originalGiftItems,
        originalSuspendItems,
        originalMemberShipStartDate,
        originalMemberShipEndDate,
        originalNextMembershipStartDate,
        originalNextMembershipEndDate,
        originalJoinFee,
        originalMonthlyFee,
        originalDebitDay,
        originalFADate,
        originalFWODate,
        originalWOTimes,
        originalClubID,
        originalIsRenew,
        isManual = false,
    } = temporarySourceDataRef.current;

    const originalIsRenewRef = useRef(null);
    const originalIsNewContractRef = useRef(null);
    const originalMemberTypeRef = useRef(null);
    const originalPaymentTypeRef = useRef(null);
    const originalMembershipTermRef = useRef(null);
    const originalContractDateRef = useRef(null);
    const originalGiftItemsRef = useRef(null);
    const originalSuspendItemsRef = useRef(null);
    const originalMemberShipDateRef = useRef(null);
    const originalJoinFeeRef = useRef(null);
    const originalMonthlyFeeRef = useRef(null);
    const originalDebitDayRef = useRef(null);
    const originalFADateRef = useRef(null);
    const originalFWODateRef = useRef(null);
    const originalWOTimesRef = useRef(null);
    const originalClubIDRef = useRef(null);

    const setParams = () => {
        if (isReadOnly) return false;
        const [originalIsRenewRefChecked] = originalIsRenewRef.current?.getResult() ?? [null];
        const [isNewContract] = originalIsNewContractRef.current.getResult();
        const radioMemberType = originalMemberTypeRef.current.getResult();
        const radioPaymentType = originalPaymentTypeRef.current.getResult();
        const radioMembershipTerm = originalMembershipTermRef.current.getResult();
        const { startDate: originalContractStartDate, endDate: originalContractEndDate } = originalContractDateRef.current.getResult();
        const { startDate: originalMemberShipStartDate, endDate: originalMemberShipEndDate } = originalMemberShipDateRef.current.getResult();

        if (originalIsRenewRefChecked) temporarySourceDataRef.current.originalIsRenew = originalIsRenewRefChecked.checked;
        temporarySourceDataRef.current.originalIsNewContract = isNewContract.checked;
        temporarySourceDataRef.current.originalMemberType = getRadioFieldResult(radioMemberType, 'value', 0);
        temporarySourceDataRef.current.originalPaymentType = getRadioFieldResult(radioPaymentType, 'value', 0);
        temporarySourceDataRef.current.originalMembershipTerm = getRadioFieldResult(radioMembershipTerm, 'value', 0);
        temporarySourceDataRef.current.originalSuspendItems = originalSuspendItemsRef.current.getResult();
        temporarySourceDataRef.current.originalGiftItems = originalGiftItemsRef.current.getResult();
        temporarySourceDataRef.current.originalContractStartDate = originalContractStartDate;
        temporarySourceDataRef.current.originalContractEndDate = originalContractEndDate;
        temporarySourceDataRef.current.originalMemberShipStartDate = originalMemberShipStartDate;
        temporarySourceDataRef.current.originalMemberShipEndDate = originalMemberShipEndDate;
        temporarySourceDataRef.current.originalNextMembershipStartDate = originalNextMembershipStartDate;
        temporarySourceDataRef.current.originalNextMembershipEndDate = originalNextMembershipEndDate;
        temporarySourceDataRef.current.originalJoinFee = originalJoinFeeRef.current.getResult() * 1;
        temporarySourceDataRef.current.originalMonthlyFee = originalMonthlyFeeRef.current.getResult() * 1;
        temporarySourceDataRef.current.originalDebitDay = originalDebitDayRef.current.getResult() * 1;
        temporarySourceDataRef.current.originalFADate = originalFADateRef.current.getResult();
        temporarySourceDataRef.current.originalFWODate = originalFWODateRef.current.getResult();
        temporarySourceDataRef.current.originalWOTimes = originalWOTimesRef.current.getResult() * 1;
        temporarySourceDataRef.current.originalClubID = originalClubIDRef.current.getResult().value || null;
        temporarySourceDataRef.current.isManual = checkIsManualTransferIn(peopleType, isManual);
    };

    const handleMembershipDateCalculateClick = () => {
        setParams();
        const params = {
            memberType: temporarySourceDataRef.current.originalMemberType,
            membershipTerm: temporarySourceDataRef.current.originalMembershipTerm,
            startDate: temporarySourceDataRef.current.originalContractStartDate,
            endDate: temporarySourceDataRef.current.originalContractEndDate,
            nextStartDate: temporarySourceDataRef.current.originalNextMembershipStartDate,
            nextEndDate: temporarySourceDataRef.current.originalNextMembershipEndDate,
            items: [...(temporarySourceDataRef.current.originalSuspendItems || []), ...(temporarySourceDataRef.current.originalGiftItems || [])],
        };
        doMembershipDateCalculate(params);
    };

    const doMembershipDateCalculate = async (params) => {
        const resp = await postTransferInRangeApi(params);
        if (resp) {
            originalMemberShipDateRef.current.setDate(resp.startDate, resp.endDate);
            setErrorMessage(resp.errorMessage);
        }
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        [],
    );

    useLayoutEffect(
        () => {
            setRefreshKey(uuid());
            return () => {
                setParams();
            };
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <React.Fragment key={refreshKey}>
            <FieldGroup label={'方案資訊'}>
                <Grid xs={12} item>
                    {checkIsManualTransferIn(peopleType, isManual) && (
                        <CheckboxField ref={originalIsRenewRef} defaultChecked={[originalIsRenew]}>
                            <CheckboxField.Checkbox value={true} label={'此為續約'} />
                        </CheckboxField>
                    )}
                </Grid>
                <Grid xs={12} item>
                    <CheckboxField
                        ref={originalIsNewContractRef}
                        defaultChecked={[originalIsNewContract]}
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                    >
                        <CheckboxField.Checkbox value={true} label={'此合約為新版入會申請書'} />
                    </CheckboxField>
                </Grid>
                <Grid xs={12} item>
                    <RadioField
                        ref={originalMemberTypeRef}
                        label="會員類型"
                        defaultValue={originalMemberType}
                        required
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                        row
                    >
                        {membershipMemberTypeOptions.map(({ text, value }) => (
                            <RadioField.Radio key={value} value={value} label={text} />
                        ))}
                    </RadioField>
                </Grid>
                <Grid xs={12} item>
                    <RadioField
                        ref={originalPaymentTypeRef}
                        label="付款方式"
                        defaultValue={originalPaymentType}
                        required
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                        row
                    >
                        {paymentTypeOptions.map(({ text, value }) => (
                            <RadioField.Radio key={value} value={value} label={text} />
                        ))}
                    </RadioField>
                </Grid>
                <Grid xs={12} item>
                    <RadioField
                        ref={originalMembershipTermRef}
                        label="合約長度"
                        defaultValue={originalMembershipTerm}
                        required
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                        row
                    >
                        {membershipTermOptions.map(({ text, value }) => (
                            <RadioField.Radio key={value} value={value} label={text} />
                        ))}
                    </RadioField>
                </Grid>
                <Grid xs={12} item>
                    <DateIntervalField
                        ref={originalContractDateRef}
                        startDateProps={{ defaultValue: originalContractStartDate }}
                        endDateProps={{ defaultValue: originalContractEndDate }}
                        label="合約期間"
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label={'贈送期間'}>
                <Grid xs={12} item>
                    <MembershipTransferInDiscountDateItem
                        sourceData={originalGiftItems}
                        ref={originalGiftItemsRef}
                        options={giftOptions}
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label={'暫停期間'}>
                <Grid xs={12} item>
                    <MembershipTransferInSuspendDateItem
                        sourceData={originalSuspendItems}
                        ref={originalSuspendItemsRef}
                        options={suspendOptions}
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label={'會籍期間'}>
                {checkIsManualTransferIn(peopleType, isManual) && (
                    <Grid xs={12} item>
                        <Stack alignItems={'center'} spacing={2}>
                            <Button onClick={handleMembershipDateCalculateClick} variant="contained">
                                計算
                            </Button>
                            <Typography className={'text-error mt-1'} compoment="div">
                                如有新增期間贈送或暫停期間，請再按一次計算
                            </Typography>
                        </Stack>
                    </Grid>
                )}
                <Grid xs={10} item>
                    <MembershipTransferInContactDateItem
                        ref={originalMemberShipDateRef}
                        startDate={originalMemberShipStartDate}
                        endDate={originalMemberShipEndDate}
                        errorMessage={errorMessage}
                    />
                </Grid>
            </FieldGroup>
            {originalNextMembershipStartDate && originalNextMembershipEndDate && (
                <FieldGroup label={'續約會籍期間'}>
                    <Grid xs={10} item>
                        <MembershipTransferInContactDateItem startDate={originalNextMembershipStartDate} endDate={originalNextMembershipEndDate} />
                    </Grid>
                </FieldGroup>
            )}
            <FieldGroup label={'付款資訊'}>
                <Grid xs={4} item>
                    <TextField
                        ref={originalJoinFeeRef}
                        maskType={'MONEY'}
                        defaultValue={originalJoinFee}
                        label={'入會費'}
                        InputProps={{ placeholder: 0 }}
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                        fullWidth
                    />
                </Grid>
                <Grid xs={4} item>
                    <TextField
                        ref={originalMonthlyFeeRef}
                        maskType={'MONEY'}
                        defaultValue={originalMonthlyFee}
                        label={'月費'}
                        InputProps={{ placeholder: 0 }}
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                        fullWidth
                    />
                </Grid>
                <Grid xs={4} item />
                <Grid xs={4} item>
                    <TextField
                        ref={originalDebitDayRef}
                        type={'number'}
                        defaultValue={originalDebitDay}
                        label={'每月扣款日'}
                        InputProps={{ placeholder: 0 }}
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                        fullWidth
                    />
                </Grid>
            </FieldGroup>
            <FieldGroup label={'其他'}>
                <Grid xs={4} item>
                    <DateField
                        ref={originalFADateRef}
                        defaultValue={originalFADate}
                        label={'FA日期'}
                        fullWidth
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                    />
                </Grid>
                <Grid xs={4} item>
                    <DateField
                        ref={originalFWODateRef}
                        defaultValue={originalFWODate}
                        label={'FWO日期'}
                        fullWidth
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                    />
                </Grid>
                <Grid xs={4} item>
                    <TextField
                        ref={originalWOTimesRef}
                        defaultValue={originalWOTimes}
                        label={'WO數'}
                        type={'number'}
                        fullWidth
                        disabled={!checkIsManualTransferIn(peopleType, isManual)}
                    />
                </Grid>
                <Grid xs={8} item>
                    <AutocompleteField
                        key={options}
                        defaultValue={String(originalClubID)}
                        ref={originalClubIDRef}
                        label={'原店舖'}
                        options={clubOptions}
                        optionKeys={['text']}
                        primaryKey={'value'}
                        InputProps={{
                            disabled: !checkIsManualTransferIn(peopleType, isManual),
                        }}
                        fullWidth
                        required
                    />
                    {isManual && (
                        <Typography className={'text-error mt-1'} compoment="div">
                            如果原始店舖是iCoach2.0，要去移入會員辦理喔！
                        </Typography>
                    )}
                </Grid>
            </FieldGroup>
        </React.Fragment>
    );
});

export default MembershipTransferInOrigingMembership;

import React, { useImperativeHandle, useState, useRef, useEffect } from 'react';
import { Grid } from "@mui/material";
import { MenuItem, SelectField } from "@common/components/";
import { find } from "lodash";

const SourceTypeSelectField = React.forwardRef((props, ref) => {
    const {
        options: originOption = [],
        defaultSourceType,
        defaultSourceTag,
        gridSize = 12,
        readOnly = false
    } = props;
    const [visibleSourceTag, setVisibleSourceTag] = useState(false);
    const [originSelectedOption, setOriginSelectedOption] = useState(null);
    const sourceTypeRef = useRef(null);
    const sourceTagRef = useRef(null);

    const handleChangeSourceType = (e) => {
        let selected = find(originOption, { 'value': `${e.target.value}` });

        setOriginSelectedOption(selected);
        setVisibleSourceTag(!!selected?.children);
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => {
                    return sourceTypeRef.current.isError() ||
                        (sourceTagRef && sourceTagRef.current && sourceTagRef.current.isError());
                },
                getResult: () => {
                    return {
                        sourceType: sourceTypeRef.current.getResult(),
                        sourceTag: sourceTagRef && sourceTagRef.current ? sourceTagRef.current.getResult() : null
                    };
                },
            };
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        let selected = find(originOption, { 'value': `${defaultSourceType}` });

        setOriginSelectedOption(selected);
        setVisibleSourceTag(!!selected?.children);
        // eslint-disable-next-line
    }, []);

    return (
        <>
            <Grid item xs={visibleSourceTag ? gridSize / 2 : gridSize}>
                <SelectField key={defaultSourceType}
                             ref={sourceTypeRef}
                             label="來源" defaultValue={defaultSourceType || null} required fullWidth
                             onChange={handleChangeSourceType}
                             readOnly={readOnly}>
                    <MenuItem disabled value="">
                        請選擇
                    </MenuItem>
                    {originOption.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>
            {visibleSourceTag && <Grid item xs={gridSize / 2}>
                <SelectField key={`${originSelectedOption?.value}-${defaultSourceTag}`} ref={sourceTagRef} label={`${originSelectedOption?.text}名稱`}
                             defaultValue={defaultSourceTag || null} required fullWidth
                             readOnly={readOnly}>
                    <MenuItem disabled value="">
                        請選擇
                    </MenuItem>
                    {originSelectedOption?.children && originSelectedOption?.children.map(({ text, value, disabled }) => (
                        <MenuItem value={value} key={value} disabled={disabled}>
                            {text}
                        </MenuItem>
                    ))}
                </SelectField>
            </Grid>}
        </>
    );
});

export default SourceTypeSelectField;
import { useRef, useCallback } from 'react';

// 通用的 useResetFields Hook
const useResetFields = (initialState, setState) => {
    const isClearedRef = useRef(false);

    const resetFieldsExcept = useCallback(() => {
        if (isClearedRef.current) return;

        const fieldsToReset = {};
        Object.keys(initialState).forEach((key) => {
            fieldsToReset[key] = '';
        });

        setState((prev) => ({
            ...prev,
            ...fieldsToReset,
        }));

        isClearedRef.current = true;
    }, [initialState, setState]);

    const resetClearFlag = () => {
        isClearedRef.current = false;
    };

    return {
        resetFieldsExcept,
        resetClearFlag,
    };
};

export default useResetFields;

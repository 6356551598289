import useAjax from '@apis/useAjax';
import { GET_CYCLE_PURCHASE_PRODUCTS_API } from '@apis/useProductApi';
import useShowLoading from '@util/hook/useShowLoading';
import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { isEmpty } from '@util/utils';

export const GET_CYCLE_PURCHASE_OPTIONS_API = `/api/cycle-purchase/options/`;
const GET_CYCLE_PURCHASE_OVERVIEW_OPTIONS_API = `/api/cycle-purchase/overview/options/`;
const POST_CYCLE_PURCHASE_API = `/api/cycle-purchase/`;
const POST_CYCLE_PURCHASE_OVERVIEW_API = `/api/cycle-purchase/overview/`;
export const GET_CYCLE_PURCHASE_MEMBER_ORDER_API = (memberID, cycleOrderID) => `/api/cycle-purchase/${memberID}/${cycleOrderID}/`;
const DELETE_CYCLE_PURCHASE_CYCLEORDERID_EMPLOYEEID_API = (cycleOrderID, employeeID) => `/api/cycle-purchase/${cycleOrderID}/${employeeID}/`;
const POST_CYCLE_PURCHASE_ORDERS_API = (memberID) => `/api/cycle-purchase/${memberID}/orders/`;
const GET_CYCLE_PURCHASE_TEMPORARY_API = `/api/cycle-purchase/temporary/`;
const POST_CYCLE_PURCHASE_TEMPORARY_CYCLEORDERID_API = (cycleOrderID) => `/api/cycle-purchase/temporary/${cycleOrderID}/`;
const DELETE_CYCLE_PURCHASE_TEMPORARY_CYCLEORDERID_API = (cycleOrderID) => `/api/cycle-purchase/temporary/${cycleOrderID}/`;

const useCyclePurchaseApi = () => {
    const Ajax = useAjax();
    const showLoading = useShowLoading();
    const { enqueueSnackbar } = useSnackbar();

    // 取得循環訂單商品清單
    // 取得循環訂單的下拉式選單
    const getInitCyclePurchaseApi = useCallback(
        async () => {
            let result = {};
            showLoading(true);
            const products = await Ajax.get(GET_CYCLE_PURCHASE_PRODUCTS_API);
            const resource = await Ajax.get(GET_CYCLE_PURCHASE_OPTIONS_API);
            if (products) result = Object.assign(result, { products });
            if (resource) result = Object.assign(result, { resource });
            showLoading(false);
            return result;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得循環訂單的下拉式選單
    const getCyclePurchaseOptionsApi = useCallback(
        async () => {
            const resp = await Ajax.get(GET_CYCLE_PURCHASE_OPTIONS_API);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得循環訂單列表
    const postCyclePurchaseOverviewApi = useCallback(
        async (params) => {
            return await Ajax.post(POST_CYCLE_PURCHASE_OVERVIEW_API, params);
        },
        // eslint-disable-next-line
        [],
    );

    const getCyclePurchaseOverviewOptionsApi = useCallback(
        async () => {
            return await Ajax.get(GET_CYCLE_PURCHASE_OVERVIEW_OPTIONS_API);
        },
        // eslint-disable-next-line
        [],
    );

    // 取得循環訂單內容
    const getCyclePurchaseMemberOrderApi = useCallback(
        async (memberID, cycleOrderID = 0) => {
            const resp = await Ajax.get(GET_CYCLE_PURCHASE_MEMBER_ORDER_API(memberID, cycleOrderID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 新增循環訂單
    const postCyclePurchaseOrderApi = useCallback(
        async (parameters) => {
            const resp = await Ajax.post(POST_CYCLE_PURCHASE_API, parameters);
            if (!isEmpty(resp) && resp.isError && typeof resp.result === 'string') return null; // 排除 0002 錯誤
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 刪除訂購單(須為未生效)
    const deleteCyclePurchaseOrderApi = useCallback(
        async (cycleOrderID, employeeID) => {
            const resp = await Ajax.delete(DELETE_CYCLE_PURCHASE_CYCLEORDERID_EMPLOYEEID_API(cycleOrderID, employeeID));
            if (resp) {
                enqueueSnackbar('刪除成功', { variant: 'success' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得收費明細資訊
    const postCyclePurchaseOrdersApi = useCallback(
        async (memberID, parameters) => {
            const resp = await Ajax.post(POST_CYCLE_PURCHASE_ORDERS_API(memberID), parameters);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 該會員是否有暫存資料
    const getTemporaryHasCycleOrderApi = useCallback(
        async (params) => {
            const resp = await Ajax.get(GET_CYCLE_PURCHASE_TEMPORARY_API, params);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 儲存 暫存循環訂單
    const postTemporaryCycleOrderApi = useCallback(
        async (params, cycleOrderID) => {
            const resp = await Ajax.post(POST_CYCLE_PURCHASE_TEMPORARY_CYCLEORDERID_API(cycleOrderID), params);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 刪除 暫存循環訂單
    const deleteTemporaryCycleOrderApi = useCallback(
        async (cycleOrderID) => {
            const resp = await Ajax.delete(DELETE_CYCLE_PURCHASE_TEMPORARY_CYCLEORDERID_API(cycleOrderID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getInitCyclePurchaseApi,
        getCyclePurchaseOptionsApi,
        postCyclePurchaseOverviewApi,
        getCyclePurchaseOverviewOptionsApi,
        getCyclePurchaseMemberOrderApi,
        postCyclePurchaseOrderApi,
        deleteCyclePurchaseOrderApi,
        postCyclePurchaseOrdersApi,
        getTemporaryHasCycleOrderApi,
        postTemporaryCycleOrderApi,
        deleteTemporaryCycleOrderApi,
    };
};

export default useCyclePurchaseApi;

import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { v4 as uuid } from 'uuid';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Plus as PlusIcon, Minus as MinusIcon } from '@common/SvgIcon/';
import { Button, CheckboxField, Stack, TextField, Alert, IconButton } from '@common/components/';
import useInBodyApi from '@apis/useInBodyApi';

const InBodyAgreedNotesDialog = ({ open: propsOpen = false, onClose: propsOnClose, sourceData = [], refresh }) => {
    const { memberID, measureID, list } = sourceData || {};
    const { postAgreedApi } = useInBodyApi();
    const [checkedItems, setCheckedItems] = useState([]); // 原資料項目
    const [editItems, setEditItems] = useState([]); // 編輯項目
    const [isEdit, setEdit] = useState(false);
    const checkedItemsRef = useRef(null);
    const editItemsRef = useRef([]);

    // 抓取當下Checked的值
    const getCheckedData = () => {
        let newItem = [];
        let items = checkedItemsRef.current;
        if (items) {
            items = items.getResult();
            newItem = items.map((cur, index) => {
                const targetItem = checkedItems[index];
                return {
                    ...targetItem,
                    isCompleted: cur.checked,
                };
            });
        }
        return newItem;
    };

    // 抓取當下Edit的值
    const getEditData = () => {
        const items = editItemsRef.current;
        const newItems = items.reduce((acc, cur, index) => {
            let newAcc = [...acc];
            const value = cur && cur.getResult();
            if (value) {
                const targetItem = editItems[index];
                newAcc.push({
                    ...targetItem,
                    taskTitle: value,
                });
            }
            return newAcc;
        }, []);
        return newItems;
    };

    // 進入編輯
    const handleEditClick = () => {
        const source = getCheckedData();
        setCheckedItems(source);
        setEditItems(source);
        setEdit(true);
    };

    // 新增編輯
    const handleAddNoteItem = () => {
        if (editItems.length >= 2) return null;
        setEditItems((old) => {
            const newArr = [...old, { id: uuid(), taskTitle: '', isCompleted: false, measureTaskID: 0 }];
            return newArr;
        });
    };

    // 刪除項目
    const handleRemoveNoteItem = (index) => {
        const newArr = [...editItems];
        newArr.splice(index, 1);
        setEditItems(newArr);
    };

    // 儲存編輯(暫存State)
    const handleSaveEditClick = () => {
        const source = getEditData();
        setCheckedItems(source);
        setEdit(false);
    };

    // 取消編輯
    const handleCancelEditClick = () => {
        setEdit(false);
    };

    // 送出資料儲存
    const handleSendOnClick = () => {
        let params = getCheckedData();
        params = params.map(({ measureTaskID, taskTitle, isCompleted }) => ({
            measureTaskID,
            taskTitle,
            isCompleted,
        }));
        doAgreedNotesApi(params);
    };

    const doAgreedNotesApi = async (params) => {
        const resp = await postAgreedApi(memberID, measureID, params);
        if (resp) {
            propsOnClose && propsOnClose();
            refresh && refresh();
        }
    };

    useEffect(() => {
        setCheckedItems(list);
    }, [list]);

    return (
        <Dialog open={propsOpen} PaperProps={{ className: 'wx-65rem' }} fullWidth>
            <GradientColorHeader onClose={propsOnClose}>約定事項</GradientColorHeader>
            <DialogContent>
                <Stack className="pt-4 px-2" justifyContent="center" alignItems="center" direction="column" spacing={2}>
                    {isEdit ? (
                        <React.Fragment>
                            <div className="mb-1" spacing={2}>
                                <Button startIcon={<PlusIcon />} onClick={handleAddNoteItem} variant="outlined">
                                    新增事項
                                </Button>
                            </div>
                            {!_.isEmpty(editItems) &&
                                editItems.map(({ id, measureTaskID, taskTitle }, index) => {
                                    return (
                                        <Stack spacing={2} key={measureTaskID || id} style={{ alignSelf: 'stretch' }}>
                                            <TextField defaultValue={taskTitle} ref={(el) => (editItemsRef.current[index] = el)} fullWidth />
                                            <IconButton onClick={() => handleRemoveNoteItem(index)} color="error">
                                                <MinusIcon />
                                            </IconButton>
                                        </Stack>
                                    );
                                })}
                        </React.Fragment>
                    ) : (
                        <Stack className="mb-1" direction="column" alignItems="center">
                            {_.isEmpty(checkedItems) ? (
                                <Alert severity="info" variant="filled" color="info" className="mt-3">
                                    尚無預約事項
                                </Alert>
                            ) : (
                                <CheckboxField ref={checkedItemsRef}>
                                    {checkedItems.map(({ id, measureTaskID, taskTitle, isCompleted }) => {
                                        return <CheckboxField.Checkbox key={measureTaskID || id} label={taskTitle} defaultChecked={!!isCompleted} />;
                                    })}
                                </CheckboxField>
                            )}
                        </Stack>
                    )}
                </Stack>
            </DialogContent>
            <DialogActions>
                {isEdit ? (
                    <Stack spacing={2}>
                        <Button variant="outlined" onClick={handleCancelEditClick}>
                            取消編輯
                        </Button>
                        <Button variant="contained" onClick={handleSaveEditClick}>
                            確認
                        </Button>
                    </Stack>
                ) : (
                    <Stack spacing={2}>
                        <Button onClick={handleEditClick} variant="outlined">
                            編輯
                        </Button>
                        <Button variant="contained" onClick={handleSendOnClick}>
                            儲存
                        </Button>
                    </Stack>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default InBodyAgreedNotesDialog;

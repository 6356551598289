import React, { useRef, useLayoutEffect, useState, useImperativeHandle } from 'react';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import MembershipTransferInPaymentTypeItem from './MembershipTransferInPaymentModeItem';
import { FieldRow, FieldGroup } from '@icoach/documents/components/Layout';
import { InvoiceCard } from '@icoach/documents/components/';
import Tablenization from '@icoach/components/Tablenization';
import { Typography, DateIntervalField, DateField } from '@common/components/';
import { initialDate, displayDateText, isAfter } from '@util/moment';
import clsx from 'clsx';
import moment from 'moment';

const shopListRowLabel = [
    {
        cellKey: 'item',
        headerLabel: '項目',
    },
    {
        cellKey: 'date',
        headerLabel: '會籍期間',
    },
    {
        cellKey: 'fee',
        headerLabel: '每期金額',
        align: 'right',
    },
];

// 付款項目
const ShoppingList = ({ rowsData }) => {
    const rowsLabel = useRef(shopListRowLabel);
    return (
        <Tablenization cardProps={{ className: 'shopping-list theme-gray' }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel.current} />
            <Tablenization.TableRowBody headerRow={rowsLabel.current} rowsData={rowsData} />
        </Tablenization>
    );
};

// 扣款授權
const MembershipTransferInPaymentAuthorization = React.forwardRef((props, ref) => {
    const { isReadOnly = false, temporarySourceDataRef, options, errorModule: errorModuleProps } = props;
    const { paymentTypeOptions, invoiceCarrierType: invoiceCarrierTypeOptions, invoiceIssuingType: invoiceIssuingTypeOptions } = options;
    const {
        paymentType,
        debitStartDate,
        invoiceIssuingType,
        invoiceCarrierType,
        invoiceCarrierNo,
        invoiceLoveCode,
        invoiceTitle,
        invoiceTaxID,
        debitFirstDate,
        memberShipStartDate,
        memberShipEndDate,
        monthlyFee,
        // originalDebitDay, // 扣款日寫死 1 後台不會給我
    } = temporarySourceDataRef.current || {};
    const [refreshKey, setRefreshKey] = useState(null);
    const paymentDurationRef = useRef(null);
    const debitFirstDateRef = useRef(null);
    const membershipTransferInPaymentTypeItemRef = useRef(null);
    const invoiceCardRef = useRef(null);

    const getPaymentTypeText = (v) => paymentTypeOptions.find(({ value }) => String(value) === String(v))?.text ?? '';

    const setParams = () => {
        if (isReadOnly) return false;
        membershipTransferInPaymentTypeItemRef.current.getResult();
        const { startDate: s1, endDate: e1 } = paymentDurationRef.current.getResult();
        const targetInvoiceCard = invoiceCardRef.current.getResult();
        temporarySourceDataRef.current.debitStartDate = s1;
        temporarySourceDataRef.current.debitEndDate = e1;
        temporarySourceDataRef.current.debitFirstDate = debitFirstDateRef.current.getResult();
        temporarySourceDataRef.current.invoiceCarrierNo = targetInvoiceCard.invoiceCarrierNo;
        temporarySourceDataRef.current.invoiceCarrierType = targetInvoiceCard.invoiceCarrierType;
        temporarySourceDataRef.current.invoiceIssuingType = targetInvoiceCard.invoiceIssuingType;
        temporarySourceDataRef.current.invoiceLoveCode = targetInvoiceCard.invoiceLoveCode;
        temporarySourceDataRef.current.invoiceTaxID = targetInvoiceCard.invoiceTaxID;
        temporarySourceDataRef.current.invoiceTitle = targetInvoiceCard.invoiceTitle;
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => setParams(),
        }),
        // eslint-disable-next-line
        [],
    );

    useLayoutEffect(
        () => {
            setRefreshKey(uuid());
            return () => setParams();
        },
        // eslint-disable-next-line
        [],
    );

    const shoppingData = {
        item: '月費',
        date: displayDateText(memberShipStartDate, memberShipEndDate),
        fee: monthlyFee,
    };

    const visibleDebit = !(memberShipEndDate && isAfter(debitFirstDate, memberShipEndDate, 'YYYY-MM-DD'));
    const defaultDebitEnd = moment(new Date(memberShipEndDate)).date(1).format('YYYY-MM-DD');

    return (
        <React.Fragment key={refreshKey}>
            <Typography variant="h3" className="h5">
                付款項目
            </Typography>
            <p className="mb-2">
                繳費方式：
                <span className="ml-3 text-primary">{getPaymentTypeText(paymentType) || '尚無參考資料'}</span>
            </p>
            {/* 目前只有一項 會及費用,目前不會有其他商品跟會籍混在一起 */}
            <ShoppingList rowsData={[shoppingData]} />
            <FieldGroup label="扣款期間">
                <Grid item xs={12} className={clsx({ hide: !visibleDebit })}>
                    <DateIntervalField
                        startDateProps={{ defaultValue: debitStartDate }}
                        endDateProps={{ defaultValue: defaultDebitEnd }}
                        ref={paymentDurationRef}
                        readOnly={isReadOnly}
                        required
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} className={clsx({ hide: !visibleDebit })}>
                    <div className="info-box">
                        <p className="m-0">
                            每月
                            <span className="mark">1</span>
                            日扣款
                        </p>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <DateField
                                    label="首次扣款日"
                                    ref={debitFirstDateRef}
                                    defaultValue={initialDate(debitFirstDate)}
                                    readOnly={isReadOnly}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </div>
                </Grid>
                <Grid item xs={12} className={clsx({ hide: visibleDebit })}>
                    <div className="info-box">
                        <p className="m-0">會籍已結束，無需扣款</p>
                    </div>
                </Grid>
            </FieldGroup>
            {/* 信用卡資訊 */}
            <MembershipTransferInPaymentTypeItem
                ref={membershipTransferInPaymentTypeItemRef}
                temporarySourceDataRef={temporarySourceDataRef}
                errorModule={errorModuleProps}
                isReadOnly={isReadOnly}
            />
            <Grid container spacing={3} className="field-group">
                <FieldRow label="發票類型">
                    <InvoiceCard
                        ref={invoiceCardRef}
                        sourceData={{
                            invoiceCarrierType: invoiceCarrierType || 1,
                            invoiceIssuingType,
                            invoiceCarrierNo,
                            invoiceTitle,
                            invoiceTaxID,
                            invoiceLoveCode,
                        }}
                        resource={{ invoiceCarrierTypeOptions, invoiceIssuingTypeOptions }}
                        readOnly={isReadOnly}
                    />
                </FieldRow>
            </Grid>
        </React.Fragment>
    );
});

export default MembershipTransferInPaymentAuthorization;

import React, { useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material';
import ProcessCircleBox from '@icoach/inbody/components/ProcessCircleBox';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, SelectField, MenuItem, TextField, RadioField, Grid, Box } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import useInBodyApi from '@apis/useInBodyApi';
import { referenceTypeMap } from '@icoach/inbody/staticData';
import useGetEmployeeOptionApi from '@util/hook/useGetEmployeeOptionApi';

const InBodyInterviewAddMeasureDialog = ({ open, onClose, sourceData, refresh, dialogRef }, ref) => {
    const { referenceType, peopleID, height, employeeID, gender } = sourceData || {};
    const { postTriggerMeasureApi } = useInBodyApi();
    const { option } = useGetEmployeeOptionApi();
    const [openProcessCircle, setOpenProcessCircle] = useState(false);

    const employeesRef = useRef(null);
    const heightRef = useRef(null);
    const genderRef = useRef(null);

    const handleClose = () => {
        setOpenProcessCircle(false);
        if (typeof onClose === 'function') onClose();
    };

    const getParams = () => {
        let result = {
            referenceID: peopleID,
            employeeID: 0,
            gender: 1,
            referenceType,
        };

        if (employeesRef.current && employeesRef.current.getResult) {
            result.employeeID = employeesRef.current.getResult();
        }

        if (heightRef.current && heightRef.current.getResult) {
            result.height = heightRef.current.getResult();
        }

        if (genderRef.current && genderRef.current.getResult) {
            result.gender = genderRef.current.getResult().find((target) => target.checked)?.value ?? 1;
        }

        return result;
    };

    const handleStartInBodyMeasure = () => {
        if (!refIsRequiredError(employeesRef, heightRef)) {
            const params = getParams();

            // setPrevMeasureParam(params);
            doPrepareInbodyMeasureApi(params);
        }
    };

    const handleOpenImpedanceDialog = () => {
        setOpenProcessCircle(false);
        dialogRef.current && dialogRef.current.openInBodyInterviewImpedanceDialog(null, refresh);
    };

    const doPrepareInbodyMeasureApi = async (params) => {
        const resp = await postTriggerMeasureApi(params);
        if (resp.isError) {
            if (resp.statusCode === 'ME003') {
                dialogRef.current.openInBodyInterviewCancelMeasureDialog(null, refresh);
            }
        } else {
            setOpenProcessCircle(true);
        }
    };

    const successRefreshApi = async () => {
        if (typeof refresh === 'function') refresh();
    };

    useEffect(
        () => {
            return () => handleClose();
        },
        // eslint-disable-next-line
        [],
    );

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => getParams(),
        }),
        // eslint-disable-next-line
        [],
    );

    return (
        <Dialog open={open} PaperProps={{ className: 'wx-40rem' }} fullWidth>
            <GradientColorHeader onClose={handleClose}>新增量身</GradientColorHeader>
            {!openProcessCircle ? (
                <React.Fragment>
                    <DialogContent>
                        <Box className="pt-3 px-3">
                            <Grid container spacing={2}>
                                {referenceType !== referenceTypeMap.employee && (
                                    <Grid item xs={12}>
                                        <SelectField
                                            key={employeeID}
                                            defaultValue={employeeID}
                                            direction={'row'}
                                            label="擔當"
                                            displayEmpty
                                            required
                                            ref={employeesRef}
                                            fullWidth
                                        >
                                            <MenuItem disabled value="">
                                                <em>請選擇</em>
                                            </MenuItem>
                                            {option.map(({ value, text, disabled }) => (
                                                <MenuItem key={value} value={value} disabled={disabled}>
                                                    {text}
                                                </MenuItem>
                                            ))}
                                        </SelectField>
                                    </Grid>
                                )}
                                <Grid item xs={12}>
                                    <TextField
                                        key={height}
                                        defaultValue={height}
                                        direction={'row'}
                                        ref={heightRef}
                                        label="身高"
                                        type={'number'}
                                        fullWidth
                                        required
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <RadioField key={gender} defaultValue={gender || 1} ref={genderRef} label="性別" labelDirection="row" required row>
                                        <RadioField.Radio value={1} label={'女'} />
                                        <RadioField.Radio value={2} label={'男'} />
                                    </RadioField>
                                </Grid>
                            </Grid>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleStartInBodyMeasure}>開始量身</Button>
                    </DialogActions>
                </React.Fragment>
            ) : (
                <DialogContent>
                    <ProcessCircleBox
                        className="pt-3 px-3"
                        peopleID={peopleID}
                        referenceType={referenceType}
                        refresh={successRefreshApi}
                        onClose={handleClose}
                        onOpenImpedanceDialog={handleOpenImpedanceDialog}
                    />
                </DialogContent>
            )}
        </Dialog>
    );
};

export default forwardRef(InBodyInterviewAddMeasureDialog);

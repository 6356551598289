import React from 'react';
import clsx from 'clsx';
import { isEmpty } from 'lodash';
import Tablenization from '@icoach/components/Tablenization';
import { TableLabel } from '@icoach/dataAnalysis/components/';
import { transposedWeeklyData } from '@icoach/dataAnalysis/staticData';
import { floatToPercentage, toFixed } from '@util/utils';

const getRowsData = (data, cumulative, thData) => {
    let rowsData = [];
    let rowsLabel = [
        {
            cellKey: 'title',
            headerLabel: '項目',
            width: 26,
            formatCell: (title, { tip, isStar }) => {
                return <TableLabel title={title} tip={tip} isStar={isStar} />;
            },
        },
        {
            cellKey: 'goal',
            headerLabel: '目標',
            align: 'center',
            width: 11,
        },
        {
            cellKey: 'cumulative',
            headerLabel: '最新數字',
            align: 'center',
            className: 'total-col',
            width: 9,
        },
    ];

    data = transposedWeeklyData(data);

    if (!isEmpty(data)) {
        rowsData = thData.map(({ key, formatCell, ...other }) => {
            let weeklyData = data[key];
            let rowData = {
                cellKey: '',
                cumulative: cumulative[key],
                ...other,
                ...weeklyData,
            };

            if (formatCell) {
                rowData.cellKey = 'cumulative';
                rowData.cumulative = formatCell(cumulative[key], rowData);
                for (let week in weeklyData) {
                    rowData.cellKey = week;
                    rowData[week] = formatCell(weeklyData[week], rowData);
                }
            }

            return rowData;
        });

        rowsLabel = [...rowsLabel, ...data.week];
    }

    return { rowsLabel, rowsData };
};

// 表格標題與目標資訊
// title: 標題 string
// tip: 說明 string
// isStar: 閃亮之星 boolean 選填
// goal: 目標 string
const thData = [
    {
        key: 'totalMembership',
        title: '在籍會員數 TM',
        tip: '依據上月在籍會員數與每周會員數變化自動調整',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'totalActiveMembership',
        title: '有效會員數 TAM',
        tip: '在籍會員數 - 請假會員數',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'newSales',
        title: '新銷售',
        tip: '每週新入會總數，依「系統送出日」判別',
        goal: '',
        formatCell: (val) => {
            return parseInt(val, 10) || 0;
        },
    },
    {
        key: 'expiredRenew',
        title: '補續約',
        tip: '會籍已經無效後辦理續約',
        goal: '',
        formatCell: (val) => {
            return parseInt(val, 10) || 0;
        },
    },
    {
        key: 'moveOut',
        title: '移出為負',
        tip: '移籍與會籍轉讓皆須紀錄在此',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'moveIn',
        title: '移入為正',
        tip: '移籍與會籍轉讓皆須紀錄在此',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'leave',
        title: '請假會員數',
        tip: '請假會員總數',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'notYetRenew',
        title: '未續約',
        tip: '會籍到期，但未填寫退會申請書的數量',
        goal: '',
        formatCell: (val) => {
            return parseInt(val, 10) || 0;
        },
    },
    {
        key: 'drop',
        title: '退會數',
        tip: '當週退會人數',
        goal: '',
        formatCell: (val) => {
            return toFixed(val);
        },
    },
    {
        key: 'leaveRate',
        title: '會員請假率',
        tip: '請假會員數 / TM',
        goal: '≤3%',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
    {
        key: 'dropRate',
        title: '退會率',
        tip: '退會數/TM',
        goal: '≤2%',
        isStar: true,
        formatCell: (val) => {
            return `${floatToPercentage(val)}%`;
        },
    },
];

const MembershipMonthlyTable = (props) => {
    const { className, rowsData: data = [], cumulative } = props;
    const { rowsLabel, rowsData } = getRowsData(data, cumulative, thData);

    return (
        <Tablenization cardProps={{ className: clsx('monthly-table', className) }}>
            <Tablenization.TableRowHeader headerRow={rowsLabel} />
            <Tablenization.TableRowBody headerRow={rowsLabel} rowsData={rowsData} />
        </Tablenization>
    );
};

export default MembershipMonthlyTable;

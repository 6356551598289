import React, { useState, useRef, useImperativeHandle } from 'react';
import { Grid } from '@mui/material';
import _ from 'lodash';
import { SelectField, MenuItem, DateField } from '../../../components/';
import { refIsRequiredError } from '../../../util/utils';
import { initialDate } from '../../../util/moment';

/**
 * 在職 = 1
 * 離職 = 2
 * 留職停薪 = 3
 * 全部 = 99
 */

const EmployeesInformationsJobSituationItem = React.forwardRef((props, ref) => {
    const { options = [], sourceData } = props;
    const { employeeStatus: employeeStatusProps, resignDate } = sourceData;
    const [employeeStatus, setEmployeeStatusProps] = useState(employeeStatusProps);
    const employeeStatusRef = useRef(null);
    const resignationDateRef = useRef(null);
    const isResignation = String(employeeStatus) === '2';
    if (_.isEmpty(options)) return null;

    const handleEmployeeStatusChange = (dom, p, value) => {
        setEmployeeStatusProps(value);
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => refIsRequiredError(employeeStatusRef, resignationDateRef),
            getResult: () => {
                let targetData = {
                    employeeStatus: employeeStatus,
                };
                if (isResignation) {
                    targetData.resignDate = resignationDateRef.current.getResult();
                }
                return targetData;
            },
        }),
        // eslint-disable-next-line
        [employeeStatus]
    );

    return (
        <Grid container spacing={3}>
            <Grid xs={6} item>
                <SelectField ref={employeeStatusRef} label="狀態" value={employeeStatus} onChange={handleEmployeeStatusChange} fullWidth required>
                    {options
                        .filter((target) => String(target.value) !== '99')
                        .map(({ text, value, disabled }) => (
                            <MenuItem key={value} value={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                </SelectField>
            </Grid>
            {isResignation ? (
                <Grid xs={6} item>
                    <DateField ref={resignationDateRef} defaultValue={initialDate(resignDate)} label="離職日期" fullWidth required />
                </Grid>
            ) : null}
        </Grid>
    );
});

export default EmployeesInformationsJobSituationItem;

import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Alert, TextField } from '@common/components/';
import { formatCurrencyFn, getErrorModuleStatus, refIsRequiredError } from '@util/utils';
import clsx from 'clsx';
import { PaidList } from '@icoach/documents/components';
import { PaidListNew } from '@icoach/documents/components/PaidList';

// 暫收款輸入欄位 *暫時不開發這一塊
// const WalletField = React.forwardRef((props, ref) => {
//     const { hasWallet = false } = props;
//     const walletRef = useRef();
//
//     useImperativeHandle(
//         ref,
//         () => ({
//             getResult: () => {
//                 let result = {};
//                 if (walletRef.current) result.walletPayment = walletRef.current.getResult();
//                 return result;
//             },
//             isError: () => refIsRequiredError(walletRef),
//         }),
//         []
//     );
//
//     if (!hasWallet) return null;
//     return (
//         <React.Fragment>
//             <li className={'paid-list-item bg-info-light py-2'}>
//                 <span className={'paid-list-cell'}>
//                     <InfoCircleIcon htmlColor={'#181c32'} className={'mr-1'} />
//                     暫收款
//                     <span className={'text-info ml-1'}>{formatCurrencyFn(1000)}</span>：
//                 </span>
//                 <TextField type={'number'} ref={walletRef} InputProps={{ placeholder: 0 }} />
//             </li>
//         </React.Fragment>
//     );
// });

const CheckoutPaidListBox = React.forwardRef((props, ref) => {
    const { className, receivableAmount = 0, paidPrice = 0, errorModule = {} } = props;
    const [paymentInfo, setPaymentInfo] = useState({
        paymentAmount: 0,
        balanceAmount: receivableAmount,
    });
    const cashRef = useRef();
    const cardRef = useRef();
    const walletRef = useRef();

    const calculateBalance = () => {
        let cash = parseInt(cashRef.current.getResult()) || 0;
        let card = parseInt(cardRef.current.getResult()) || 0;
        let balanceAmount = 0;
        let paymentAmount = 0;
        if (typeof paidPrice === 'number') {
            paymentAmount += paidPrice;
        }
        paymentAmount += cash;
        paymentAmount += card;
        if (typeof receivableAmount === 'number') {
            balanceAmount = receivableAmount - paymentAmount;
        }
        setPaymentInfo({ paymentAmount, balanceAmount });
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => ({
                cashPayment: cashRef.current && cashRef.current.getResult(),
                cardPayment: cashRef.current && cardRef.current.getResult(),
                walletPayment: 0, // 暫時不開發，但需要給後端對應的欄位為零
            }),
            isError: () => {
                let isError = refIsRequiredError(cashRef, cardRef, walletRef);
                if (!isError) {
                    isError = paymentInfo.balanceAmount < 0;
                }
                return isError;
            },
        }),
        // eslint-disable-next-line
        [paymentInfo],
    );

    useEffect(() => {
        calculateBalance();
        // eslint-disable-next-line
    }, [paidPrice, receivableAmount]);
    return (
        <PaidListNew className={clsx('checkout-box', className)}>
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label={'應收金額'}>
                    <strong>{formatCurrencyFn(receivableAmount)}</strong>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label={'收款金額'}>
                    <strong>{formatCurrencyFn(paymentInfo.paymentAmount)}</strong>
                </PaidList.PaidListItem>
                <PaidList.PaidListItem
                    className={clsx('paid-list-item ', {
                        'text-error': paymentInfo.balanceAmount !== 0,
                    })}
                    label={'未收金額'}
                >
                    <strong>{formatCurrencyFn(paymentInfo.balanceAmount)}</strong>
                </PaidList.PaidListItem>
                {paymentInfo.balanceAmount < 0 && (
                    <Alert severity="error" variant="filled" color="error" className="my-3">
                        收款金額已超過應收金額，無法進行結帳。
                    </Alert>
                )}
            </PaidList.PaidListGroup>
            <PaidList.PaidListGroup>
                <PaidList.PaidListItem label="現金收款金額">
                    <TextField
                        maskType={'MONEY'}
                        ref={cashRef}
                        onChangeNative={calculateBalance}
                        inputProps={{ placeholder: '請輸入金額', min: 0, required: true }}
                        {...getErrorModuleStatus(errorModule, 'cashPayment')}
                    />
                </PaidList.PaidListItem>
                <PaidList.PaidListItem label="信用卡收款金額">
                    <TextField
                        maskType={'MONEY'}
                        ref={cardRef}
                        onChangeNative={calculateBalance}
                        inputProps={{ placeholder: '請輸入金額', min: 0, required: true }}
                        {...getErrorModuleStatus(errorModule, 'cardPayment')}
                    />
                </PaidList.PaidListItem>
            </PaidList.PaidListGroup>
        </PaidListNew>
    );
});

export default CheckoutPaidListBox;

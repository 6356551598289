import React, { useEffect, useRef, useState } from 'react';
import { Dialog, DialogContent } from '@mui/material';
import { MemberInfoBox } from '../../components/BusinessCardBox';
import MemberAvatar from '../../components/MemberAvatar';
import TakePhotoBox from '../../components/TakePhotoBox';
import GradientColorHeader from '../../components/dialog/GradientColorHeader';
import { Button, Stack } from '../../../components';
import useMemberApi from '../../../apis/useMemberApi';
import { useMemberProfileApi } from '../../../apis/useMemberProfileApi';

const MembershipFormAvatarDialog = React.forwardRef((props, ref) => {
    const { open = false, onClose: onCloseProps, memberID } = props;
    const { postMemberAvatarApi } = useMemberApi();
    const { getMemberProfileTipApi } = useMemberProfileApi();
    const [sourceData, setSourceData] = useState({});
    const takePhotoBoxRef = useRef(null);

    const handleOnClose = () => {
        onCloseProps && onCloseProps(false);
    };

    const handleAvatarEditOpen = () => {
        takePhotoBoxRef.current.open();
    };

    const handleFileChange = (files) => {
        const formData = new FormData();
        formData.append('file', files.file);
        uploadAvatarApi(memberID, formData, files);
    };

    const uploadAvatarApi = async (memberID, params, file) => {
        const resp = await postMemberAvatarApi(memberID, params);
        if (resp) {
            // console.log(file);
            setSourceData((prev) => {
                return { ...prev, picturePath: file.blobSrc };
            });
        }
    };

    const getMemberBaseInfoApi = async () => {
        const resp = await getMemberProfileTipApi(memberID);
        if (resp) {
            setSourceData(resp);
        }
    };

    useEffect(
        () => {
            if (open) getMemberBaseInfoApi();
            return setSourceData(false);
        },
        // eslint-disable-next-line
        [open],
    );

    const { picturePath, memberName, memberNo, membershipStatus, nickName } = sourceData;
    return (
        <Dialog open={open}>
            <GradientColorHeader onClose={handleOnClose}>會員資料</GradientColorHeader>
            <DialogContent key={sourceData} className="mx-5">
                <Stack spacing="3">
                    <div className="text-center">
                        <MemberAvatar src={picturePath || ''} alt={memberName} width="14" membershipStatus={membershipStatus} />
                        <Button className="mt-2" onClick={handleAvatarEditOpen}>
                            編輯頭像
                        </Button>
                        <TakePhotoBox onFileChange={handleFileChange} ref={takePhotoBoxRef} />
                    </div>
                    <MemberInfoBox className="mt-5" sourceData={{ memberName, memberNo, nickName }} />
                </Stack>
            </DialogContent>
        </Dialog>
    );
});

export default MembershipFormAvatarDialog;

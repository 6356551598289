import { useState, useCallback } from 'react';

// 0002
const useValidationErrors = () => {
    const [validationErrors, setValidationErrors] = useState({});

    const parseValidationErrors = useCallback((array) => {
        const result = {};
        if (Array.isArray(array)) {
            array.forEach((item) => {
                // 去掉方括號和其中的數字部分
                const cleanedKey = item.key.replace(/\[(\d+)\]/g, '$1');

                // 將處理過的 key 使用 '.' 分割
                const keys = cleanedKey.split('.');
                let obj = result;

                keys.forEach((key, index) => {
                    if (index === keys.length - 1) {
                        obj[key] = item.message;
                    } else {
                        obj[key] = obj[key] || {};
                        obj = obj[key];
                    }
                });
            });
        }
        return result;
    }, []);

    const updateValidationErrors = useCallback(
        (errorsArray) => {
            const formattedErrors = parseValidationErrors(errorsArray);
            setValidationErrors(formattedErrors);
        },
        [parseValidationErrors],
    );

    const clearValidationErrors = useCallback(() => {
        setValidationErrors({});
    }, []);

    return {
        validationErrors,
        updateValidationErrors,
        clearValidationErrors,
    };
};

export default useValidationErrors;

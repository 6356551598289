import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Switch, useLocation, useHistory } from 'react-router-dom';
import routerMap from '@icoach/router/routers';
import { PrivateRoute, Box } from '@common/components/';
import { flatDeepArray } from '@util/utils';
import { NotFoundPage, NotFoundPDFPage } from '@error/';
import { getMenusData } from '@redux/menus/selector';
import { localUser, isEmpty } from '@util/utils';

const EmptyComponent = () => <></>;

const checkRootPath = (pathname) => pathname === '/club' || pathname === '/club/';

const Main = (props) => {
    const [isMounted, setMounted] = useState(false);
    const menuData = useSelector(getMenusData);
    const { path: rootPath, className: classNameProps } = props;
    let { pathname } = useLocation();
    let history = useHistory();

    const devPrivateRoute = useMemo(
        () => {
            const { hostname } = window.location;
            if (hostname === 'localhost' || /192.168.0/.test(hostname)) {
                const { developerRouter } = require('@icoach/router/DevelopmentRouter');
                const devRouters = flatDeepArray([developerRouter]);
                return devRouters.reduce((acc, { path: path__, as: as__, ...others }) => {
                    let sourceAry = [...acc];
                    if (path__) {
                        sourceAry.push(<PrivateRoute key={path__} path={path__} as={as__} {...others} />);
                    }
                    return sourceAry;
                }, []);
            } else {
                return null;
            }
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (!isEmpty(menuData) && !isEmpty(menuData.oriMenuData)) {
                setMounted(true);
            }
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [menuData],
    );

    useEffect(
        () => {
            const { clubSystemType = '' } = localUser.get() || {};
            if (checkRootPath(pathname) && clubSystemType) {
                String(clubSystemType) === '3' ? history.push(`${rootPath}/home/arrive/`) : history.push(`${rootPath}/points/points-redeem/overview/`);
            }
        },
        // eslint-disable-next-line
        [pathname],
    );

    return (
        <Box className={classNameProps}>
            {isMounted && (
                <Switch>
                    {routerMap['club'].map(({ frontKey, path: pathRouter, exact, as: Component, ...others }) => {
                        if (!pathRouter) return null;
                        const defaultProperty = {
                            path: pathRouter,
                            exact,
                            as: Component,
                            ...others,
                        };
                        let isExist = menuData.menulist.find(({ frontKey: _key }) => frontKey === _key);

                        if (/^CXX99[\d]+$/g.test(frontKey) && window.location.hostname === 'localhost') {
                            isExist = {}; //專門給正在開發且測試的頁面使用
                        }
                        if ('CXX99901' === frontKey) {
                            isExist = {};
                        }

                        return isExist ? <PrivateRoute key={frontKey} {...defaultProperty} isAuth={isExist.isAuth} /> : null;
                    })}
                    {devPrivateRoute}
                    {/*   Todo   */}
                    <PrivateRoute path={`${rootPath}/404`} as={NotFoundPage} />
                    <PrivateRoute path={`${rootPath}/pdf-notfound`} as={NotFoundPDFPage} />
                    <PrivateRoute path="*" as={checkRootPath(pathname) ? EmptyComponent : NotFoundPage} />
                </Switch>
            )}
        </Box>
    );
};

export default Main;

import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, Card, Form, MenuItem, SelectField, TextField } from '@common/components/';
import { Grid } from '@mui/material';
import { isEmpty, objectToQueryString, refIsRequiredError, paramsToObject } from '@util/utils';
import useCyclePurchaseApi from '@apis/useCyclePurchaseApi';
import { useCyclePurchaseContext } from '@icoach/documents/cyclePurchase/CyclePurchaseContext';
import { DOCUMENT_CYCLE_PURCHASE_ROUTER } from '@icoach/router/routerPath';

const numberParamKeys = ['pageIndex', 'pageSize', 'sortBy'];

const CyclePurchaseOverviewSearchBar = React.memo((props) => {
    const { className } = props;
    const history = useHistory();
    const { postCyclePurchaseOverviewApi, getCyclePurchaseOverviewOptionsApi } = useCyclePurchaseApi();
    const { setTableData, bookmark, setBookmarkNum, setRefresh, options, setOptions } = useCyclePurchaseContext();
    const [paramsData, setParamsData] = useState({});
    const {
        shippingDay = '99',
        cycleOrderType = '1',
        cycleOrderNo = '',
        productName = '',
        memberSearch = '',
        employeeID = '0'
    } = paramsData || {};
    const {
        shippingDayOptions = [],
        cycleOrderTypeOptions = [],
        coachOptions: employeeOptions = []
    } = options || {};
    const memberSearchRef = useRef(null);
    const shippingDayRef = useRef(null);
    const cycleOrderTypeRef = useRef(null);
    const cycleOrderNoRef = useRef(null);
    const productNameRef = useRef(null);
    const employeeRef = useRef(null);
    const preloadRef = useRef(false);

    const parseQueryStringToParams = () => {
        const { location } = history;
        return paramsToObject(location.search, numberParamKeys);
    }

    const updateUrlWithSearchQuery = (params) => {
        let url = DOCUMENT_CYCLE_PURCHASE_ROUTER;
        if (!isEmpty(params)) url += objectToQueryString(params);
        history.replace(url);
    };

    const getOverviewApi = async (params) => {
        let resp = await postCyclePurchaseOverviewApi(params);
        if (resp) {
            const { list, pageIndex } = resp;
            if (String(pageIndex) === '1') {
                setTableData(resp);
            } else {
                setTableData((prev) => {
                    const prevList = prev.list;
                    let targetList = list;

                    if (Array.isArray(prevList)) {
                        targetList = prevList.concat(targetList);
                    }

                    return {
                        ...resp,
                        list: targetList,
                    };
                });
            }
        }

        updateUrlWithSearchQuery(params);
        preloadRef.current = true;
    };

    const getParams = (pageInfo) => {
        let result = {};

        if (pageInfo) Object.assign(result, pageInfo);

        if (memberSearchRef.current && memberSearchRef.current.getResult) {
            let value = memberSearchRef.current.getResult();
            value = value.trim();
            Object.assign(result, { memberSearch: value });
        }

        if (shippingDayRef.current && shippingDayRef.current.getResult) {
            Object.assign(result, { shippingDay: shippingDayRef.current.getResult() });
        }

        if (cycleOrderTypeRef.current && cycleOrderTypeRef.current.getResult) {
            Object.assign(result, { cycleOrderType: cycleOrderTypeRef.current.getResult() });
        }

        if (cycleOrderNoRef.current && cycleOrderNoRef.current.getResult) {
            let value = cycleOrderNoRef.current.getResult();
            value = value.trim();
            Object.assign(result, { cycleOrderNo: value });
        }

        if (productNameRef.current && productNameRef.current.getResult) {
            let value = productNameRef.current.getResult();
            value = value.trim();
            Object.assign(result, { productName: value });
        }

        if (employeeRef.current && employeeRef.current.getResult) {
            Object.assign(result, { employeeID: employeeRef.current.getResult() });
        }

        return result;
    };

    const getOptionsApi = async () => {
        let resp = await getCyclePurchaseOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
            let params = parseQueryStringToParams();
            if (!isEmpty(params)) {
                setParamsData({ ...params, ...{ pageIndex: 1 } });
                setBookmarkNum(1);
            } else {
                params = getParams(bookmark);
            }

            preloadRef.current = false;
            getOverviewApi(params);
        }
    };

    const handleSubmit = (pageObject = { pageIndex: null }) => {
        let isError = refIsRequiredError(memberSearchRef, shippingDayRef, cycleOrderTypeRef, cycleOrderNoRef, productNameRef, employeeRef);
        if (!isError) {
            setBookmarkNum(1);
        }
    };

    useEffect(
        () => {
            if (!preloadRef.current) return null;
            preloadRef.current = false;
            const params = getParams(bookmark);
            getOverviewApi(params);
        },
        // eslint-disable-next-line
        [bookmark],
    );

    useEffect(
        () => {
            setRefresh(getOverviewApi);
            getOptionsApi();
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <Card className={className}>
            <Form onSubmit={handleSubmit}>
                <Card.CardContent className={'px-4 pt-2 pb-2'}>
                    <Grid spacing={2} container>
                        <Grid xs={4} item>
                            <TextField
                                key={memberSearch}
                                ref={memberSearchRef}
                                label={'會員'}
                                defaultValue={memberSearch}
                                InputProps={{ placeholder: '請輸入會員姓名或會員編號或手機' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField key={shippingDay} ref={shippingDayRef} label={'出貨週期'} defaultValue={shippingDay} fullWidth>
                                {Array.isArray(shippingDayOptions) &&
                                    shippingDayOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField key={cycleOrderType} ref={cycleOrderTypeRef} label={'訂單狀態'} defaultValue={cycleOrderType} fullWidth>
                                {Array.isArray(cycleOrderTypeOptions) &&
                                    cycleOrderTypeOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                        <Grid xs={4} item>
                            <TextField
                                key={cycleOrderNo}
                                ref={cycleOrderNoRef}
                                label={'訂單編號'}
                                defaultValue={cycleOrderNo}
                                InputProps={{ placeholder: '請輸入訂單編號' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <TextField
                                key={productName}
                                ref={productNameRef}
                                label={'商品名稱'}
                                defaultValue={productName}
                                InputProps={{ placeholder: '請輸入商品名稱' }}
                                fullWidth
                            />
                        </Grid>
                        <Grid xs={4} item>
                            <SelectField key={employeeID} ref={employeeRef} label={'擔當'} defaultValue={employeeID} fullWidth displayEmpty>
                                {Array.isArray(employeeOptions) &&
                                    employeeOptions
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                        <Grid className={'text-right'} xs={12} item>
                            <Button type={'submit'} variant={'contained'}>
                                搜尋
                            </Button>
                        </Grid>
                    </Grid>
                </Card.CardContent>
            </Form>
        </Card>
    );
});

export default CyclePurchaseOverviewSearchBar;

import React, { useMemo } from 'react';
import clsx from 'clsx';
import { Box, Typography } from '@common/components/';
import Tablenization from '@icoach/components/Tablenization';
import { BusinessCardBox } from '@icoach/components/';
import { formatCurrencyFn } from '@util/utils';

const rowsLabel = [
    {
        cellKey: 'memberSheet',
        headerLabel: '會員',
        align: 'left',
        maxWidth: 20.4,
        formatCell: (cellValue, { memberNo, memberName, memberPicturePath }) => {
            let memberInfo = {
                memberNo,
                memberName,
                memberImg: memberPicturePath,
            };
            return <BusinessCardBox sourceData={memberInfo} />;
        },
    },
    {
        cellKey: 'itemName',
        headerLabel: '品項',
        align: 'center',
    },
    {
        cellKey: 'transactionPrice',
        headerLabel: '交易金額',
        align: 'center',
        formatCell: (cellValue) => {
            return formatCurrencyFn(cellValue);
        },
    },
    {
        cellKey: 'creditCardNo',
        headerLabel: '信用卡',
        maxWidth: 21,
        formatCell: (cellValue, { cardExpiryDate }) => {
            return (
                <Box className="payment-card-content">
                    <Typography className={'title'} variant="body1" component={'h6'}>
                        {cellValue}
                    </Typography>
                    {cardExpiryDate ? (
                        <Typography className={'sub-title font-color-3'} variant="body2">
                            {`有效期限：${cardExpiryDate}`}
                        </Typography>
                    ) : (
                        ''
                    )}
                </Box>
            );
        },
    },
    {
        cellKey: 'note',
        headerLabel: '備註',
        maxWidth: 30,
    },
];

const BillingPendingPaymentByCreditCardTable = (props) => {
    const { className, rowsData = [] } = props;
    const labelTitle = useMemo(() => {
        return rowsLabel;
    }, []);

    return (
        <React.Fragment>
            <Tablenization cardProps={{ className: clsx(className, 'bulk-debit-table') }}>
                <Tablenization.TableRowHeader headerRow={labelTitle} />
                <Tablenization.TableRowBody headerRow={labelTitle} rowsData={rowsData} />
            </Tablenization>
        </React.Fragment>
    );
};

export default BillingPendingPaymentByCreditCardTable;

import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { getVersionData } from '@redux/version/selector';
import useShowPDF from '@util/hook/useShowPDF';
import { objectToQueryString } from '@util/utils';

const GET_DOCUMENT_CARD_AUTH_PDF_PATH_API = `/document/card-auth.pdf`;
const GET_DOCUMENT_LEADERBOARD_POSTER_API = `/document/leaderboard/poster/`;
const GET_DOCUMENT_TRANSCRIPT_API = `/document/leaderboard/transcript/`;
const GET_DOCUMENT_MEASURE_SPORT_ADVISE_API = (key) => `/document/${key}/measure/sport-advise/`;
const GET_DOCUMENT_MULTIPLE_MEASURE_INFORMATION_API = (key) => `/document/${key}/measure/multiple-information/`;

const useDocumentApi = () => {
    const showPDF = useShowPDF();
    const reduxVersion = useSelector(getVersionData);

    // 信用卡申請書
    const getDocumentCardAuthPDFDownloadApi = async () => {
        showPDF({
            open: true,
            pdfUrl: GET_DOCUMENT_CARD_AUTH_PDF_PATH_API,
        });
    };

    const getLeaderboardPDFApi = useCallback(
        async (params) => {
            const path = `${GET_DOCUMENT_LEADERBOARD_POSTER_API}?${params}`;
            showPDF({
                open: true,
                htmlUrl: path,
                pdfUrl: path,
            });
        },
        // eslint-disable-next-line
        []
    );
    // 成績單
    const getTranscriptPDFApi = useCallback(
        async (params, key, memberID, isShowQrcode) => {
            let path = `${GET_DOCUMENT_TRANSCRIPT_API}${params}`;
            const { env } = reduxVersion;
            let pdfParams;
            if (env.toUpperCase() === 'PRODUCTION') {
                pdfParams = {
                    open: true,
                    htmlUrl: path,
                    pdfUrl: path,
                    canDownload: false,
                    canPrint: false,
                    canQrCode: isShowQrcode,
                    sourceData: { memberID },
                };
            } else {
                path = `${GET_DOCUMENT_MULTIPLE_MEASURE_INFORMATION_API(key)}${params}`;
                pdfParams = {
                    open: true,
                    htmlUrl: path,
                    pdfUrl: path,
                    canDownload: false,
                    canPrint: false,
                    canQrCode: isShowQrcode,
                    sourceData: { memberID },
                };
            }
            showPDF(pdfParams);
        },
        // eslint-disable-next-line
        []
    );

    const getSportAdvisePDFApi = useCallback(
        async (measureID, healthDeclarationContractID) => {
            const path = `${GET_DOCUMENT_MEASURE_SPORT_ADVISE_API(measureID)}${objectToQueryString(healthDeclarationContractID)}`;
            showPDF({
                open: true,
                htmlUrl: path,
                pdfUrl: path,
                canDownload: false,
                canPrint: false,
            });
        },
        // eslint-disable-next-line
        []
    );

    // 成績單與套表混合頁面
    const getMutipleMeasureInformationApi = useCallback(
        async (params, key, memberID) => {
            const path = `${GET_DOCUMENT_MULTIPLE_MEASURE_INFORMATION_API(key)}${params}`;
            showPDF({
                open: true,
                htmlUrl: path,
                pdfUrl: path,
                canDownload: false,
                canPrint: false,
                canQrCode: true,
                sourceData: { memberID },
            });
        },
        // eslint-disable-next-line
        []
    );

    return {
        getDocumentCardAuthPDFDownloadApi,
        getLeaderboardPDFApi,
        getSportAdvisePDFApi,
        getTranscriptPDFApi,
        getMutipleMeasureInformationApi,
    };
};

export default useDocumentApi;

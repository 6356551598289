import React, { useImperativeHandle, useRef, useState } from 'react';
import useShowPDF from '@util/hook/useShowPDF';
import InbodyXFactorDialog from '@icoach/inbody/components/InBodyXFactorDialog';
import InbodyAgreedNotesDialog from '@icoach/inbody/components/InBodyAgreedNotesDialog';
import InbodyDeleteMeasureDialog from '@icoach/inbody/components/InBodyDeleteMeasureDialog';
import InbodyInterviewNoteDialog from '@icoach/inbody/components/InBodyInterviewNoteDialog';
import MemberSiteQrCodeDialog from '@icoach/inbody/components/MemberSiteQrCodeDialog';

const X_FACTOR_DIALOG = 'xFactor';
const AGREED_NOTES_DIALOG = 'agreedNotes';
const DELETE_MEASURE_DIALOG = 'deleteMeasure';
const INTERVIEW_NOTES_DIALOG = 'interviewNotes';
const MEMBER_SITE_QR_CODE_DIALOG = 'memberSiteQrCode';

// table 重複元件使用(單一來源彈跳視窗)，如果只有一個元件在使用就不用寫在這
const InBodyInterviewDialogBox = React.forwardRef((props, ref) => {
    const showPDF = useShowPDF();
    const [openDialog, setOpenDialog] = useState(null);
    const [sourceData, setSourceData] = useState([]);
    const refreshRef = useRef(null);

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSourceData([]);
    };

    const doRefresh = (...rest) => {
        if (refreshRef.current && typeof refreshRef.current === 'function') {
            setTimeout(() => refreshRef.current(...rest), 5);
        }
    };

    useImperativeHandle(
        ref,
        () => {
            return {
                openXFactorDialog: (targetData, refreshFn) => {
                    setSourceData(targetData);
                    setOpenDialog(X_FACTOR_DIALOG);
                    refreshRef.current = refreshFn;
                },
                openAgreedNotesDialog: (targetData, refreshFn) => {
                    setSourceData(targetData);
                    setOpenDialog(AGREED_NOTES_DIALOG);
                    refreshRef.current = refreshFn;
                },
                openDeleteMeasureDialog: (targetData, refreshFn) => {
                    setSourceData(targetData);
                    setOpenDialog(DELETE_MEASURE_DIALOG);
                    refreshRef.current = refreshFn;
                },
                openInterviewNotesDialog: (targetData, refreshFn) => {
                    setSourceData(targetData);
                    setOpenDialog(INTERVIEW_NOTES_DIALOG);
                    refreshRef.current = refreshFn;
                },
                openViewPdfDialog: (pdfUrl) => {
                    showPDF({
                        open: true,
                        pdfUrl: pdfUrl,
                    });
                },
                openMemberSiteQrCodeDialog: (targetData) => {
                    setSourceData(targetData);
                    setOpenDialog(MEMBER_SITE_QR_CODE_DIALOG);
                },
                closeDialog: () => handleCloseDialog(),
            };
        },
        // eslint-disable-next-line
        [],
    );

    // 以下給的property都是可選(除了open)，*****不是一定要這樣給*****，依照自己的需求給
    return (
        <React.Fragment>
            {openDialog === X_FACTOR_DIALOG && (
                <InbodyXFactorDialog open={openDialog === X_FACTOR_DIALOG} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            )}
            {openDialog === AGREED_NOTES_DIALOG && (
                <InbodyAgreedNotesDialog open={openDialog === AGREED_NOTES_DIALOG} onClose={handleCloseDialog} sourceData={sourceData} refresh={doRefresh} />
            )}
            {openDialog === DELETE_MEASURE_DIALOG && (
                <InbodyDeleteMeasureDialog
                    open={openDialog === DELETE_MEASURE_DIALOG}
                    onClose={handleCloseDialog}
                    sourceData={sourceData}
                    refresh={doRefresh}
                />
            )}
            {openDialog === INTERVIEW_NOTES_DIALOG && (
                <InbodyInterviewNoteDialog
                    open={openDialog === INTERVIEW_NOTES_DIALOG}
                    onClose={handleCloseDialog}
                    sourceData={sourceData}
                    refresh={doRefresh}
                />
            )}
            {openDialog === MEMBER_SITE_QR_CODE_DIALOG && (
                <MemberSiteQrCodeDialog
                    open={openDialog === MEMBER_SITE_QR_CODE_DIALOG}
                    onClose={handleCloseDialog}
                    sourceData={sourceData}
                    refresh={doRefresh}
                />
            )}
        </React.Fragment>
    );
});

export default InBodyInterviewDialogBox;

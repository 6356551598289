import React from 'react';

const InBodyContext = React.createContext();

export const InBodyProvider = ({ children, value }) => {
    return <InBodyContext.Provider value={value}>{children}</InBodyContext.Provider>;
};

export const useInBodyContext = () => {
    const context = React.useContext(InBodyContext);
    if (context === undefined) {
        throw new Error('useInBodyContext 必須在 InBodyProvider 中使用');
    }
    return context;
};

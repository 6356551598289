import React from 'react';
import clsx from 'clsx';
import { NavLink, useHistory } from 'react-router-dom';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import CounterBox from '@icoach/members/components/CounterBox';
import TaskTodo from '@icoach/members/components/TaskTodo';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Stack, Button, Skeleton } from '@common/components/';
import { useMemberProfileApi } from '@apis/useMemberProfileApi';
import { MEMBERS_MEMBER_DETAIL_URL } from '@icoach/router/MembersRouter';
import { BusinessCardBox } from '@icoach/components/';
import { GuidanceNotesTitle } from '@icoach/members/components';
import { INBODY_INTERVIEW_SUMMARY_PATH } from '@icoach/router/routerPath';

const TextPanel = (props) => {
    const { title, context } = props;
    return (
        <Stack direction="column" className={`member-summary-dialog-text-panel`}>
            <div className={`member-summary-dialog-text-title-panel`}>{title}</div>
            <div className={`member-summary-dialog-text-context-panel`}>{context}</div>
        </Stack>
    );
};

const ViewLoadingMemberTipBox = () => {
    return (
        <Grid container spacing={3}>
            <Grid item xs={5}>
                <Stack spacing={2} alignItems="center">
                    <Skeleton variant="circular" width={80} height={80} />
                    <Stack direction="column" spacing={1} style={{ flexGrow: 1 }}>
                        <Skeleton variant="text" height={40} />
                        <Skeleton variant="text" height={40} />
                    </Stack>
                </Stack>
            </Grid>
            <Grid item xs={7}>
                <Stack spacing={2} justifyContent="end">
                    <Skeleton variant="rectangular" width={90} height={90} />
                    <Skeleton variant="rectangular" width={90} height={90} />
                    <Skeleton variant="rectangular" width={90} height={90} />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" spacing={2}>
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                    <Skeleton variant="rectangular" height={100} />
                </Stack>
            </Grid>
            <Grid item xs={6}>
                <Stack direction="column" spacing={2}>
                    <Skeleton variant="rectangular" height={80} />
                    <Skeleton variant="rectangular" height={80} />
                </Stack>
            </Grid>
        </Grid>
    );
};

const MemberSummaryTipDialog = React.forwardRef((props, ref) => {
    const { onClose: propsOnClose, open, id: memberID, handleOpenDialog } = props;
    const history = useHistory();
    const { getMemberProfileTipApi } = useMemberProfileApi();
    const [sorceData, setSourceData] = React.useState(null);

    const getMemberInfoTipApi = async () => {
        const resp = await getMemberProfileTipApi(memberID);
        if (resp) setSourceData(resp);
    };

    const handleCloseClick = (e) => propsOnClose && propsOnClose(null);

    React.useLayoutEffect(() => {
        open ? getMemberInfoTipApi() : setSourceData(null);
        // eslint-disable-next-line
    }, [open]);

    return (
        <Dialog className={'member-summary-dialog'} PaperProps={{ className: 'wx-65rem' }} open={open} fullWidth>
            <GradientColorHeader onClose={handleCloseClick}>會員狀態</GradientColorHeader>
            {sorceData ? (
                <React.Fragment>
                    <DialogContent className="mt-3">
                        <Stack className={'member-summary-dialog-business'} justifyContent={'space-between'} spacing={2}>
                            <BusinessCardBox
                                sourceData={{
                                    memberImg: sorceData.picturePath,
                                    memberName: sorceData.memberName,
                                    nickName: sorceData.nickName,
                                    memberNo: sorceData.memberNo,
                                    membershipStatus: sorceData.membershipStatus || 0,
                                }}
                                memberAvatarWidth={7.5}
                            />
                            <Stack className={'member-summary-dialog-workout'} spacing={2}>
                                <CounterBox
                                    title="總WO"
                                    counter={<span className={`member-summary-dialog-workout-number`}>{sorceData.totalWorkoutQuantity}</span>}
                                />
                                <CounterBox
                                    title="本月WO"
                                    counter={<span className={`member-summary-dialog-workout-number`}>{sorceData.monthlyWorkoutQuantity}</span>}
                                />
                                <CounterBox
                                    title="本週WO"
                                    counter={<span className={`member-summary-dialog-workout-number`}>{sorceData.weeklyWorkoutQuantity}</span>}
                                />
                            </Stack>
                        </Stack>
                        <Grid container className="pt-4" spacing={2}>
                            <Grid item xs={6}>
                                <Stack direction="column" spacing={2}>
                                    <TextPanel title={'約定事項'} context={sorceData.agreedNotes || ''} />
                                    <TextPanel title={'X-Factor'} context={sorceData.xFactorNotes || ''} />
                                    <TextPanel title={<GuidanceNotesTitle />} context={sorceData.guidanceNotes || ''} />
                                    <TextPanel title={'特殊狀況'} context={sorceData.specialCondition || ''} />
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <TaskTodo
                                    taskType={11}
                                    memberID={memberID}
                                    className={`member-summary-dialog-checked-panel`}
                                    title="待辦事項"
                                    variant="outlined"
                                    data={sorceData.todoTasks}
                                    hasIcon
                                />
                                <TaskTodo
                                    taskType={12}
                                    memberID={memberID}
                                    className={`member-summary-dialog-checked-panel`}
                                    title="服銷待辦事項"
                                    variant="outlined"
                                    data={sorceData.serviceTasks}
                                    options={sorceData.resource?.serviceTodoTextOptions ?? []}
                                    // resource
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions className={clsx(`member-summary-dialog-actions`, 'p-3')}>
                        <Stack spacing={2}>
                            <Button variant="outlined" onClick={() => history.push(`${INBODY_INTERVIEW_SUMMARY_PATH(memberID)}`)}>
                                開始面談
                            </Button>
                            <Button variant="outlined" onClick={() => handleOpenDialog('checkout', memberID)}>
                                查看欠費明細
                            </Button>
                            <Button variant="contained" component={NavLink} to={MEMBERS_MEMBER_DETAIL_URL(memberID)}>
                                查看會員資訊
                            </Button>
                        </Stack>
                    </DialogActions>
                </React.Fragment>
            ) : (
                <DialogContent className="mt-3">
                    <ViewLoadingMemberTipBox />
                </DialogContent>
            )}
        </Dialog>
    );
});

export default MemberSummaryTipDialog;

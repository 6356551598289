import { useEffect, useState } from 'react';
import useEmployeeApi from '@apis/useEmployeeApi';

const useGetEmployeeOptionApi = ({ isFetchOnMount = true } = {}) => {
    const { getEmployeesOptionsApi: doEmployeesOptionsApi } = useEmployeeApi();
    const [option, setOption] = useState([]);

    const getEmployeesOptionsApi = async () => {
        const resp = await doEmployeesOptionsApi();
        if (resp) setOption(resp);
    };

    useEffect(() => {
        if (isFetchOnMount) getEmployeesOptionsApi();

        // eslint-disable-next-line
    }, [isFetchOnMount]);

    return { option, getEmployeesOptionsApi };
};

export default useGetEmployeeOptionApi;

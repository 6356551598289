import { useEffect, useRef } from 'react';

/**
 * 用於實現表格左側固定單元格的自定義 Hook
 * @returns {{ tableContainerRef: React.RefObject, frozenCellsRef: React.RefObject }}
 */
const useFrozenTableCells = () => {
    const tableContainerRef = useRef(null);
    const frozenCellsRef = useRef([]);

    useEffect(() => {
        const tableContainer = tableContainerRef.current;
        const handleScroll = () => {
            const scrollLeft = tableContainer.scrollLeft;

            // 固定左側
            frozenCellsRef.current.forEach((cell) => {
                if (scrollLeft > 0) {
                    cell.style.position = 'sticky';
                    cell.style.left = '0';
                    cell.style.zIndex = '10';
                } else {
                    cell.style.position = 'static';
                }
            });
        };

        if (tableContainer) tableContainer.addEventListener('scroll', handleScroll);

        return () => {
            if (tableContainer) tableContainer.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return { tableContainerRef, frozenCellsRef };
};

export default useFrozenTableCells;

import React, { useState, useRef, useImperativeHandle, useLayoutEffect } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { Button, Tabs, Tab, TabPanel } from '@common/components/';
import { refIsRequiredError } from '@util/utils';

const OldStepper = React.forwardRef((props, ref) => {
    const {
        stepSourceContent: stepContent,
        onTemporaryStorageEvent,
        onSubmitEvent,
        onReturnHistory,
        onChange: onChangeProps,
        isTemporary = true,
        isSubmit = true,
        isBack = false,
        isCancel = true,
        tabsProps = {},
        tabsFooterProps = {},
        TabPanelProps = {},
        ButtonEls = <></>,
    } = props;

    const history = useHistory();
    const [tabsIndex, changeTabIndex] = useState(0);
    const contentRef = useRef(null);
    const isInitRef = useRef(true);
    const tabRef = useRef(null);

    const handleChangeTab = (e, index) => {
        if (index < 0 || index >= stepContent.length) return false;
        if (typeof onChangeProps === 'function') onChangeProps(tabsIndex);
        changeTabIndex(index);
    };

    const handleCancel = () => {
        history.goBack();
    };

    const handleTemporaryStorage = (e) => {
        if (typeof onChangeProps === 'function') onChangeProps(tabsIndex);
        if (typeof onTemporaryStorageEvent === 'function') onTemporaryStorageEvent(e);
    };
    const handleSubmit = (e) => {
        if (typeof onChangeProps === 'function') onChangeProps(tabsIndex);
        if (typeof onSubmitEvent === 'function') onSubmitEvent(e);
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                if (contentRef.current && contentRef.current.hasOwnProperty('getResult')) {
                    return contentRef.current.getResult();
                }
            },
            getTabIndex: () => {
                return tabsIndex;
            },
            isError: () => refIsRequiredError(contentRef),
        }),
        // eslint-disable-next-line
        [tabsIndex],
    );

    useLayoutEffect(
        () => {
            if (!tabRef.current) return null;
            if (!window) return null;
            let scrollToTop = 0;

            if (!isInitRef.current) {
                window.scrollTo({
                    top: scrollToTop,
                    behavior: 'smooth',
                });
            } else {
                isInitRef.current = false;
            }
        },
        // eslint-disable-next-line
        [tabsIndex, tabRef],
    );

    const tabPanelUseMemo = React.useMemo(
        () => {
            return stepContent.map(({ id, content }, index) => {
                return (
                    <TabPanel className={clsx('stepper-tabs-panel', TabPanelProps.className)} key={id} value={tabsIndex} index={index}>
                        {React.cloneElement(content, { ref: contentRef })}
                    </TabPanel>
                );
            });
        },
        // eslint-disable-next-line
        [tabsIndex],
    );

    return (
        <React.Fragment>
            <Tabs ref={tabRef} className={clsx('stepper-tabs', tabsProps.className)} value={tabsIndex} onChange={handleChangeTab}>
                {stepContent.map((item, index) => {
                    const { id, label, hasError } = item;
                    return <Tab key={id} index={index} label={label} className={clsx({ state: hasError })} />;
                })}
            </Tabs>
            {tabPanelUseMemo}
            <div className={clsx('stepper-tabs-footer', tabsFooterProps.className)}>
                <div className="btn-group">
                    {tabsIndex !== 0 && (
                        <Button variant="outlined" color="secondary" className="btn-minWidth prev" onClick={(e) => handleChangeTab(e, tabsIndex - 1)}>
                            上一步
                        </Button>
                    )}
                    {
                        <div className="btn-group">
                            {isCancel && (
                                <Button color="secondary" className="btn-minWidth" onClick={handleCancel}>
                                    取消
                                </Button>
                            )}
                            {isTemporary && (
                                <Button name="temporary" variant="contained" color="secondary" className="btn-minWidth" onClick={handleTemporaryStorage}>
                                    暫存檔案
                                </Button>
                            )}
                            {isSubmit && tabsIndex === stepContent.length - 1 && (
                                <Button name="create" variant="contained" color="secondary" className="btn-minWidth" onClick={handleSubmit}>
                                    確認送出
                                </Button>
                            )}
                            {isBack && (
                                <Button variant="contained" color="secondary" className="btn-minWidth" onClick={onReturnHistory}>
                                    返回
                                </Button>
                            )}
                            {React.isValidElement(ButtonEls) && ButtonEls}
                        </div>
                    }
                    {tabsIndex !== stepContent.length - 1 && (
                        <Button variant="outlined" color="secondary" className="btn-minWidth next" onClick={(e) => handleChangeTab(e, tabsIndex + 1)}>
                            下一步
                        </Button>
                    )}
                </div>
            </div>
        </React.Fragment>
    );
});

export default OldStepper;

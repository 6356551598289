import React, { useRef } from 'react';
import { Grid } from '@mui/material';
import EmployeesInformationsAddContactsItem from '@icoach/employees/informations/EmployeesInformationsAddContactsItem';
import EmployeesInformationsJobSituationItem from '@icoach/employees/informations/EmployeesInformationsJobSituationItem';
import { AddressField } from '@icoach/documents/components';
import { Button, Stack, TextField, DateField, SelectField, MenuItem, CheckboxField } from '@common/components/';
import { getRadioFieldResult, refIsRequiredError } from '@util/utils';
import useEmployeeApi from '@apis/useEmployeeApi';

const EmployeesInformationsBasicEditContent = React.forwardRef((props, ref) => {
    const { sourceData, options, onCancelEditClick: onCancelEditClickProps, onReadOnlyApi } = props;
    const { putEmployeesApi } = useEmployeeApi();
    const {
        employeeID,
        employeeName,
        nickName,
        birthday,
        jobTitleType,
        identifierID,
        isForeigner,
        email,
        mobile,
        cardNo,
        permanentPostCode,
        permanentAddress,
        correspondencePostCode,
        correspondenceAddress,
        hireDate,
        employeeStatus,
        resignDate,
        contacts,
        height,
    } = sourceData;
    const { employeeRankTypeOptions, employeeStatusOptions } = options;

    const employeeNameRef = useRef(null);
    const nickNameRef = useRef(null);
    const birthdayRef = useRef(null);
    const heightRef = useRef(null);
    const jobTitleTypeRef = useRef(null);
    const identifierIDRef = useRef(null);
    const isForeignerRef = useRef(null);
    const emailRef = useRef(null);
    const mobileRef = useRef(null);
    const cardNoRef = useRef(null);
    const permanentRef = useRef(null);
    const correspondenceRef = useRef(null);
    const employeesContactsItemRef = useRef(null);
    const jobSituationItemRef = useRef(null);
    const hireDateRef = useRef(null);

    const getAllParams = () => {
        const isForeignerValue = getRadioFieldResult(isForeignerRef.current.getResult(), 'checked', false);
        const { postCode: pc, address: pa } = permanentRef.current.getResult();
        const { postCode: cp, address: ca } = correspondenceRef.current.getResult();
        const jobSituationData = jobSituationItemRef.current.getResult();
        const params = {
            employeeID: employeeID,
            name: employeeNameRef.current.getResult(),
            nickName: nickNameRef.current.getResult(),
            birthday: birthdayRef.current.getResult(),
            height: heightRef.current.getResult(),
            jobTitleType: jobTitleTypeRef.current.getResult(),
            identifierID: identifierIDRef.current.getResult(),
            isForeigner: isForeignerValue,
            email: emailRef.current.getResult(),
            mobile: mobileRef.current.getResult(),
            cardNo: cardNoRef.current.getResult(),
            permanentPostCode: pc,
            permanentAddress: pa,
            correspondencePostCode: cp,
            correspondenceAddress: ca,
            contacts: employeesContactsItemRef.current.getResult(),
            hireDate: hireDateRef.current.getResult(),
            ...jobSituationData,
        };
        return params;
    };

    const handleSaveEditClick = () => {
        if (
            !refIsRequiredError(
                employeeNameRef,
                nickNameRef,
                birthdayRef,
                jobTitleTypeRef,
                identifierIDRef,
                isForeignerRef,
                emailRef,
                mobileRef,
                cardNoRef,
                permanentRef,
                correspondenceRef,
                employeesContactsItemRef,
                jobSituationItemRef,
                hireDateRef,
            )
        ) {
            postEditEmployeeInformationsDateApi(getAllParams());
        }
    };

    const postEditEmployeeInformationsDateApi = async (params) => {
        const resp = await putEmployeesApi(params);
        if (resp) {
            await onReadOnlyApi(employeeID);
            onCancelEditClickProps();
        }
    };

    return (
        <React.Fragment>
            <Stack justifyContent="end" spacing={2}>
                <Button variant="outlined" onClick={onCancelEditClickProps}>
                    取消
                </Button>
                <Button variant="contained" onClick={handleSaveEditClick}>
                    修改
                </Button>
            </Stack>
            <Grid container spacing={3}>
                <Grid xs={4} item>
                    <TextField label="員工姓名" ref={employeeNameRef} defaultValue={employeeName} fullWidth required />
                </Grid>
                <Grid xs={4} item>
                    <TextField label="名牌名稱" ref={nickNameRef} defaultValue={nickName} fullWidth />
                </Grid>
                <Grid xs={4} item>
                    <DateField label="生日" ref={birthdayRef} defaultValue={birthday} fullWidth required />
                </Grid>
                <Grid xs={4} item>
                    <SelectField label="職稱" ref={jobTitleTypeRef} defaultValue={jobTitleType} fullWidth required>
                        {employeeRankTypeOptions.map(({ text, value, disabled }) => (
                            <MenuItem key={value} value={value} disabled={disabled}>
                                {text}
                            </MenuItem>
                        ))}
                    </SelectField>
                </Grid>
                <Grid xs={4} item>
                    <TextField label="身份證字號" ref={identifierIDRef} defaultValue={identifierID} fullWidth />
                </Grid>
                <Grid xs={4} item>
                    <CheckboxField ref={isForeignerRef} defaultChecked={[isForeigner]} className={'mt-4'}>
                        <CheckboxField.Checkbox label="非中華名國國籍" />
                    </CheckboxField>
                </Grid>
                <Grid xs={4} item>
                    <TextField label={'身高'} ref={heightRef} type={'number'} defaultValue={height} fullWidth />
                </Grid>
                <Grid xs={8} item>
                    <TextField label="電子信箱" ref={emailRef} defaultValue={email} fullWidth />
                </Grid>
                <Grid xs={4} item>
                    <TextField label="手機號碼" ref={mobileRef} defaultValue={mobile} fullWidth />
                </Grid>
                <Grid xs={4} item>
                    <TextField label="卡號" ref={cardNoRef} defaultValue={cardNo} fullWidth />
                </Grid>
                <Grid xs={12} item>
                    <AddressField label={'戶籍地址'} ref={permanentRef} postCode={permanentPostCode} address={permanentAddress} />
                </Grid>
                <Grid xs={12} item>
                    <AddressField label={'通訊地址'} ref={correspondenceRef} postCode={correspondencePostCode} address={correspondenceAddress} />
                </Grid>
                <Grid xs={12} item>
                    <Grid container spacing={2}>
                        <EmployeesInformationsAddContactsItem ref={employeesContactsItemRef} sourceData={contacts} />
                    </Grid>
                </Grid>
                <Grid xs={4} item>
                    <DateField label="到職日期" ref={hireDateRef} defaultValue={hireDate} fullWidth required />
                </Grid>
                <Grid xs={8} item>
                    <EmployeesInformationsJobSituationItem
                        ref={jobSituationItemRef}
                        options={employeeStatusOptions}
                        sourceData={{ employeeStatus: employeeStatus, resignDate: resignDate }}
                    />
                </Grid>
            </Grid>
        </React.Fragment>
    );
});

export default EmployeesInformationsBasicEditContent;

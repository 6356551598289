import { useCallback } from 'react';
import useAjax from '@apis/useAjax';
import { useSnackbar } from 'notistack';
import useShowLoading from '@util/hook/useShowLoading';
import { isEmpty } from '@util/utils';

const GET_ORDERS_CHECKOUT_OPTIONS_API = `/api/orders/checkout/options/`;
const GET_ORDERS_CHECKOUT_API = `/api/orders/checkout/`;
const POST_ORDERS_CHECKOUT_BY_PEOPLE_API = `/api/orders/checkout/by-people/`;

const useOrderCheckoutApi = () => {
    const Ajax = useAjax();
    const isLoading = useShowLoading();
    const { enqueueSnackbar } = useSnackbar();

    // 取得結帳需使用到的下拉式選單
    const getOrderCheckoutOptionsApi = useCallback(
        async () => {
            let resp = await Ajax.get(GET_ORDERS_CHECKOUT_OPTIONS_API);
            if (!resp) {
                enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得未結帳的會員訂單清單
    const getOrderCheckoutApi = useCallback(
        async (id) => {
            const resp = await Ajax.get(GET_ORDERS_CHECKOUT_API, { id });
            if (!resp) {
                enqueueSnackbar('讀取資料失敗', { variant: 'error' });
            }

            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得結帳需使用到的下拉式選單
    // 取得未結帳的會員訂單清單
    const getMemberInfoCardApi = useCallback(
        async (id) => {
            let params = { id };
            let result = {};
            isLoading(true);
            const orderData = await Ajax.get(GET_ORDERS_CHECKOUT_API, params);
            const resource = await Ajax.get(GET_ORDERS_CHECKOUT_OPTIONS_API);
            if (orderData) result = Object.assign(result, { orderData });
            if (resource) result = Object.assign(result, { resource });
            isLoading(false);
            return result;
        },
        // eslint-disable-next-line
        [],
    );

    // 發送個人結帳資料(包含未結帳及新增訂單項目)
    const postOrderCheckoutByPeopleApi = useCallback(
        async (parameters) => {
            let resp = await Ajax.post(POST_ORDERS_CHECKOUT_BY_PEOPLE_API, parameters);
            if (!isEmpty(resp) && resp.isError && typeof resp.result === 'string') return null; // 排除 0002 錯誤
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getOrderCheckoutOptionsApi,
        getOrderCheckoutApi,
        getMemberInfoCardApi,
        postOrderCheckoutByPeopleApi,
    };
};

export default useOrderCheckoutApi;

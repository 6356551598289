import React, { useEffect, useState, useRef } from 'react';
import { Dialog, DialogContent, DialogActions } from '@mui/material/';
import { Button, SelectField, MenuItem, Form } from '@common/components/';
import UploadBox from '@icoach/components/UploadBox';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import useEmployeeApi from '@apis/useEmployeeApi';
import useInBodyApi from '@apis/useInBodyApi';
import { refIsRequiredError } from '@util/utils';
import { UploadFeedbackBox } from '@icoach/components';
import { useLocalStorage } from '@util/hook/useStorage';
import { Grid } from '@mui/material';
import useGetInbodyOverviewOptionsApi from '@util/hook/useGetInbodyOverviewOptionsApi';
import { referenceTypeMap } from '@icoach/inbody/staticData';

const getInitFeedbackData = () => {
    return {
        isShow: false,
        isError: false,
        data: {},
    };
};

const UploadInbodyDataDialog = React.forwardRef((props, ref) => {
    const { open, onClose } = props;
    if (!open) return null;
    const { getEmployeesOptionsApi } = useEmployeeApi();
    const { postUploadInbodyExcelApi, postUploadInbody370DM0536ExcelApi } = useInBodyApi();
    const [feedbackData, setFeedBackData] = useState(getInitFeedbackData());
    const [employeeOption, setEmployeeOption] = useState([]);
    const { options: referenceTypeOptions } = useGetInbodyOverviewOptionsApi();
    const employeeIDRef = useRef(null);
    const referenceTypeRef = useRef(null);
    const uploadFileRef = useRef(null);
    const { value } = useLocalStorage('user');
    const is370DM0536 = /^(C071).*/g.test(value.userName);
    const fileExtensionFile = is370DM0536 ? ['xlsx'] : ['csv'];

    const initEmployeeOptions = async () => {
        const resp = await getEmployeesOptionsApi();
        if (resp) {
            setEmployeeOption(resp);
        }
    };

    const getParams = () => {
        let result = {};

        if (employeeIDRef.current && employeeIDRef.current.getResult) {
            Object.assign(result, { employeeID: employeeIDRef.current.getResult() });
        }

        if (process.env.isProductionEnv) {
            Object.assign(result, { referenceType: referenceTypeMap.member });
        } else {
            if (referenceTypeRef.current && referenceTypeRef.current.getResult) {
                Object.assign(result, { referenceType: referenceTypeRef.current.getResult() });
            }
        }

        if (uploadFileRef.current && uploadFileRef.current.getResult) {
            Object.assign(result, { file: uploadFileRef.current.getResult() });
        }

        return result;
    };

    const doUploadInbodyExcelApi = async (params) => {
        let resp;
        if (is370DM0536) {
            resp = await postUploadInbody370DM0536ExcelApi(params);
        } else {
            resp = await postUploadInbodyExcelApi(params);
        }
        if (resp) {
            if (resp.isError) {
                const { isError, result } = resp;
                setFeedBackData({
                    isShow: true,
                    isError,
                    data: result,
                });
            } else {
                onClose();
            }
        }
    };

    const handleSubmit = () => {
        const isError = refIsRequiredError(uploadFileRef, employeeIDRef);
        if (!isError) {
            const formData = new FormData();
            const { employeeID, file, referenceType } = getParams();
            formData.append('file', file.file, `${file.name}.${file.extension}`);
            formData.append('employeeID', employeeID);
            formData.append('referenceType', referenceType);
            doUploadInbodyExcelApi(formData);
        }
    };

    useEffect(
        () => {
            if (!open) {
                setFeedBackData(getInitFeedbackData());
            } else {
                initEmployeeOptions();
            }
        },
        // eslint-disable-next-line
        [open],
    );

    return (
        <Dialog className={'simple-content dialog'} PaperProps={{ className: 'wx-65rem', component: Form }} open={open} onSubmit={handleSubmit} fullWidth>
            <GradientColorHeader onClose={onClose}>批次上傳量身紀錄</GradientColorHeader>
            <DialogContent>
                {!feedbackData.isError ? (
                    <UploadBox ref={uploadFileRef} className={'import-ach-box'} fileExtension={fileExtensionFile} required />
                ) : (
                    <UploadFeedbackBox
                        isShow={feedbackData.isShow}
                        className={'import-ach-box'}
                        isError={feedbackData.isError}
                        {...feedbackData.data}
                        onConfirm={onClose}
                        onDownload={onClose}
                    />
                )}
                {!feedbackData.isShow && (
                    <Grid className={'mt-1'} spacing={3} container>
                        {!process.env.isProductionEnv && (
                            <Grid xs={6} item>
                                <SelectField label={'對象'} ref={referenceTypeRef} displayEmpty fullWidth required>
                                    {Array.isArray(referenceTypeOptions) &&
                                        referenceTypeOptions
                                            .filter((item) => item || !item.disabled)
                                            .map((item) => (
                                                <MenuItem key={item.value} value={item.value}>
                                                    {item.text}
                                                </MenuItem>
                                            ))}
                                </SelectField>
                            </Grid>
                        )}

                        <Grid xs={!process.env.isProductionEnv ? 6 : 12} item>
                            <SelectField label={'擔當教練'} ref={employeeIDRef} displayEmpty fullWidth required>
                                {Array.isArray(employeeOption) &&
                                    employeeOption
                                        .filter((item) => item || !item.disabled)
                                        .map((item) => (
                                            <MenuItem key={item.value} value={item.value}>
                                                {item.text}
                                            </MenuItem>
                                        ))}
                            </SelectField>
                        </Grid>
                    </Grid>
                )}
            </DialogContent>
            {!feedbackData.isShow && (
                <DialogActions>
                    <Button variant="contained" type="submit">
                        確認匯入
                    </Button>
                </DialogActions>
            )}
        </Dialog>
    );
});

const InbodyUploadExcel = React.memo(() => {
    const [open, setOpen] = useState(false);
    const handleUploadClick = () => {
        setOpen(true);
    };

    const handleOnClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Button variant="contained" onClick={handleUploadClick}>
                批次上傳
            </Button>
            <UploadInbodyDataDialog open={open} onClose={handleOnClose} />
        </React.Fragment>
    );
});

export default InbodyUploadExcel;

import React, { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { Grid } from '@mui/material';
import { Alert, Box, Button, Card, Tab, Tabs, Typography } from '@common/components/';
import { TabsLabel } from '@icoach/components/';
import { SimpleContentItem } from '@icoach/members/components';
import useOrderBatchPaymentApi from '@apis/useOrderBatchPaymentApi';
import { AccountBookProvider, useAccountBookContext } from '@icoach/accountBook/components/AccountBookContext';
import { parseDate } from '@util/moment';
import { formatCurrencyFn, isEmpty, serialize } from '@util/utils';
import useMessageDialog from '@util/hook/useMessageDialog';
import BatchPaymentBillingByCreditCard from '@icoach/accountBook/BatchPaymentBillingByCreditCard';
import BatchPaymentBillingByAch from '@icoach/accountBook/BatchPaymentBillingByAch';
import BillingResultCreditCardTable from '@icoach/accountBook/tables/BillingResultCreditCardTable';
import BillingResultAchTable from '@icoach/accountBook/tables/BillingResultAchTable';
import BillingPendingPaymentByCreditCardTable from '@icoach/accountBook/tables/BillingPendingPaymentByCreditCardTable';
import BillingPendingPaymentByAchTable from '@icoach/accountBook/tables/BillingPendingPaymentByAchTable';
import AccountBookDialogBox from '@icoach/accountBook/dialog/AccountBookDialogBox';
import { ACCOUNT_BOOK_BULK_DEBIT_URL } from '@icoach/router/AccountBookRouter';
import { useHistory } from 'react-router-dom';

const TAB_IDLE_STATE = 'idleState';
const TAB_SUCCESS_STATE = 'successState';
const TAB_FAILURE_STATE = 'failureState';

// 扣款結果狀態
const isBillingDebitResult = (billingStatus) => {
    if (!billingStatus) return false;
    switch (billingStatus.toString()) {
        case '5':
            // 已有成功或失敗比數
            return true;
        default:
            // 尚未有扣款結果
            return false;
    }
};

const ActionButtonGroup = () => {
    const history = useHistory();
    const { billingData, refresh, dialogRef, paramData } = useAccountBookContext();
    const { billingID, canCancel, canAchAction } = billingData?.billing || {};
    const { deleteOrderBatchPaymentBillingApi, getOrderBatchPaymentAchExportApi } = useOrderBatchPaymentApi();
    const setMessageDialog = useMessageDialog();

    const createRefreshHandler = (billingID) => {
        return () => refresh && refresh(billingID);
    };

    const refreshBilling = createRefreshHandler(billingID);

    const doDeleteOrderBatchPaymentBillingApi = async (bID, onClose) => {
        let res = await deleteOrderBatchPaymentBillingApi(bID);
        if (res) {
            refreshBilling();
            onClose();
        }
    };

    // 下載扣款名單
    const handleClickDownload = async (bID) => {
        await getOrderBatchPaymentAchExportApi(bID);
    };

    // 刪除扣款群組
    const handleClickDelete = (bID) => {
        setMessageDialog({
            open: true,
            title: '確認取消批次扣款',
            msg: '取消後將停止交易作業且無法恢復，請問確定要取消嗎?',
            onAsyncConfirm: (_, onclose) => doDeleteOrderBatchPaymentBillingApi(bID, onclose),
        });
    };

    const goToOrderPage = (params) => {
        const updateUrlWithSearchQuery = (params) => {
            let url = ACCOUNT_BOOK_BULK_DEBIT_URL;
            if (!isEmpty(params)) url += `?${serialize(params)}`;
            history.push(url);
        };

        updateUrlWithSearchQuery(params);
    };

    return (
        <React.Fragment>
            <Box className="btn-group">
                {canAchAction && (
                    <React.Fragment>
                        <Button variant={'outlined'} onClick={() => handleClickDownload(billingID)}>
                            下載扣款名單
                        </Button>
                        <Button variant={'outlined'} onClick={() => dialogRef.current && dialogRef.current.openImportAchDialog({ billingID }, refreshBilling)}>
                            回填扣款紀錄
                        </Button>
                    </React.Fragment>
                )}
                {canCancel && (
                    <Button variant={'outlined'} color={'error'} onClick={() => handleClickDelete(billingID)}>
                        取消批次扣款
                    </Button>
                )}
                <Button variant={'contained'} onClick={() => goToOrderPage({ ...paramData, bulkDebitBillingType: 1 })}>
                    返回列表
                </Button>
            </Box>
        </React.Fragment>
    );
};

const FailedPaymentList = () => {
    const { billingData } = useAccountBookContext();
    const { failureRecords, billing } = billingData || {};
    const { billingTarget, reBillingQuantity } = billing || {};

    return (
        <React.Fragment>
            {Boolean(reBillingQuantity) && (
                <Alert className={'alert-justify-content-start mb-2'} variant="filled" severity="info" color="info">
                    {`已有 ${reBillingQuantity} 筆名單重新發送批次扣款。`}
                </Alert>
            )}
            {String(billingTarget) === '1' && <BatchPaymentBillingByCreditCard tableData={failureRecords} />}
            {String(billingTarget) === '51' && <BatchPaymentBillingByAch tableData={failureRecords} />}
        </React.Fragment>
    );
};

const SuccessPaymentList = (props) => {
    const { billingData } = useAccountBookContext();
    const { records, billing } = billingData || {};
    const { billingTarget, billingStatus } = billing || {};

    if (!isBillingDebitResult(billingStatus))
        return (
            <React.Fragment>
                {String(billingTarget) === '1' && <BillingPendingPaymentByCreditCardTable className={'theme-gray'} rowsData={records} />}
                {String(billingTarget) === '51' && <BillingPendingPaymentByAchTable className={'theme-gray'} rowsData={records} />}
            </React.Fragment>
        );

    return (
        <React.Fragment>
            {String(billingTarget) === '1' && <BillingResultCreditCardTable className={'theme-gray'} rowsData={records} />}
            {String(billingTarget) === '51' && <BillingResultAchTable className={'theme-gray'} rowsData={records} />}
        </React.Fragment>
    );
};

const BatchPaymentBillingTabs = () => {
    const { billingData, tabsIndex, handleTabChange } = useAccountBookContext();
    const { billingStatus, failureQuantity, successQuantity } = billingData?.billing ?? {};

    return (
        <Box component={'header'} className={'page-header'}>
            {isBillingDebitResult(billingStatus) ? (
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab
                        className={'py-3'}
                        label={
                            <TabsLabel
                                className={clsx({
                                    'tab-selected': tabsIndex === TAB_FAILURE_STATE,
                                })}
                                label={'失敗名單'}
                                count={failureQuantity}
                            />
                        }
                        value={TAB_FAILURE_STATE}
                    />
                    <Tab
                        className={'py-3'}
                        label={
                            <TabsLabel
                                className={clsx({
                                    'tab-selected': tabsIndex === TAB_SUCCESS_STATE,
                                })}
                                label={'成功名單'}
                                count={successQuantity}
                            />
                        }
                        value={TAB_SUCCESS_STATE}
                    />
                </Tabs>
            ) : (
                <Tabs value={tabsIndex} onChange={handleTabChange}>
                    <Tab className={'py-3'} label={'扣款名單'} value={TAB_IDLE_STATE} />
                </Tabs>
            )}
        </Box>
    );
};

const BatchPaymentBillingContent = () => {
    const { billingData } = useAccountBookContext();
    const {
        billingTargetText,
        billingStatusText,
        sendDate,
        totalQuantity,
        paymentPrice,
        returnPrice,
        employeeDisplayName,
        successPaymentPrice = 0,
    } = billingData?.billing ?? {};
    return (
        <Card className={'mb-4 px-4 py-3'}>
            <Card.CardContent>
                <Grid spacing={2} container>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'帳單狀態'}>{billingStatusText}</SimpleContentItem>
                    </Grid>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'扣款方式'}>{billingTargetText}</SimpleContentItem>
                    </Grid>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'送件日期'}>{parseDate(sendDate, 'YYYY/MM/DD')}</SimpleContentItem>
                    </Grid>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'擔當'}>{employeeDisplayName}</SimpleContentItem>
                    </Grid>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'總筆數'}>{totalQuantity}</SimpleContentItem>
                    </Grid>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'扣款金額'}>{formatCurrencyFn(paymentPrice, { symbol: '$' })}</SimpleContentItem>
                    </Grid>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'退款金額'}> {formatCurrencyFn(returnPrice, { symbol: '$' })}</SimpleContentItem>
                    </Grid>
                    <Grid xs={3} item>
                        <SimpleContentItem label={'請款成功金額'}>{formatCurrencyFn(successPaymentPrice, { symbol: '$' })}</SimpleContentItem>
                    </Grid>
                </Grid>
            </Card.CardContent>
        </Card>
    );
};

const BatchPaymentBillingDetailPage = (props) => {
    const { billingID } = props.match.params;
    const { getInitBatchPaymentBillingApi } = useOrderBatchPaymentApi();
    const [data, setData] = useState({});
    const { resource = {}, billingData = {} } = data;
    const { billingStatus, billingCategory, sendDate } = billingData?.billing ?? {};
    const [tabsIndex, setTabsIndex] = useState(TAB_IDLE_STATE);
    const dialogRef = useRef(null);

    const doInitBatchPaymentBillingApi = async (billingID) => {
        let res = await getInitBatchPaymentBillingApi(billingID);
        if (res) {
            setData(res);
        }
    };

    const handleTabChange = (e, value) => {
        setTabsIndex(value);
    };

    const childContext = {
        paramData: {
            type: billingCategory,
            date: parseDate(sendDate, 'YYYY-MM'),
        },
        resource,
        billingData,
        tabsIndex,
        handleTabChange,
        refresh: doInitBatchPaymentBillingApi,
        dialogRef,
    };

    useEffect(
        () => {
            if (billingID) {
                doInitBatchPaymentBillingApi(billingID);
            }
        },
        // eslint-disable-next-line
        [billingID],
    );

    useEffect(
        () => {
            if (billingStatus) {
                handleTabChange(undefined, isBillingDebitResult(billingStatus) ? TAB_FAILURE_STATE : TAB_IDLE_STATE);
            }
        },
        // eslint-disable-next-line
        [billingStatus],
    );

    return (
        <AccountBookProvider value={childContext}>
            <Box className={'container main-container-spacing order-detail-page'}>
                <Box component={'header'} className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        批次扣款交易明細
                    </Typography>
                    <ActionButtonGroup />
                </Box>
                <BatchPaymentBillingContent />
                <Card className={'tabs-content-layout'}>
                    <BatchPaymentBillingTabs />
                    <Card.CardContent className={'p-4'}>
                        {tabsIndex === TAB_FAILURE_STATE && <FailedPaymentList />}
                        {(tabsIndex === TAB_IDLE_STATE || tabsIndex === TAB_SUCCESS_STATE) && <SuccessPaymentList />}
                    </Card.CardContent>
                </Card>
            </Box>
            <AccountBookDialogBox ref={dialogRef} />
        </AccountBookProvider>
    );
};

export default BatchPaymentBillingDetailPage;

import React from 'react';
import { BusinessCardBox, LabelCheckBox, QuickMoreLabelBox, DescribeLabelBox } from '@icoach/components/';

/**
 * 會員
 */
export const memberSheet = {
    cellKey: 'memberSheet',
    headerLabel: '會員',
    width: 20,
    maxWidth: 23,
    align: 'left',
    formatCell: (_value, rowData, { routerPath, isShowAvatar = true, isShowAvatarImage = true, onKeepState }) => {
        const handleOnClick = () => {
            onKeepState && onKeepState(rowData);
        };
        return (
            <BusinessCardBox
                sourceData={rowData}
                routerPath={routerPath}
                isShowAvatar={isShowAvatar}
                isShowAvatarImage={isShowAvatarImage}
                onClick={handleOnClick}
            />
        );
    },
};

/**
 * 手機
 */
export const cellPhoneSheet = {
    cellKey: 'cellPhone',
    headerLabel: '手機',
    width: 13,
    align: 'center',
};

/**
 * 聯繫紀錄
 */
export const contactSheet = {
    cellKey: 'contact',
    headerLabel: '聯繫',
    width: 30,
    maxWidth: 30,
    align: 'left',
    formatCell: (value, { memberID }, { setContactState }) => {
        return value ? <DescribeLabelBox {...value} targetID={memberID} peopelType={'2'} setContactState={setContactState} /> : null;
    },
};

/**
 * Cell 勾選按鈕
 */
export const rowLabelCheckboxSheet = {
    // cellKey: 'memberID',
    isIcon: true,
    maxWidth: 7,
    width: 7,
    formatCell: (value, rowData, { checkBoxsRef, onCheckBoxChange, getCheckedItems, sequence }) => {
        let isChecked = false;
        const key = `${value}_${sequence}`;
        if ((getCheckedItems() || []).includes(key)) isChecked = true;
        return (
            <LabelCheckBox
                ref={(el) => (checkBoxsRef.current[key] = el)}
                onChange={onCheckBoxChange}
                idKey={key}
                defalutCehecked={isChecked}
                sourceData={rowData}
                disabled={String(rowData.redeemStatus) === '1'}
            />
        );
    },
};

/**
 * 更多
 */
export const moreSheet = {
    cellKey: 'more',
    headerLabel: '',
    width: 6.8,
    formatCell: (value, rowData, { dialogRef, routerPath, targetType, onKeepState }) => {
        const newRowData = { ...rowData, id: rowData.memberID, type: 2 };
        return <QuickMoreLabelBox rowData={newRowData} dialogRef={dialogRef} routerPath={routerPath} targetType={targetType} onKeepState={onKeepState} />;
    },
};

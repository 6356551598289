import { useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';

const GET_MEMBERS_OPTIONS_API = `/api/members/options/`;
const POST_MEMBERS_OVERVIEW_API = `/api/members/overview/`;
const GET_MEMBERS_MEMBERID_USER_API = (memberID) => `/members/${memberID}/user.jpg`;
const POST_MEMBERS_MEMBERID_PHOTO_API = (memberID) => `/api/members/${memberID}/photo/`;
const POST_MEMBERS_MEMBERID_TRANSCRIPT_PHOTO_API = (memberID) => `/api/members/${memberID}/transcript/photo/`;

const useMemberApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    const getMembersOptions = useCallback(
        async () => {
            const resp = await Ajax.get(GET_MEMBERS_OPTIONS_API);
            if (!resp) enqueueSnackbar('讀取資料失敗,請重新整理', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postMembersOverview = useCallback(
        async (params) => {
            const resp = await Ajax.post(POST_MEMBERS_OVERVIEW_API, params);
            if (!resp) enqueueSnackbar('查詢失敗,請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const getMembersType = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBERS_MEMBERID_USER_API(memberID));
            if (!resp) enqueueSnackbar('查詢失敗,請重新再操作一次', { variant: 'error' });
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postMemberAvatarApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.post(POST_MEMBERS_MEMBERID_PHOTO_API(memberID), params, {
                headerOption: { headers: { 'Content-Type': 'multipart/form-data' } },
            });
            if (resp) {
                enqueueSnackbar('大頭照更新成功', { variant: 'success' });
            } else {
                enqueueSnackbar('上傳頭像失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    const postMemberTranscriptAvatarApi = useCallback(
        async (memberID, params) => {
            const resp = await Ajax.post(POST_MEMBERS_MEMBERID_TRANSCRIPT_PHOTO_API(memberID), params, {
                headerOption: { headers: { 'Content-Type': 'multipart/form-data' } },
            });
            if (resp) {
                enqueueSnackbar('排行榜大頭照更新成功', { variant: 'success' });
            } else {
                enqueueSnackbar('上傳失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        []
    );

    return {
        getMembersOptions,
        postMembersOverview: postMembersOverview,
        getMembersType,
        postMemberAvatarApi,
        postMemberTranscriptAvatarApi,
    };
};

export default useMemberApi;

import React from 'react';
import { Button } from '../../../components';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';

const InBodyDeleteMeasure = React.forwardRef((props, ref) => {
    const { sourceData, measureID } = props;
    const { dialogRef, refresh } = useInBodyContext();
    const handleOnClick = () => {
        dialogRef.current.openDeleteMeasureDialog({ ...sourceData, measureID }, refresh);
    };
    return (
        <React.Fragment>
            <Button variant="outlined" onClick={handleOnClick}>
                刪除
            </Button>
        </React.Fragment>
    );
});

export default InBodyDeleteMeasure;

import React, { useRef } from 'react';
import clsx from 'clsx';
import InBodyInterviewMeasureNormalBox from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import InBodyExtendDiv from '@icoach/inbody/components/InBodyExtendDiv';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { Box, Card } from '@common/components/';
import employeeInBodyInterviewMeasureItems from '@icoach/inbody/employeeInbodyInterview/employeeInBodyInterviewMeasureItems';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';
import useFrozenTableCells from '@util/hook/useFrozenTableCells';

const InBodyInterviewMeasureTable = React.forwardRef((props, ref) => {
    const { filteredNonInterviewMeasureRecords: sourceData } = useInBodyContext();
    const divRef = useRef(null);
    const { tableContainerRef, frozenCellsRef } = useFrozenTableCells();

    return (
        <Card>
            <Card.CardContent>
                <Box ref={tableContainerRef} className={'inbody-measure scroll-arrow-container'}>
                    <Box className={'container'} ref={divRef}>
                        {employeeInBodyInterviewMeasureItems.map(
                            ({ value, key, rowClass, formatCell, formatCompare, formatMutipleCell, style: styleProps = {} }, i) => {
                                let styles = { ...styleProps };
                                return (
                                    // row
                                    <Box key={value} className={clsx('row', rowClass, sourceData.length === 0 && 'full-width')}>
                                        {/* Column 標題 */}
                                        <Box
                                            ref={(el) => (frozenCellsRef.current[i] = el)}
                                            className={clsx('item', 'sticky', 'label')}
                                            style={{ width: '180px' }}
                                        >
                                            {value}
                                        </Box>
                                        {/*/!* 全部內容 *!/*/}
                                        <InBodyInterviewMeasureNormalBox sourceData={sourceData} itemKey={key} styles={styles} formatCell={formatCell} />
                                        <InBodyExtendDiv />
                                    </Box>
                                );
                            },
                        )}
                    </Box>
                    <ScrollerArrowButton arrowLeftPos={600} parentRef={divRef} />
                </Box>
            </Card.CardContent>
        </Card>
    );
});

export default InBodyInterviewMeasureTable;

import { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import useAjax from '@apis/useAjax';
import { isEmpty } from '@util/utils';

const GET_MEMBER_PROFILE_API = (memberID) => `/api/members/${memberID}/profile/`;
const GET_MEMBER_PROFILE_ORDER_API = (memberID) => `/api/members/${memberID}/onsite-orders/`;
const GET_MEMBER_PROFILE_UNCHECKOUT_ORDER_API = (memberID) => `/api/members/${memberID}/uncheckout-orders/`;
const GET_MEMBER_PROFILE_TIP_API = (memberID) => `/api/members/${memberID}/tip/`;

const POST_MEMBER_PROFILE_PROTEIN_PUSH_API = (memberID) => `/api/members/${memberID}/profile/protein-push/`;
const POST_MEMBER_PROFILE_PROBIOTICS_PUSH_API = (memberID) => `/api/members/${memberID}/profile/probiotics-push/`;
const POST_MEMBER_PROFILE_CALCIUM_PUSH_API = (memberID) => `/api/members/${memberID}/profile/calcium-push/`;
const POST_MEMBER_PROFILE_HUNDRED_T_SHIRT_API = (memberID) => `/api/members/${memberID}/profile/hundred-t-shirt/`;
const POST_MEMBER_PROFILE_MEASURE_DAY_API = (memberID) => `/api/members/${memberID}/profile/measure-day/`;
const POST_MEMBER_PROFILE_NOTES_API = (memberID) => `/api/members/${memberID}/profile/notes/`;
const POST_MEMBER_PROFILE_TASKS_API = (memberID) => `/api/members/${memberID}/profile/tasks/`;
const PUT_MEMBER_PROFILE_TASKS_API = (memberID, taskID) => `/api/members/${memberID}/profile/tasks/${taskID}/`;
const DELETE_MEMBER_PROFILE_TASKS_API = PUT_MEMBER_PROFILE_TASKS_API;

const GET_MEMBER_PROFILE_BASIC_API = (memberID) => `/api/members/${memberID}/profile-basic/`;
const POST_MEMBER_PROFILE_BASIC_API = GET_MEMBER_PROFILE_BASIC_API;

const GET_MEMBER_PROFILE_PAYMENTS_API = (memberID) => `/api/members/${memberID}/profile-payments/`;
const GET_MEMBER_PROFILE_PAYMENTS_MEMBERSHIP_API = (memberID, paymentID) => `/api/members/${memberID}/profile-payments-membership/${paymentID}/`;
const POST_MEMBER_PROFILE_PAYMENTS_CREDIT_CARD_API = (memberID) => `/api/members/${memberID}/profile-payments/creditcard/`;
const POST_MEMBER_PROFILE_PAYMENTS_BANK_API = (memberID) => `/api/members/${memberID}/profile-payments/bank/`;
const POST_MEMBER_PROFILE_PAYMENTS_MEMBERSHIPS_API = (memberID) => `/api/members/${memberID}/profile-payments/memberships/`;
const GET_MEMBER_PROFILE_PAYMENTS_INVOICES_API = (memberID) => `/api/members/${memberID}/profile-invoices/`;
const POST_MEMBER_PROFILE_PAYMENTS_INVOICES_API = GET_MEMBER_PROFILE_PAYMENTS_INVOICES_API;
const PUT_MEMBER_PROFILE_NOTIFY_API = (memberID) => `/api/members/${memberID}/profile/notify/`;
const GET_MEMBER_PROFILE_HEALTH_DECLARATION_API = (memberID) => `/api/members/${memberID}/profile-health-declaration/`;

const GET_MEMBER_PAYMENTS_OPTIONS_API = (memberID) => `/api/members/${memberID}/payment/options/`;
const GET_MEMBER_PROTEINPUSH_OPTIONS_API = (memberID) => `/api/members/${memberID}/profile/protein-push/options/`;
const GET_MEMBER_PROBIOTICSPUSH_OPTIONS_API = (memberID) => `/api/members/${memberID}/profile/probiotics-push/options/`;
const GET_MEMBER_CALCIUMPUSH_OPTIONS_API = (memberID) => `/api/members/${memberID}/profile/calcium-push/options/`;

export const useMemberProfileApi = () => {
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 取得會員履歷
    const getMemberProfileApi = useCallback(
        async (memberID) => {
            let resp = await Ajax.get(GET_MEMBER_PROFILE_API(memberID));
            if (!resp) {
                enqueueSnackbar('讀取會員摘要失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberProfileOrderApi = useCallback(
        async (memberID) => {
            let resp = await Ajax.get(GET_MEMBER_PROFILE_ORDER_API(memberID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberUncheckoutOrderApi = useCallback(
        async (memberID) => {
            let result = {};
            let resp = await Ajax.get(GET_MEMBER_PROFILE_UNCHECKOUT_ORDER_API(memberID));
            if (resp) result = Object.assign(result, { orderData: resp });
            return result;
        },
        // eslint-disable-next-line
        [],
    );

    const getMemberProfileTipApi = useCallback(
        async (memberID) => {
            let resp = await Ajax.get(GET_MEMBER_PROFILE_TIP_API(memberID));
            if (!resp) {
                enqueueSnackbar('讀取會員摘要失敗,請再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員蛋白循環推進
    const postMemberProfileProteinPushApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_PROTEIN_PUSH_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員鈣循環推進
    const postMemberProfileCalciumPushApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_CALCIUM_PUSH_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員益菌循環推進
    const postMemberProfileProbioticsPushApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_PROBIOTICS_PUSH_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員百T尺吋
    const postMemberProfileHundredTShirtApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_HUNDRED_T_SHIRT_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員量身日
    const postMemberProfileMeasureDayApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_MEASURE_DAY_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員激勵因子項目來源、約定項目來源、指導注意、備註
    const postMemberProfileNotesApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_NOTES_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 新增/更新待辦工作項目(待辦事項、服銷待辦事項)
    const postMemberProfileTasksApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_TASKS_API(memberID), parameters, {
                isLoadingVisible: false,
            });

            if (!resp) {
                enqueueSnackbar('新增失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    //更新切換待辦事項狀態(未選->完成 / 完成->未選)
    const putMemberProfileTasksApi = useCallback(
        async (memberID, parameters) => {
            let { taskID } = parameters;
            let resp = await Ajax.put(PUT_MEMBER_PROFILE_TASKS_API(memberID, taskID), parameters);

            if (!resp) {
                enqueueSnackbar('更新失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 刪除待辦事項
    const deleteMemberProfileTasksApi = useCallback(
        async (memberID, taskID) => {
            let resp = await Ajax.delete(DELETE_MEMBER_PROFILE_TASKS_API(memberID, taskID));

            if (resp) {
                enqueueSnackbar('已刪除', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('刪除失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得會員基本資料
    const getMemberProfileBasicApi = useCallback(
        async (memberID) => {
            let resp = await Ajax.get(GET_MEMBER_PROFILE_BASIC_API(memberID));

            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員基本資料
    const postMemberProfileBasicApi = useCallback(
        async (memberID, parameters, callbackfn) => {
            const resp = await Ajax.post(POST_MEMBER_PROFILE_BASIC_API(memberID), parameters, { callbackfn });
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 會員付款資訊清單
    const getMemberProfilePaymentsApi = useCallback(
        async (memberID) => {
            let resp = await Ajax.get(GET_MEMBER_PROFILE_PAYMENTS_API(memberID));
            if (!isEmpty(resp) && resp.isError && typeof resp.result === 'string') return null; // 排除 0002 錯誤
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 會員會籍付款資訊清單
    const getMemberProfilePaymentsMembershipApi = useCallback(
        async (memberID, paymentID) => {
            let resp = await Ajax.get(GET_MEMBER_PROFILE_PAYMENTS_MEMBERSHIP_API(memberID, paymentID));
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新信用卡付款資訊
    const postMemberPaymentCreditCardApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_PAYMENTS_CREDIT_CARD_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新ACH銀行付款資訊
    const postMemberPaymentBankApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_PAYMENTS_BANK_API(memberID), parameters);

            if (resp) {
                enqueueSnackbar('已更新', {
                    variant: 'success',
                });
            } else {
                enqueueSnackbar('更新失敗', {
                    variant: 'error',
                });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新ACH銀行付款資訊
    const postMemberPaymentMembershipApi = useCallback(
        async (memberID, params) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_PAYMENTS_MEMBERSHIPS_API(memberID), params);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 會員發票資訊清單
    const getMemberProfileInvoicesApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBER_PROFILE_PAYMENTS_INVOICES_API(memberID));

            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員發票資訊
    const postMemberProfileInvoicesApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_PAYMENTS_INVOICES_API(memberID), parameters);
            if (!isEmpty(resp) && resp.isError && typeof resp.result === 'string') return null; // 排除 0002 錯誤
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 更新會員簡訊發送狀態Flag
    const putMemberProfileNotifyApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.put(PUT_MEMBER_PROFILE_NOTIFY_API(memberID), parameters);
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 取得是否有健康申告書PDF API
    const getMemberProfileHealthDeclarationApi = useCallback(
        async (memberID) => {
            let resp = await Ajax.get(GET_MEMBER_PROFILE_HEALTH_DECLARATION_API(memberID));
            if (!resp) {
                enqueueSnackbar('讀取健康申告書PDF有誤,請重新整理', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 付款Dialog初始資料
    const getInitMemberPaymentDataApi = useCallback(
        async (memberID) => {
            let sourceData = {};
            const optResp = await getMemberPaymentOptionsApi(memberID);
            if (optResp) {
                Object.assign(sourceData, { options: optResp.paymentTypeOptions });
            }
            const paymentsInfoData = await getMemberProfilePaymentsApi(memberID);
            if (paymentsInfoData) {
                Object.assign(sourceData, { paymentsInfoData: paymentsInfoData });
            }
            return sourceData;
        },
        // eslint-disable-next-line
        [],
    );

    // 付款方式options
    const getMemberPaymentOptionsApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBER_PAYMENTS_OPTIONS_API(memberID));
            if (!resp) {
                enqueueSnackbar('付款方式讀取有誤，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 銷售益菌options
    const getMembersProbioticsOptionsApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBER_PROBIOTICSPUSH_OPTIONS_API(memberID));
            if (!resp) {
                enqueueSnackbar('益菌讀取有誤，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 銷售蛋白options
    const getMembersProteinOptionsApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBER_PROTEINPUSH_OPTIONS_API(memberID));
            if (!resp) {
                enqueueSnackbar('蛋白讀取有誤，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 銷售蛋白options
    const getMembersCalciumOptionsApi = useCallback(
        async (memberID) => {
            const resp = await Ajax.get(GET_MEMBER_CALCIUMPUSH_OPTIONS_API(memberID));
            if (!resp) {
                enqueueSnackbar('蛋白讀取有誤，請重新再操作一次', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        getMemberProfileApi,
        getMemberProfileOrderApi,
        getMemberUncheckoutOrderApi,
        getMemberProfileTipApi,
        postMemberProfileProteinPushApi,
        postMemberProfileProbioticsPushApi,
        postMemberProfileCalciumPushApi,
        postMemberProfileHundredTShirtApi,
        postMemberProfileMeasureDayApi,
        postMemberProfileNotesApi,
        postMemberProfileTasksApi,
        putMemberProfileTasksApi,
        deleteMemberProfileTasksApi,
        getMemberProfileBasicApi,
        postMemberProfileBasicApi,
        getMemberProfilePaymentsApi,
        getMemberProfilePaymentsMembershipApi,
        postMemberPaymentCreditCardApi,
        postMemberPaymentBankApi,
        postMemberPaymentMembershipApi,
        getMemberProfileInvoicesApi,
        postMemberProfileInvoicesApi,
        putMemberProfileNotifyApi,
        getMemberProfileHealthDeclarationApi,
        getMembersProbioticsOptionsApi,
        getMembersProteinOptionsApi,
        getMembersCalciumOptionsApi,
        getMemberPaymentOptionsApi,
        getInitMemberPaymentDataApi,
    };
};

// 待辦工作項目(待辦事項、服銷待辦事項)
export const useMemberProfileTasksApi = () => {
    const [payload, setPayload] = useState();
    const Ajax = useAjax();
    const { enqueueSnackbar } = useSnackbar();

    // 新增/更新待辦工作項目(待辦事項、服銷待辦事項)
    const postMemberProfileTasksApi = useCallback(
        async (memberID, parameters) => {
            let resp = await Ajax.post(POST_MEMBER_PROFILE_TASKS_API(memberID), parameters, {
                isLoadingVisible: false,
            });

            if (resp) {
                setPayload(resp);
            } else {
                enqueueSnackbar('新增失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    //更新切換待辦事項狀態(未選->完成 / 完成->未選)
    const putMemberProfileTasksApi = useCallback(
        async (memberID, parameters) => {
            let { taskID } = parameters;
            let resp = await Ajax.put(PUT_MEMBER_PROFILE_TASKS_API(memberID, taskID), parameters, {
                isLoadingVisible: false,
            });

            if (resp) {
                setPayload(resp);
            } else {
                enqueueSnackbar('更新失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    // 刪除待辦事項
    const deleteMemberProfileTasksApi = useCallback(
        async (memberID, taskID) => {
            let parameters = { memberID, taskID };
            let resp = await Ajax.delete(DELETE_MEMBER_PROFILE_TASKS_API(memberID, taskID), parameters, {
                isLoadingVisible: false,
            });

            if (resp) {
                setPayload(resp);
                enqueueSnackbar('已刪除', { variant: 'success' });
            } else {
                enqueueSnackbar('刪除失敗', { variant: 'error' });
            }
            return resp;
        },
        // eslint-disable-next-line
        [],
    );

    return {
        payload,
        postMemberProfileTasksApi,
        putMemberProfileTasksApi,
        deleteMemberProfileTasksApi,
    };
};

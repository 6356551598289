import { useEffect, useState } from 'react';
import useInBodyApi from '@apis/useInBodyApi';

// 取得量身總覽下拉選項
const useGetInbodyOverviewOptionsApi = ({ isFetchOnMount = true } = {}) => {
    const { getInbodyOverviewOptionsApi: doInbodyOverviewOptionsApi } = useInBodyApi();
    const [options, setOptions] = useState([]);

    const getInbodyOverviewOptionsApi = async () => {
        const resp = await doInbodyOverviewOptionsApi();
        if (resp) {
            setOptions(resp);
        }
    };

    useEffect(() => {
        if (isFetchOnMount) getInbodyOverviewOptionsApi();

        // eslint-disable-next-line
    }, [isFetchOnMount]);

    return { options, getInbodyOverviewOptionsApi };
};

export default useGetInbodyOverviewOptionsApi;

import React from 'react';
import { Grid } from '@mui/material';
import { memberSheet, moreSheet, cellPhoneSheet, contactSheet, rowLabelCheckboxSheet, SpecialConditionBox } from '@icoach/components/';
import { DateIntervalField } from '@common/components/';
import { refIsRequiredError } from '@util/utils';
import { parseDate, getAddDays, isValidDate } from '@util/moment';
import { DateTimeStandard } from '../../../components/pickers/enums/DateTimeStandard';

export const FilterBirthdaySearch = React.forwardRef(({ paramsData }, ref) => {
    const dateRangeRef = React.useRef(null);

    React.useImperativeHandle(
        ref,
        () => {
            return {
                isError: () => refIsRequiredError(dateRangeRef),
                getResult: () => {
                    const { startDate, endDate } = dateRangeRef.current.getResult() || {};
                    return {
                        startDate: parseDate(startDate),
                        endDate: parseDate(endDate),
                    };
                },
            };
        },
        // eslint-disable-next-line
        []
    );

    const year = parseDate(new Date(), 'YYYY');
    const birthDayStart = isValidDate(`${year}-${paramsData.startDate}`) 
        ? `${year}-${paramsData.startDate}` 
        : isValidDate(paramsData.startDate) ? paramsData.startDate : parseDate(new Date(), 'YYYY-MM-DD');
    const birthDayEnd = isValidDate(`${year}-${paramsData.endDate}`) 
        ? `${year}-${paramsData.endDate}` 
        : isValidDate(paramsData.endDate) ? paramsData.endDate : parseDate(getAddDays(new Date(), 14), 'YYYY-MM-DD');
    
    return (
        <Grid item xs={4}>
            <DateIntervalField
                ref={dateRangeRef}
                label="時間區間"
                startDateProps={{ defaultValue: birthDayStart }}
                endDateProps={{ defaultValue: birthDayEnd }}
                required
                fullWidth
            />
        </Grid>
    );
});

//********************** 生日名單 **********************//
export const FilterBirthdayLabel = [
    {
        ...rowLabelCheckboxSheet,
        cellKey: 'memberID',
    },
    { ...memberSheet, isFixed: true, isFixedLeft: true, isShowAvatarImage: false },
    { ...cellPhoneSheet },
    {
        cellKey: 'specialCondition',
        headerLabel: '特殊狀況',
        width: 18,
        formatCell: (cellValue, { memberID }) => {
            return <SpecialConditionBox sourceData={{ memberID, value: cellValue }} />;
        },
    },
    {
        cellKey: 'weeklyWorkoutQuantity',
        headerLabel: '本週WO',
        width: 9,
        align: 'center',
    },
    {
        cellKey: 'monthlyWorkoutFrequency',
        headerLabel: 'WO頻率',
        align: 'center',
    },
    {
        cellKey: 'birthday',
        headerLabel: '生日',
        width: 13,
        align: 'center',
        formatCell: (value) => parseDate(value, DateTimeStandard.DisplayDateBySlash),
    },
    {
        cellKey: 'address',
        headerLabel: '住址',
        className: 'white-space-normal',
        width: 24,
        formatCell: (value, { postCode }) => {
            return `${postCode} ${value}`;
        },
    },
    { ...contactSheet },
    { ...moreSheet },
];

import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Stack, Button } from '@common/components/';
import { getLoadingData } from '@redux/loading/selector';

/**
 * 更多
 */
const MorePageBox = (props) => {
    const { count, page, onChange: onChangeProps, autoLoading } = props;
    const { show: isShowLoading } = useSelector(getLoadingData);
    const observerRef = useRef(null);
    const targetRef = useRef(null);

    const handlePageClick = (e) => {
        if (count === page || isShowLoading) return;
        const nextPage = page + 1;
        onChangeProps && onChangeProps(e, nextPage);
    };

    const handleObserverIntersect = (entries) => {
        entries.forEach((entry) => {
            if (entry.isIntersecting && autoLoading && !isShowLoading) {
                targetRef.current.click();
            }
        });
    };
    

    useEffect(() => {
        if (observerRef.current) observerRef.current.disconnect();
        observerRef.current = new IntersectionObserver(handleObserverIntersect, { threshold: 0.1 });

        const currentComponent = targetRef.current;

        if (currentComponent) {
            observerRef.current.observe(targetRef.current);
        }
        // eslint-disable-next-line
    }, [count, page]);


    useEffect(() => {
        const currentElement = targetRef.current;

        return () => {
            if (observerRef.current && currentElement) {
                observerRef.current.unobserve(currentElement);
            }
        };
        // eslint-disable-next-line
    }, []);

    return (
        <Stack justifyContent="center" className="my-2">
            {count !== page ? (
                <Button ref={targetRef} onClick={handlePageClick} fullWidth>
                    更多
                </Button>
            ) : null}
        </Stack>
    );
};
export default MorePageBox;

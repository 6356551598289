import React, { useState, useEffect, useRef } from 'react';
import { Route, Switch, useParams, Redirect } from 'react-router-dom';
import InBodyInterviewNotes from '@icoach/inbody/interview/InBodyInterviewNotesTable';
import InBodyInterviewSummary from '@icoach/inbody/interview/InBodyInterviewSummary';
import InBodyInterviewMeasure from '@icoach/inbody/interview/InBodyInterviewMeasureTable';
import { InBodyProvider } from '@icoach/inbody/components/InBodyContext';
import { PrivateRoute } from '@common/components/';
import {
    INBODY_INTERVIEW_SUMMARY_PATH,
    INBODY_INTERVIEW_MEASURE_PATH,
    INBODY_INTERVIEW_NOTES_PATH,
    INBODY_INTERVIEW_COMPARE_PATH,
} from '@icoach/router/routerPath';
import useInBodyApi from '@apis/useInBodyApi';
import InBodyInterviewMeasureCompareTable from '@icoach/inbody/interview/InBodyInterviewMeasureCompareTable';
import InBodyInterviewMemberInfoCard from '@icoach/inbody/interview/InBodyInterviewMemberInfoCard';
import { referenceTypeMap } from '@icoach/inbody/staticData';
import InBodyInterviewDialogBox from '@icoach/inbody/components/InBodyInterviewDialogBox';

const InBodyInterviewPage = React.forwardRef((props, ref) => {
    const { getInterviewInitDataApi, getInterviewMemberProfileApi, getInterviewMeasureApi } = useInBodyApi();
    const [sourceData, setSourceData] = useState({});
    const { profileData, options, measureData = [], isInterviewNotMeasure } = sourceData;
    const [isMounted, setMounted] = useState(false);
    const { id: peopleID } = useParams();
    const dialogRef = useRef(null);
    const refreshRef = useRef(null);

    const initAllDateApi = async () => {
        const resp = await getInterviewInitDataApi(peopleID);
        setSourceData(resp);
        setMounted(true);
    };

    const doInterviewMemberMeasureApi = async () => {
        const resp = await getInterviewMeasureApi({ referenceID: peopleID, type: referenceTypeMap.member });
        if (resp) setSourceData((preData) => ({ ...preData, ...resp }));
    };

    const refreshProfileApi = async () => {
        const resp = await getInterviewMemberProfileApi(peopleID);
        setSourceData((prev) => ({ ...prev, profileData: resp.profileData }));
    };

    const setRefresh = (fn) => {
        refreshRef.current = fn;
    };

    const inbodyInterviewValue = {
        dialogRef: dialogRef,
        referenceType: referenceTypeMap.member,
        peopleID: peopleID,
        peopleData: {
            ...sourceData.profileData,
            height: sourceData.memberHeight,
        },
        isInterviewNotMeasure,
        setSourceData: setSourceData,
        options: sourceData.options || {},
        refresh: refreshRef.current,
        setRefresh,
    };

    useEffect(() => {
        if (peopleID) {
            initAllDateApi();
            setRefresh(doInterviewMemberMeasureApi);
        }
        // eslint-disable-next-line
    }, []);

    refreshRef.current = initAllDateApi;

    return (
        <InBodyProvider value={inbodyInterviewValue}>
            <div className="container main-container-spacing inbody-interview">
                {isMounted && (
                    <React.Fragment>
                        <InBodyInterviewMemberInfoCard className={'mb-3'} sourceData={sourceData} peopleID={peopleID} setSourceData={setSourceData} />
                        <Switch>
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)}
                                as={InBodyInterviewSummary}
                                sourceData={profileData}
                                options={options}
                                initAllDateApi={initAllDateApi}
                                refreshProfileApi={refreshProfileApi}
                            />
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_COMPARE_PATH(peopleID)}
                                as={InBodyInterviewMeasureCompareTable}
                                sourceData={measureData.filter((target) => target.inbodyScore)}
                            />
                            <PrivateRoute
                                exact
                                path={INBODY_INTERVIEW_MEASURE_PATH(peopleID)}
                                as={InBodyInterviewMeasure}
                                sourceData={measureData.filter((target) => target.inbodyScore)}
                            />
                            <PrivateRoute exact path={INBODY_INTERVIEW_NOTES_PATH(peopleID)} as={InBodyInterviewNotes} sourceData={measureData} />
                            <Route path="/">
                                <Redirect to={INBODY_INTERVIEW_SUMMARY_PATH(peopleID)} />
                            </Route>
                        </Switch>
                        <InBodyInterviewDialogBox ref={dialogRef} />
                    </React.Fragment>
                )}
            </div>
        </InBodyProvider>
    );
});

export default InBodyInterviewPage;

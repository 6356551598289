import React from 'react';
import { IconButton } from '@common/components/';
import { Download as DownloadIcon } from '@common/SvgIcon/';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';

const InBodyInterviewPdfDialog = React.forwardRef((props, ref) => {
    const { sourceData: pdfUrl } = props;
    const { dialogRef } = useInBodyContext();

    const handleOnClick = () => {
        dialogRef.current.openViewPdfDialog(pdfUrl);
    };

    return (
        <React.Fragment>
            {pdfUrl && (
                <IconButton onClick={handleOnClick}>
                    <DownloadIcon />
                </IconButton>
            )}
        </React.Fragment>
    );
});

export default InBodyInterviewPdfDialog;

import React, { useState, useEffect, useImperativeHandle, useRef } from 'react';
import { v4 as uuid } from 'uuid';
import { isEmpty, refIsRequiredError } from '@util/utils';

const PrivateInformationBox = React.forwardRef((props, ref) => {
    let {
        defaultValue: defaultProps,
        placeholder: placeholderProps,
        value: valueProps,
        as: Component,
        onChange: onChangeProps,
        isError: propsError = false,
        required: requiredProps,
        ...other
    } = props;
    const [valueState, setValue] = useState({
        placeholder: placeholderProps,
        key: '',
    });
    const [required, setRequired] = useState(false);
    const { placeholder, key, ...otherValue } = valueState;
    const isEditRef = useRef(false);
    const filedRef = useRef(null);

    const onChange = (e, ...rest) => {
        if (isEditRef.current && placeholder !== '') {
            setValue((old) => ({
                ...old,
                placeholder: '',
            }));
        }

        isEditRef.current = true;

        if (typeof onChangeProps === 'function') onChangeProps(e, ...rest);
    };

    useImperativeHandle(
        ref,
        () => ({
            isError: () => {
                return refIsRequiredError(filedRef);
            },
            getResult: () => {
                let val;
                if (isEditRef.current) {
                    val = filedRef.current.getResult();
                } else {
                    val = defaultProps || valueProps;
                }
                return val;
            },
            getName: () => filedRef.current.name,
            getId: () => filedRef.current.id,
            node: () => filedRef.current,
        }),
        // eslint-disable-next-line
        [defaultProps, valueProps, propsError],
    );

    useEffect(() => {
        if (defaultProps === 'undefined' && valueProps === 'undefined') {
            throw new Error('value 與 defaultValue 擇一設定');
        }

        // 初始化正則表達式來檢測星號
        const reg = /\*+/gm;
        const newObj = {
            placeholder: null,
            defaultValue: null,
            value: null,
        };

        // 如果 defaultProps 或 valueProps 包含星號，將其設為 placeholder
        if (reg.test(defaultProps || '') || reg.test(valueProps || '')) {
            newObj.placeholder = defaultProps || valueProps;
            newObj.key = uuid();
        } else if (defaultProps) {
            newObj.defaultValue = defaultProps;
            newObj.key = uuid();
        } else if (valueProps) {
            newObj.value = valueProps;
        }

        setValue((old) => {
            const newProps = { ...old, ...newObj };
            return newProps;
        });

        // eslint-disable-next-line
    }, [defaultProps, valueProps]);

    useEffect(
        () => {
            if (!isEmpty(valueState)) {
                const value = valueState.defaultValue || valueState.value;

                if (requiredProps) {
                    if (!required) setRequired(true);
                } else {
                    if (value) {
                        setRequired(true);
                    } else {
                        setRequired(false);
                    }
                }
            }
        },
        // eslint-disable-next-line
        [valueState],
    );

    return (
        <Component
            key={key}
            ref={filedRef}
            isError={propsError}
            {...other}
            {...otherValue}
            inputProps={{ placeholder: placeholder }}
            onChange={onChange}
            required={required}
        />
    );
});
export default PrivateInformationBox;

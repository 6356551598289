import React, { useState, useRef } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { Grid } from '@mui/material';
import TakePhotoBox from '@icoach/components/TakePhotoBox';
import MemberAvatar from '@icoach/components/MemberAvatar';
import { MemberInfoBox } from '@icoach/components/BusinessCardBox';
import { memberDetailRouter } from '@icoach/router/MembersRouter';
import { Card, Stack, Tab, Tabs, Button } from '@common/components/';
import useMemberApi from '@apis/useMemberApi';
import MemberDetailHealthCareDoc from '@icoach/members/memberDetail/MemberDetailHealthCareDoc';
import { CounterBox, MembershipProgressBar, SimpleContentItem } from '@icoach/members/components/';

const MemberDetailSummary = (props) => {
    const { className, data = {}, memberID, tab, setTab } = props;
    const {
        membershipBar = {},
        memberName,
        membershipText,
        picturePath,
        totalWorkoutQuantity,
        weeklyWorkoutQuantity,
        monthlyWorkoutQuantity,
        membershipStatus,
    } = data;
    const { postMemberAvatarApi } = useMemberApi();
    const [avatarImg, setAvatar] = useState(picturePath);
    const takePhotoBoxRef = useRef(null);

    const handleChangeTab = (e, newValue) => {
        setTab(newValue);
    };

    const handleUploadAvatar = () => {
        takePhotoBoxRef.current.open();
    };

    const handleFileChange = (files) => {
        const formData = new FormData();
        formData.append('file', files.file);
        uploadAvatarApi(memberID, formData, files);
    };

    const uploadAvatarApi = async (memberID, params, file) => {
        const resp = await postMemberAvatarApi(memberID, params);
        if (resp) {
            setAvatar(file.blobSrc);
        }
    };

    return (
        <Card className={clsx('member-summary-box', className)}>
            <Card.CardContent className="px-4 pt-4 pb-2">
                <Stack alignItems="start" justifyContent="start">
                    <Stack direction="column" justifyContent="center" alignItems="center" className="mr-4">
                        <MemberAvatar className="mb-1" src={avatarImg} alt={memberName} width="8" membershipStatus={membershipStatus} />
                        <Button size="small" className="p-1" onClick={handleUploadAvatar}>
                            編輯頭像
                        </Button>
                        <TakePhotoBox onFileChange={handleFileChange} ref={takePhotoBoxRef} />
                        <MemberDetailHealthCareDoc memberID={memberID} />
                    </Stack>
                    <Grid container spacing={2}>
                        <Grid item xs={4}>
                            <MemberInfoBox sourceData={data} />
                        </Grid>
                        <Grid item xs={8}>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <CounterBox title="本週WO" className="counter-box-xl" counter={weeklyWorkoutQuantity} />
                                </Grid>
                                <Grid item xs={4}>
                                    <CounterBox title="總WO" className="counter-box-xl" counter={totalWorkoutQuantity} />
                                </Grid>
                                <Grid item xs={4}>
                                    <CounterBox title="月WO" className="counter-box-xl" counter={monthlyWorkoutQuantity} />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <MembershipProgressBar progressBarData={membershipBar} />
                        </Grid>
                        <Grid item xs={12}>
                            <SimpleContentItem label="會籍類別">{membershipText}</SimpleContentItem>
                        </Grid>
                    </Grid>
                </Stack>
            </Card.CardContent>
            <Tabs value={tab} variant="scrollable" scrollButtons="auto" onChange={handleChangeTab} className="px-4">
                {Array.isArray(memberDetailRouter) &&
                    memberDetailRouter.map((item) => {
                        return <Tab key={item.description} label={item.description} value={item.tabsIndex} component={NavLink} to={item.href(memberID)} />;
                    })}
            </Tabs>
        </Card>
    );
};

export default MemberDetailSummary;

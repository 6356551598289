import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import Tablenization from '@icoach/components/Tablenization';
import { Card, Typography, Button } from '@common/components/';
import { parseDate } from '@util/moment';
import { INBODY_INTERVIEW_ROUTER } from '@icoach/router/routerPath';
import { useInBodyContext } from '@icoach/inbody/components/InBodyContext';

const rowLabel = [
    {
        cellKey: 'measureDate',
        headerLabel: '量身時間',
        width: 20,
        formatCell: (value) => parseDate(value, 'YYYY-MM-DD HH:mm'),
    },
    {
        cellKey: 'employeeName',
        headerLabel: '量身擔當',
        width: 20,
        // formatCell: ()=> '2022-08-31 12:22',
    },
    {
        cellKey: 'interviewEmployeeName',
        headerLabel: '面談擔當',
        width: 20,
        // formatCell: ()=> '2022-08-31 12:22',
    },
    {
        cellKey: 'xFactorNotes',
        headerLabel: 'X-factor',
        width: 20,
        formatCell: (value = []) => {
            return value.map((target, index) => <Typography key={index}>{target}</Typography>);
        },
    },
    {
        cellKey: 'agreedNotes',
        headerLabel: '約定事項',
        // width: 20,
        formatCell: (value = []) => {
            return value.map((target, index) => <Typography key={index}>{target}</Typography>);
        },
    },
];

const InBodyHistoryTable = React.forwardRef((props, ref) => {
    const { sourceData, referenceID } = useInBodyContext();
    const history = useHistory();
    const [headerRow] = useState(() => {
        if (!Array.isArray(rowLabel) || rowLabel.length < 1) return [];
        return [...rowLabel];
    });

    const handleForwardOnClick = () => {
        history.push(`${INBODY_INTERVIEW_ROUTER}${referenceID}`);
    };

    return (
        <Card>
            <Card.CardContent className="inbody-list">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        歷史紀錄
                    </Typography>
                    <Button name="create" variant="outlined" color="secondary" onClick={handleForwardOnClick}>
                        開始面談
                    </Button>
                </header>
                <div>
                    <Tablenization cardProps={{ className: 'theme-gray' }}>
                        <Tablenization.TableRowHeader headerRow={headerRow} />
                        <Tablenization.TableRowBody headerRow={headerRow} rowsData={sourceData} />
                    </Tablenization>
                </div>
            </Card.CardContent>
        </Card>
    );
});

export default InBodyHistoryTable;

import React, { useRef } from 'react';
import { Dialog, DialogContent, DialogActions, Grid } from '@mui/material';
import GradientColorHeader from '@icoach/components/dialog/GradientColorHeader';
import { Button, TextField } from '@common/components/';
import useInBodyApi from '@apis/useInBodyApi';

const InBodyXFactorDialog = ({ open: propsOpen = false, onClose: propsOnClose, sourceData, refresh }) => {
    const { list, memberID, measureID } = sourceData || {};
    const { postXFactorApi } = useInBodyApi();
    const [item_1 = {}, item_2 = {}] = list || [];
    const item_1_Ref = useRef(null);
    const item_2_Ref = useRef(null);

    const getAllParams = (p1, p2) => {
        let xFactorPack = [];
        if (p1) {
            xFactorPack.push({
                measureTaskID: item_1.measureTaskID || 0,
                taskTitle: p1,
            });
        }
        if (p2) {
            xFactorPack.push({
                measureTaskID: item_2.measureTaskID || 0,
                taskTitle: p2,
            });
        }
        return xFactorPack;
    };

    const handleSendOnClick = () => {
        const item_1_vaule = item_1_Ref.current.getResult();
        const item_2_vaule = item_2_Ref.current.getResult();
        const params = getAllParams(item_1_vaule, item_2_vaule);
        doXFactorApi(params);
    };

    const doXFactorApi = async (params) => {
        const resp = await postXFactorApi(memberID, measureID, params);
        if (resp) {
            propsOnClose && propsOnClose();
            refresh && refresh();
        }
    };

    if (!Array.isArray(list)) return null;

    return (
        <Dialog open={propsOpen} PaperProps={{ className: 'wx-65rem' }} fullWidth>
            <GradientColorHeader onClose={propsOnClose}>X-Factor</GradientColorHeader>
            <DialogContent>
                <Grid container className="pt-4 px-2" spacing={3}>
                    <Grid item xs={12}>
                        <TextField ref={item_1_Ref} defaultValue={item_1.taskTitle || null} direction="row" label="項目ㄧ" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField ref={item_2_Ref} defaultValue={item_2.taskTitle || null} direction="row" label="項目二" fullWidth />
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button variant={'contained'} onClick={handleSendOnClick}>
                    送出
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InBodyXFactorDialog;

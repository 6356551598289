import React, { useRef, useImperativeHandle, useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { TextField, Button, Box } from '@common/components/';
import { SignBox } from '@icoach/components/';
import useMessageDialog from '@util/hook/useMessageDialog';
import { getErrorModuleStatus, isEmpty, refIsRequiredError } from '@util/utils';
import useResetFields from '@util/hook/useResetFields';

const ACHCard = React.forwardRef((props, ref) => {
    const {
        defaultValue = {},
        className,
        errorModule = {},
        identifierID = 0,
        userName = '',
        isSameBtn = false,
        readOnly: readOnlyProp = false,
        isSign = true,
        bankAccountProps = {},
    } = props;
    const setMessageDialog = useMessageDialog();

    const fieldRef = useRef({});
    const signImgRef = useRef(null);
    // 設定每個欄位的大小
    const [achInfo, setAchInfo] = useState({
        bankCode: '',
        bankAccount: '',
        bankAccountID: '',
        bankAccountName: '',
    });
    const { resetFieldsExcept } = useResetFields(achInfo, setAchInfo);

    const handleSameId = () => {
        if (!identifierID || !userName) {
            setMessageDialog({
                open: true,
                title: '提醒',
                msg: '很抱歉！無法取得相關資料，謝謝。',
            });
        } else {
            setAchInfo({
                bankAccountID: identifierID,
                bankAccountName: userName,
            });
        }
    };

    const handleChange = () => {
        resetFieldsExcept();
    };

    useImperativeHandle(
        ref,
        () => ({
            getResult: () => {
                const fieldObj = fieldRef.current;
                let result = {};
                for (let key in fieldObj) {
                    const field = fieldObj[key];
                    if (field && field.hasOwnProperty('getResult')) {
                        result[key] = field.getResult();
                    }
                }

                if (isSign) {
                    result.signImg = signImgRef.current.getResult();
                }
                return result;
            },
            isError: () => {
                const allObj = {
                    ...fieldRef.current,
                };

                if (isSign) allObj.signImg = signImgRef.current;

                return refIsRequiredError.apply(null, Object.values(allObj));
            },
        }),
        // eslint-disable-next-line
        [],
    );

    useEffect(() => {
        if (!isEmpty(defaultValue)) {
            const { bankCode, bankAccount, bankAccountID, bankAccountName } = defaultValue;
            setAchInfo({ bankCode, bankAccount, bankAccountID, bankAccountName });
        }
    }, [defaultValue]);

    return (
        <Box className={className}>
            <Grid container spacing={3}>
                <Grid item xs={6}>
                    <TextField
                        key={achInfo.bankCode}
                        ref={(el) => (fieldRef.current.bankCode = el)}
                        defaultValue={achInfo.bankCode}
                        label={'銀行代碼'}
                        {...getErrorModuleStatus(errorModule, 'bankCode')}
                        readOnly={readOnlyProp}
                        onChange={handleChange}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        key={defaultValue.bankName}
                        ref={(el) => (fieldRef.current.bankName = el)}
                        defaultValue={defaultValue.bankName}
                        label={'銀行名稱'}
                        {...getErrorModuleStatus(errorModule, 'bankName')}
                        readOnly={readOnlyProp}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        key={achInfo.bankAccount}
                        ref={(el) => (fieldRef.current.bankAccount = el)}
                        defaultValue={achInfo.bankAccount}
                        label={'銀行帳號'}
                        {...getErrorModuleStatus(errorModule, 'bankAccount')}
                        readOnly={readOnlyProp}
                        onChange={handleChange}
                        fullWidth
                        required
                        {...bankAccountProps}
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        label={'銀行帳戶'}
                        key={defaultValue.bankAccountName}
                        ref={(el) => (fieldRef.current.bankAccountName = el)}
                        defaultValue={defaultValue.bankAccountName}
                        {...getErrorModuleStatus(errorModule, 'bankAccountName')}
                        readOnly={readOnlyProp}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        key={achInfo.bankAccountID}
                        ref={(el) => (fieldRef.current.bankAccountID = el)}
                        defaultValue={achInfo.bankAccountID}
                        label={'帳戶身分證字號'}
                        onChange={handleChange}
                        {...getErrorModuleStatus(errorModule, 'bankAccountID')}
                        readOnly={readOnlyProp}
                        fullWidth
                        required
                    />
                </Grid>
                <Grid item xs={6}>
                    {isSameBtn && !readOnlyProp && (
                        <Button variant="contained" color="secondary" className="mt-4" onClick={handleSameId}>
                            同基本資料
                        </Button>
                    )}
                </Grid>
                {isSign && (
                    <Grid item xs={6}>
                        <SignBox
                            title="帳戶持有人簽名"
                            ref={signImgRef}
                            defaultValue={defaultValue.signImg}
                            {...getErrorModuleStatus(errorModule, 'signImg')}
                            readOnly={readOnlyProp}
                        />
                    </Grid>
                )}
            </Grid>
        </Box>
    );
});

export default ACHCard;

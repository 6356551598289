import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import SearchBar from './InBodyHistorySearchBar';
import InBodyHistoryTable from '@icoach/inbody/history/InBodyHistoryTable';
import InBodyVisitorsDialog from '@icoach/inbody/history/InBodyVisitorsDialog';
import { Box, Typography } from '@common/components/';
import { InBodyProvider } from '@icoach/inbody/components/InBodyContext';
import InbodyUploadExcel from '@icoach/inbody/components/InbodyUploadExcel';

const InBodyHistoryPage = React.forwardRef((props, ref) => {
    const { referenceID } = useParams();
    const [sourceData, setSourceData] = useState(null);
    const [isShowContent, setShowContent] = useState(false);

    const inbodyValue = {
        sourceData: sourceData,
        setSourceData: (params) => {
            setSourceData(params);
            setShowContent(true);
        },
        referenceID: referenceID,
    };

    return (
        <InBodyProvider value={inbodyValue}>
            <Box className="container main-container-spacing">
                <header className={'page-header'}>
                    <Typography className={'page-header-title'} variant={'h3'}>
                        量身紀錄
                    </Typography>
                    <Box className="btn-group">
                        <InbodyUploadExcel />
                        <InBodyVisitorsDialog />
                    </Box>
                </header>
                <SearchBar className={'mb-4'} />
                {sourceData !== null && isShowContent && <InBodyHistoryTable />}
            </Box>
        </InBodyProvider>
    );
});

export default InBodyHistoryPage;

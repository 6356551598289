// checkbox or radio Field 取值
import { isEmpty } from '@util/utils';

export const getRadioValue = (val, type = 'value') => {
    return val.find((v) => v.checked)?.[type] ?? '';
};

export const getCheckboxValue = (val, { type = 'value', isMultiple = true }) => {
    if (!isMultiple) {
        return val.find((v) => v.checked)?.[type] ?? false;
    }

    return val.map((item) => item[type]);
};

export const setCheckboxValue = (val) => {
    return Array.isArray(val) ? val : [val];
};

/**
 * 判斷是否為 手動移入
 * @param {Number|String} type
 * @param {Boolean?} isManual
 * @returns {Boolean}
 */
export const checkIsManualTransferIn = (type, isManual) => String(type) === '11' || isManual;

/**
 * 映射步驟陣列，檢查每個步驟是否存在錯誤
 * @param {Array} steps - 包含步驟的陣列，每個步驟包含 keyMapping 和其他屬性
 * @param {Array} errorModule - 包含錯誤 key 的陣列，每個項目包含 key 屬性
 * @returns {Array} - 返回包含步驟屬性與錯誤狀態的物件陣列
 */
export const mapStepsWithErrorState = (steps = [], errorModule = {}) => {
    if (isEmpty(errorModule)) return steps;
    const errorKeysSet = new Set(Object.keys(errorModule));

    return steps.map((step) => {
        const { keyMapping, id, ...other } = step;

        // 檢查 keyMapping 中是否存在錯誤中
        const hasError = keyMapping.some((key) => {
            if (id) {
                return errorKeysSet.has(`${id}.${key}`) || errorKeysSet.has(key);
            } else {
                return errorKeysSet.has(key);
            }
        });

        // 返回包含錯誤狀態的新步驟物件
        return {
            ...other,
            id,
            hasError,
        };
    });
};

import React, { useEffect, useState, useCallback, useRef, useLayoutEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import moment from 'moment';
import clsx from 'clsx';
import { v4 as uuid } from 'uuid';
import { Grid } from '@mui/material';
import { Card, Typography, Stack, Alert, Divider, Box } from '@common/components/';
import { Phone as PhoneIcon, RingBall as RingBallIcon } from '@common/SvgIcon/';
import { BusinessCardBox } from '@icoach/components/';
import MobileDatePickerBox from '@icoach/components/MobileDatePickerBox';
import LandingDialogBox from '@icoach/landingPage/dialog/LandingDialogBox';
import {
    AppointmentCircuitDutyItem,
    AppointmentItemEmployee,
    AppointmentDailyMemo,
    AppointmentConfirmMenuOption,
    AppointmentAttendMenuOption,
    AppointmentSaleMenuOption,
    AppointmentMoreMenuOption,
    AppointmentTelemarketingBox,
    AppointmentMemoEditControllerBox,
} from '@icoach/landingPage/appointment/';
import useLanding from '@icoach/landingPage/components/useLanding';
import { isValidDate, parseDate, getDatetime } from '@util/moment';
import { isEmpty, getUrlParameter } from '@util/utils';
import useAppointmentApi from '@apis/useAppointmentApi';
import useEmployeeScheduleApi from '@apis/useEmployeeScheduleApi';

// 每列資訊
const RowInformationItem = (props) => {
    const { sourceData = [], apoDateTime, className } = props;
    const { options = {}, refresh, dialogRef } = useLanding();

    const {
        confirmTypeOption = [],
        attendTypeOption = [],
        figureAnalysisPushTypeOption = [],
        figureAnalysisReasonTypeOption = [],
        usableEmployeeOption = [],
    } = options.current;

    return (
        <React.Fragment>
            {sourceData.map((targetData) => {
                const {
                    imageSrc = null,
                    sourceTypeName,
                    name,
                    nickName,
                    memberNo,
                    cellPhone,
                    contactNotes,
                    serveEmployeeName,
                    serveEmployeeID,
                    appointmentTypeText,
                    appointmentType,
                    appointmentID,
                    sourceTypeText,
                    peopleType,
                    peopleID,
                    attendType,
                    confirmType,
                    pushType,
                    pushReasonText,
                    appointmentTelemarketingType,
                    notes,
                    isInvalidMember,
                    isSendSms = false,
                } = targetData;
                const isMember = String(peopleType) === '2';
                const memberOtherInfo = isMember ? memberNo : sourceTypeName;

                const handleClickMember = () => {
                    if (isMember) {
                        dialogRef.current && dialogRef.current.openMemberSummaryDialog(peopleID);
                    }
                };

                const cardInfoProperty = {
                    memberImg: imageSrc,
                    memberName: name,
                    nickName,
                    memberNo: memberOtherInfo ? memberOtherInfo.slice(-6) : '',
                    sourceTypeText,
                    peopleType,
                    isSourceType: String(peopleType) === '1',
                };

                return (
                    <Box className={clsx(`appointment-time-list-fram-decorate`, className)} key={uuid()}>
                        <Grid container columns={12} alignItems="center" spacing={1}>
                            {/* 預約單 姓名/暱稱/編號/大頭照 */}
                            <Grid item xs={3}>
                                <BusinessCardBox className={clsx({ 'cursor-pointer': isMember })} sourceData={cardInfoProperty} onClick={handleClickMember} />
                            </Grid>
                            {/* 預約名稱 and 預約教練 */}
                            <Grid item xs={2}>
                                <AppointmentItemEmployee
                                    appointmentTypeText={appointmentTypeText}
                                    serveEmployeeID={serveEmployeeID}
                                    serveEmployeeName={serveEmployeeName}
                                    appointmentID={appointmentID}
                                    refresh={refresh}
                                    employeeOption={usableEmployeeOption}
                                />
                            </Grid>
                            {/* NOTES */}
                            <Grid item xs={3}>
                                <AppointmentMemoEditControllerBox
                                    className="appointment-time-list-notes"
                                    apoId={appointmentID}
                                    text={notes}
                                    isTableSpace={false}
                                />
                            </Grid>
                            {/* 確認/出席/推進 項目 */}
                            <Grid item xs={4}>
                                <Box className={`appointment-time-list-action`}>
                                    <Stack spacing={1}>
                                        {/* 確認項目 */}
                                        {confirmTypeOption.length > 0 && (
                                            <AppointmentConfirmMenuOption
                                                className={`appointment-time-list-light-text`}
                                                apoId={appointmentID}
                                                option={confirmTypeOption}
                                                value={confirmType}
                                            />
                                        )}
                                        {/* 出席項目 */}
                                        {attendTypeOption.length > 0 && (
                                            <AppointmentAttendMenuOption
                                                className={`appointment-time-list-light-text`}
                                                apoId={appointmentID}
                                                option={attendTypeOption}
                                                value={attendType}
                                            />
                                        )}
                                        {/* 入會項目 */}
                                        <AppointmentSaleMenuOption
                                            className={`appointment-time-list-light-text`}
                                            apoId={appointmentID}
                                            apoType={appointmentType}
                                            peopleType={peopleType}
                                            isInvalidMember={isInvalidMember}
                                            options={{
                                                figureAnalysisPushTypeOption,
                                                figureAnalysisReasonTypeOption,
                                            }}
                                            sourceData={{ pushType, pushReasonText }}
                                            refresh={refresh}
                                        />
                                        {/* 推進項目 */}
                                        <AppointmentTelemarketingBox
                                            className={`appointment-time-list-light-text`}
                                            apoId={appointmentID}
                                            value={appointmentTelemarketingType}
                                            apoType={appointmentType}
                                            peopleType={peopleType}
                                            isInvalidMember={isInvalidMember}
                                        />
                                        <Stack alignItems="center">
                                            <AppointmentMoreMenuOption
                                                apoDateTime={apoDateTime}
                                                apoId={appointmentID}
                                                peopleType={peopleType}
                                                isInvalidMember={isInvalidMember}
                                                peopleID={peopleID}
                                                name={nickName || name}
                                                refresh={refresh}
                                            />
                                        </Stack>
                                    </Stack>
                                </Box>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider />
                            </Grid>
                            {/* 聯繫資訊 */}
                            {/* 人員電話號碼 */}
                            <Grid item xs={12}>
                                <Stack className={`appointment-time-list-cellphone`} alignItems="center" spacing={1}>
                                    <PhoneIcon htmlColor="#000" fontSize="small" />
                                    <span>{cellPhone}</span>
                                    {isSendSms && <RingBallIcon fontSize="small" />}
                                </Stack>
                            </Grid>
                            <Grid item xs={12}>
                                <Box className="appointment-time-list-contact-notes">
                                    <Typography>{contactNotes}</Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                );
            })}
        </React.Fragment>
    );
};

const AppointmentScheduleTable = () => {
    const { setOptions: setOptionsProps, setRefresh, setAppointmentDateRef } = useLanding();
    const { location, replace: _replace } = useHistory();
    const [isMounted, setMounted] = useState(false);
    const [sourceData, setSourceData] = useState([]);
    const [options, setOptions] = useState({});
    const [employeeData, setEmployeeData] = useState({});
    const [pickDateTime, setPickDateTime] = useState(getDatetime(getUrlParameter('t', location.search)?.t ?? ''));
    const { getAppointmentScheduleRecordsInitApi, getAppointmentDailyRecordsApi } = useAppointmentApi();
    const { getEmployeeScheduleEmployeeApi } = useEmployeeScheduleApi();
    const currentTimeArea = useRef(null);
    const pickeDateTimeRef = useRef(false);
    let now = moment();
    let currentApoTime = now.minutes() < 30 ? now.format('HH:00') : now.format('HH:30');

    const handleDateOnChange = (dateTime) => {
        setPickDateTime(parseDate(dateTime));
    };

    const initDataApi = async () => {
        const resp = await getAppointmentScheduleRecordsInitApi({ time: parseDate(pickDateTime) });
        if (resp) {
            const { options: opts, result } = resp;
            _replace(`${location.pathname}?t=${pickDateTime}`);
            setOptions(opts);
            setSourceData(result.apoTimelines);
            setMounted(true);
        }
    };

    const getAppointmentsDailyApi = useCallback(
        async () => {
            const resp = await getAppointmentDailyRecordsApi({ time: parseDate(pickDateTime) });
            if (resp) {
                setMounted(false);
                const { options: opts, apoTimelines } = resp;
                _replace(`${location.pathname}?t=${pickDateTime}`);
                setOptions((prev) => ({ ...prev, ...opts }));
                setSourceData(apoTimelines);
                setMounted(true);
            }
        },
        // eslint-disable-next-line
        [pickDateTime],
    );

    const doEmployeeScheduleEmployeeApi = async (params) => {
        const res = await getEmployeeScheduleEmployeeApi(params);
        if (res) {
            setEmployeeData(res);
        }
    };

    const DailyMemo = useMemo(
        () => {
            return <AppointmentDailyMemo date={parseDate(pickDateTime)} />;
        },
        // eslint-disable-next-line
        [pickDateTime],
    );

    useEffect(
        () => {
            // first Initial Date
            initDataApi();
            return () => setMounted(false);
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (pickeDateTimeRef.current) getAppointmentsDailyApi(); // 已初始過後才會做
            pickeDateTimeRef.current = true; //第一次初始才進來
            if (isValidDate(pickDateTime)) {
                setAppointmentDateRef(pickDateTime);
                doEmployeeScheduleEmployeeApi({ time: parseDate(pickDateTime) });
            }
        },
        // eslint-disable-next-line
        [pickDateTime],
    );

    useLayoutEffect(
        () => {
            if (currentTimeArea.current != null && currentTimeArea.current.offsetTop > 0) {
                const offsetTop = currentTimeArea.current.offsetTop - 120;
                setTimeout(() => {
                    window.scrollTo(0, offsetTop);
                }, 100);
            }
        },
        // eslint-disable-next-line
        [],
    );

    // 預設給個元件使用 不寫在記憶體內會被cache著
    setOptionsProps(options);
    setRefresh(getAppointmentsDailyApi);

    return (
        <React.Fragment>
            <Card className={clsx(`appointment-time-list-context`, 'p-3')} square isOverflowHidden>
                <Card.CardContent className={clsx('sticky-card-header', 'py-1', 'appointment-time-list-header')}>
                    <Stack alignItems={'center'} justifyContent={'start'}>
                        <MobileDatePickerBox className={'flex-y-flex-start'} value={pickDateTime} onChange={handleDateOnChange} />
                        <Typography className={'mb-0'}>
                            排休：{!isEmpty(employeeData.leaveEmployees) ? employeeData.leaveEmployees.join(', ') : '無'} / 外出支援：
                            {!isEmpty(employeeData.goingOutSupportEmployees) ? employeeData.goingOutSupportEmployees.join(', ') : '無'} / 店內支援：
                            {!isEmpty(employeeData.externalSupportCoaches) ? employeeData.externalSupportCoaches.join(', ') : '無'}
                        </Typography>
                    </Stack>
                    {DailyMemo}
                </Card.CardContent>
                {isMounted &&
                    sourceData.map((target) => {
                        const { list: resource, circuitEmployeeID, circuitEmployeeName, apoTime } = target;
                        return (
                            <React.Fragment key={apoTime}>
                                <Card.CardContent
                                    className={clsx(`appointment-time-list-content`, 'py-1')}
                                    ref={apoTime === currentApoTime ? currentTimeArea : null}
                                >
                                    <Stack className={clsx(`appointment-time-list-time-item`)} alignItems={'center'}>
                                        <span className={`appointment-time-list-circle-decorate`} />
                                        <Box className={`appointment-time-list-time-period`}>{apoTime}</Box>
                                        <Box className={`appointment-time-list-dotted-line`}></Box>
                                        <Typography component="h5" variant="h5">
                                            CC教練&nbsp;
                                        </Typography>
                                        <AppointmentCircuitDutyItem
                                            options={options.usableEmployeeOption}
                                            time={apoTime}
                                            date={pickDateTime}
                                            name={circuitEmployeeName}
                                            value={circuitEmployeeID}
                                        />
                                    </Stack>
                                    <Stack>
                                        <span className={`appointment-time-list-line-decorate`} />
                                        <Box className="appointment-time-list-data">
                                            {Array.isArray(resource) && resource.length > 0 ? (
                                                <RowInformationItem sourceData={resource} apoDateTime={`${pickDateTime} ${apoTime}`} />
                                            ) : (
                                                <Alert variant="filled" severity="info" color="gray">
                                                    尚無預約
                                                </Alert>
                                            )}
                                        </Box>
                                    </Stack>
                                </Card.CardContent>
                            </React.Fragment>
                        );
                    })}
            </Card>
            <LandingDialogBox />
        </React.Fragment>
    );
};

export default AppointmentScheduleTable;

import React, { useMemo, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getVersionData } from '@redux/version/selector';
import clsx from 'clsx';
import { Card, Alert, Box } from '@common/components/';
import InBodyInterviewMeasureNormalBox, {
    inBodyInterviewMeasureItems,
    inBodyInterviewNotesItems,
} from '@icoach/inbody/interview/InBodyInterviewMeasureNormalBox';
import InBodyInterviewDialogBox from '@icoach/inbody/components/InBodyInterviewDialogBox';
import InBodyExtendDiv from '@icoach/inbody/components/InBodyExtendDiv';
import ScrollerArrowButton from '@icoach/inbody/components/ScrollerArrowButton';
import { InBodyProvider } from '@icoach/inbody/components/InBodyContext';
import { referenceTypeMap } from '@icoach/inbody/staticData';
import useGetMeasureRecordsApi from '@util/hook/useGetMeasureRecordsApi';
import useFrozenTableCells from '@util/hook/useFrozenTableCells';
import { isEmpty } from '@util/utils';

const MemberDetailInbodyContent = ({ memberID, setTab, match }) => {
    const { env: ENV = '' } = useSelector(getVersionData);
    const tabs = match.params.tabs;
    const { sourceData: measureData, handleRefetch } = useGetMeasureRecordsApi({ referenceID: memberID, referenceType: referenceTypeMap.member });
    const dialogRef = useRef(null);
    const measureContentRef = useRef(null);
    const { tableContainerRef, frozenCellsRef } = useFrozenTableCells();

    const inbodyInterviewValue = {
        dialogRef: dialogRef,
        peopleID: memberID,
        refresh: handleRefetch,
    };

    const inbodyMeasureLabelMemo = useMemo(
        () => {
            return [...inBodyInterviewMeasureItems.slice(0, inBodyInterviewMeasureItems.length - 1), ...inBodyInterviewNotesItems.slice(1)];
        },
        // eslint-disable-next-line
        [],
    );

    useEffect(
        () => {
            if (tabs) setTab(tabs);
        },
        // eslint-disable-next-line
        [],
    );

    return (
        <InBodyProvider value={inbodyInterviewValue}>
            <React.Fragment>
                <Card>
                    <Card.CardContent className="p-4">
                        {!isEmpty(measureData) ? (
                            <Box ref={tableContainerRef} className={'inbody-measure scroll-arrow-container'}>
                                <Box className={'container'} ref={measureContentRef}>
                                    {inbodyMeasureLabelMemo.map(({ uuid, value, rowClass = '', key, formatCell, style: styleProps = {}, env }, i) => {
                                        if (env && !env.includes(String(ENV).toUpperCase())) {
                                            return null;
                                        }
                                        let styles = { ...styleProps };
                                        return (
                                            <Box key={uuid} className={clsx('row', rowClass, measureData.length === 0 && 'full-width')} style={{ ...styles }}>
                                                {/* Column 標題 */}
                                                <Box
                                                    ref={(el) => (frozenCellsRef.current[i] = el)}
                                                    className={clsx('item', 'sticky')}
                                                    style={{ width: '180px' }}
                                                >
                                                    {value}
                                                </Box>
                                                <InBodyInterviewMeasureNormalBox
                                                    sourceData={measureData}
                                                    itemKey={key}
                                                    styles={styles}
                                                    formatCell={formatCell}
                                                />
                                                <InBodyExtendDiv />
                                            </Box>
                                        );
                                    })}
                                </Box>
                                <ScrollerArrowButton arrowLeftPos={150} moveDistance={584} parentRef={measureContentRef} />
                            </Box>
                        ) : (
                            <Alert color="info" severity="info" variant="filled">
                                尚無量身資料
                            </Alert>
                        )}
                    </Card.CardContent>
                </Card>
                <InBodyInterviewDialogBox ref={dialogRef} />
            </React.Fragment>
        </InBodyProvider>
    );
};

export default MemberDetailInbodyContent;
